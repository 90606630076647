import React from "react";
import { Icons } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useTranslate,
  useNavigation,
} from "@pankod/refine-core";
import { ListCustom } from "components/layout";
import { ButtonTooltip } from "components";
import { ApprovalRequest } from "./components/tabs/approvalRequest";
import { PrepayRequest } from "./components/tabs/prepayRequest";
import usePermissions from "hooks/permission";
import { PATH } from "configs/path";

export const PrepayList: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { create } = useNavigation();
  const { checkCreateHaveHelperText } = usePermissions();

  const isApproval = props?.name === PATH.prepayApprovalrequests;

  return (
    <ListCustom
      title={t(
        !isApproval ? "prepays.titles.list" : "approvalrequests.titles.list"
      )}
      headerButtons={
        <ButtonTooltip
          hidden={isApproval}
          type="primary"
          icon={<Icons.PlusOutlined />}
          onClick={() => create(PATH.prepays)}
          {...checkCreateHaveHelperText(PATH.prepays)}
        >
          {t("prepays.create")}
        </ButtonTooltip>
      }
      breadcrumb={null}
    >
      {isApproval ? <ApprovalRequest /> : <PrepayRequest />}
    </ListCustom>
  );
};
