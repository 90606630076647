import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Typography,
  notification,
} from "@pankod/refine-antd";
import { useGetIdentity, useTranslate } from "@pankod/refine-core";
import { UserIdentity } from "api/types";
import { SelectCustom } from "components";
import UploadMulti from "components/UploadMulti";
import { DATE_FORMAT } from "configs/constants";
import { API_PATH } from "configs/path";
import dayjs from "dayjs";
import { FC, memo, useContext } from "react";
import { IForm, ServiceRequestFormContext } from "../../..";
import { useFormRule } from "hooks/useFormRule";
import { RequestType } from "interfaces/ServiceRequest";
import ModalConfirm from "components/ModalConfirm";
import { ApprovalStatus } from "api/enums";

const { Title } = Typography;
const { TextArea } = Input;
const { useWatch, useFormInstance } = Form;

const DetailInfo: FC = memo(() => {
  const { initialValues, isEdit } = useContext(ServiceRequestFormContext);
  const { required } = useFormRule();
  const form = useFormInstance<IForm>();
  const translate = useTranslate();
  const { data: user } = useGetIdentity<UserIdentity>();
  const { employee } = user || {};
  const caseId = useWatch("caseId", form);
  const serviceRequestTypeId = useWatch("serviceRequestTypeId", form);
  const serviceRequestTypeCode = useWatch("serviceRequestTypeCode", form);

  const onChangeRequestType =
    (isDone?: boolean) =>
    (value: string, __: any, selectedItem: RequestType) => {
      if (serviceRequestTypeId && !isDone) return;
      form.setFieldsValue({
        serviceRequestTypeId: value,
        serviceRequestTypeCode: selectedItem?.code,
      });
    };

  const onCancelConfirm = () =>
    form.setFieldsValue({
      serviceRequestTypeId: serviceRequestTypeId || null,
      serviceRequestTypeCode: serviceRequestTypeCode || null,
    });

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Title level={5}>{translate("Thông tin chi tiết")}</Title>
      <div style={{ paddingLeft: 16 }}>
        <Row gutter={16}>
          <Col lg={12} xs={24}>
            <Form.Item hidden name="serviceRequestTypeCode" />
            <Form.Item
              label={translate("Loại yêu cầu")}
              name="serviceRequestTypeId"
              rules={required}
            >
              <ModalConfirm
                title={translate("Bạn muốn thay đổi vụ việc/loại yêu cầu?")}
                enable={!!serviceRequestTypeId}
                desc={
                  "Lưu ý: Khi thay đổi vụ việc hoặc loại yêu cầu, các thông tin danh sách sản phẩm sẽ bị xóa."
                }
                onCancel={onCancelConfirm}
                onChange={onChangeRequestType()}
                onDone={onChangeRequestType(true)}
              >
                <SelectCustom
                  optionLabel="name"
                  optionValue="id"
                  resource={API_PATH.serviceRequestTypeDropdownlist}
                  placeholder={translate("Chọn loại yêu cầu")}
                  disabled={
                    isEdit && initialValues?.status !== ApprovalStatus.Draft
                  }
                />
              </ModalConfirm>
            </Form.Item>
          </Col>
          <Col lg={12} xs={24} />
          <Col lg={12} xs={24}>
            <Form.Item
              label={translate("Tên yêu cầu")}
              name="name"
              rules={[
                ...required,
                {
                  max: 300,
                  message: translate("errors.ER014", { max: "300" }),
                },
              ]}
            >
              <Input placeholder={translate("Nhập tên yêu cầu")} />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24} />
          <Col span={24}>
            <Form.Item
              label={translate("Mô tả yêu cầu")}
              name="description"
              rules={[
                ...required,
                {
                  max: 3000,
                  message: translate("errors.ER014", { max: "3,000" }),
                },
              ]}
            >
              <TextArea rows={4} placeholder="Nhập mô tả" />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item label={translate("Người yêu cầu")}>
              <Input
                disabled
                value={`${employee?.employee?.code} - ${employee?.employee?.fullName}`}
                style={{ color: "#000000" }}
              />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item label={translate("Bộ phận")}>
              <Input
                disabled
                value={employee?.department?.name}
                style={{ color: "#000000" }}
              />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item label={translate("Ngày yêu cầu")} name="requestDate">
              <Input disabled style={{ color: "#000000" }} />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              label={translate("Ngày yêu cầu hoàn thành")}
              name="requestCompletionDate"
              rules={required}
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
            >
              <DatePicker
                className="w-full"
                placeholder={translate("Chọn ngày yêu cầu hoàn thành")}
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24} />
          <Col span={24}>
            <Form.Item
              name="listServiceRequestDocument"
              validateStatus="success"
              label=""
            >
              <UploadMulti
                title={translate("Tài liệu đính kèm")}
                url={`${process.env.REACT_APP_API_URL}${API_PATH.serviceRequestUploadFile}?caseId=${caseId}`}
                idKey="id"
                onClickButtonUpload={(action) => {
                  if (!caseId) {
                    notification.error({
                      message: translate(
                        "Vui lòng chọn mã vụ việc trước khi tải file."
                      ),
                    });
                  } else {
                    action();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default DetailInfo;
