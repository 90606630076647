import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  getDefaultSortOrder,
  useSelect,
  List,
  Select,
  Table,
  TextField,
  Space,
  Icons,
  Form,
  Radio,
  useForm,
  Button,
  Input,
  Modal,
  Typography,
  Tag,
  notification,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useTranslate,
  useTable,
  useCreate,
  useUpdate,
  useDelete,
} from "@pankod/refine-core";
import { EmptyData, SearchBox, DataText, ButtonTooltip } from "components";
import { useNavigate } from "react-router-dom";
import { API_PATH, PATH } from "configs/path";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import { searchSelect } from "utils/commons";
import { ICase } from "interfaces";
import usePermissions from "hooks/permission";
import { v4 as uuid } from "uuid";
import { CASE_STATUS_OPTIONS, PAGE_SIZE_OPTIONS } from "configs/constants";
import ButtonConfirm from "components/ButtonConfirm";
import { CaseStatus } from "api/enums";
import { IntegrationStatusTag } from "components/StatusTag/IntegrationStatusTag";

interface IForm {
  description: string;
  department: string;
  status: string;
}

const { Text } = Typography;
const { useWatch } = Form;

export const getTagColor = (
  statusCode: CaseStatus = CaseStatus.Draft
): string => {
  let color = "default";
  switch (statusCode) {
    case CaseStatus.Draft:
      color = "warning";
      break;
    case CaseStatus.Active:
      color = "success";
      break;
    case CaseStatus.Inactive:
      color = "default";
      break;
    default:
      break;
  }

  return color;
};
export const CasesList: React.FC<IResourceComponentsProps> = () => {
  const { form: filterForm, formProps: filterFormProps } = useForm<any>();
  const { form, formProps, saveButtonProps } = useForm<any, any, IForm>();

  const {
    checkCreateHaveHelperText,
    checkEditHaveHelperText,
    checkDeleteHaveHelperText,
  } = usePermissions();
  const translate = useTranslate();
  const navigate = useNavigate();
  const [isSearch, setIsSearch] = React.useState<boolean>(false);

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useCreate<any>();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>();
  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useDelete<any>();

  const isLoading = isLoadingCreate || isLoadingUpdate;

  const {
    tableQueryResult: { data: dataTable },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    setFilters,
    sorter,
    setSorter,
    filters,
  } = useTable<ICase>({
    resource: PATH.cases,
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const { selectProps: statusSelectProps } = useSelect({
    resource: API_PATH.caseStatusDropdownlist,
    optionLabel: "name",
    optionValue: "id",
  });

  const [isAdding, setIsAdding] = useState(false);

  const [visibleModalConfirmCreate, setVisibleModalConfirmCreate] =
    useState(false);

  const [visibleModalConfirmChangeRow, setVisibleModalConfirmChangeRow] =
    useState(false);

  const [visibleModalConfirmDelete, setVisibleModalConfirmDelete] =
    useState(false);

  const [rowSelected, setRowSelected] = useState<any>();

  const draftRowSelect = useRef<any>();

  const draftRowDelete = useRef<any>();

  const toggleModalConfirmCreate = () =>
    setVisibleModalConfirmCreate((prev) => !prev);

  const toggleModalConfirmChangeRow = () =>
    setVisibleModalConfirmChangeRow((prev) => !prev);

  const toggleModalConfirmDelete = () =>
    setVisibleModalConfirmDelete((prev) => !prev);

  const { selectProps: departmentSelectProps } = useSelect<any>({
    resource: API_PATH.departmentSelect,
    optionValue: "id",
    optionLabel: "name",
    onSearch: () => [],
  });

  const onSearch = (value: string) => {
    setCurrent(1);
    setIsSearch(value ? true : false);
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };

  const onAdd = () => {
    form.resetFields();
    setRowSelected(undefined);
    setIsAdding(true);
  };

  const dataList = useMemo(() => {
    let temp: any[] = dataTable?.data || [];
    if (isAdding) {
      temp = [{ id: uuid(), isCreate: true }, ...temp];
    }

    return temp.map((t) => ({
      ...t,
      departmentId: t?.department?.id,
      statusId: t?.status?.id,
    }));
  }, [isAdding, dataTable]);

  const isAddingRow = useCallback(
    (rowIndex: number) => {
      return rowIndex === 0 && isAdding;
    },
    [isAdding]
  );

  const isEditingRow = useCallback(
    (row?: any) => row?.id === rowSelected?.id,
    [rowSelected]
  );

  const onSubmit = () => {
    if (isAdding) {
      onCreate();
    } else {
      onEdit();
    }
  };

  const onCreate = () => {
    mutateCreate(
      {
        resource: PATH.cases,
        values: {
          description: description || "",
          departmentId,
        },
        metaData: {
          type: `/nonsale?description=${
            description || ""
          }&departmentId=${departmentId}`,
        },
      },
      {
        onSuccess(data, variables, context) {
          notification.success({ message: "Tạo mới thành công" });
          setIsAdding(false);
          toggleModalConfirmCreate();
        },
        onError(error, variables, context) {
          notification.error({ message: "Tạo mới thất bại" });
          toggleModalConfirmCreate();
        },
      }
    );
  };

  const onEdit = () => {
    mutateUpdate(
      {
        id: rowSelected?.id,
        resource: PATH.cases,
        values: {
          description,
          statusId,
        },
        metaData: {
          type: `/nonsale`,
        },
      },
      {
        onSuccess(data, variables, context) {
          notification.success({ message: "Chỉnh sửa thành công" });
          setRowSelected(undefined);
          toggleModalConfirmCreate();
        },
        onError(error, variables, context) {
          notification.error({ message: "Chỉnh sửa thất bại" });
          toggleModalConfirmCreate();
        },
      }
    );
  };

  const onRemove = (record: ICase) => {
    mutateDelete(
      {
        resource: PATH.cases,
        id: record?.id!,
        metaData: { url: `api/${PATH.cases}/nonsale` },
      },
      {
        onSuccess(data, variables, context) {
          notification.success({ message: "Xóa thành công" });
          draftRowDelete.current = undefined;
          toggleModalConfirmDelete();
        },
        onError(error, variables, context) {
          notification.error({ message: "Xóa thất bại" });
          draftRowDelete.current = undefined;
          toggleModalConfirmDelete();
        },
      }
    );
  };

  const description = useWatch("description", form);
  const departmentId = useWatch("departmentId", form);
  const statusId = useWatch("statusId", form);

  const isChanged = useMemo(() => {
    return (
      rowSelected?.description !== description ||
      rowSelected?.statusId !== statusId
    );
  }, [description, statusId, rowSelected]);

  const onFinishedForm = () => {
    if (rowSelected && !isChanged) {
      setRowSelected(undefined);
    } else {
      toggleModalConfirmCreate();
    }
  };

  return (
    <>
      <List
        headerButtons={<></>}
        // title={translate("cases.titles.list")}
        breadcrumb={null}
      >
        <div className="list-content">
          <Form {...filterFormProps}>
            <div className="flex justify-between">
              <Space>
                <SearchBox
                  placeholder={translate("cases.searchPlaceHolder")}
                  onSearch={onSearch}
                />
                <Form.Item name="departmentId" style={{ marginBottom: 0 }}>
                  <Select
                    {...departmentSelectProps}
                    allowClear
                    onChange={(e) => {
                      setFilters([
                        {
                          field: "departmentId",
                          operator: "eq",
                          value: e,
                        },
                      ]);
                    }}
                    className={"placeholderBlack"}
                    dropdownStyle={{ color: "black" }}
                    style={{ width: 150, marginBottom: 16 }}
                    bordered={false}
                    placeholder={translate("cases.fields.department")}
                    filterOption={(inputValue: string, option: any) => {
                      return option && searchSelect(inputValue, option?.label);
                    }}
                  />
                </Form.Item>
                {filters &&
                  filters.length > 0 &&
                  !(
                    filters.length === 1 && filters[0]?.operator === "containss"
                  ) && (
                    <Button
                      type="text"
                      onClick={(e) => {
                        filterForm.resetFields();
                        setFilters([], "replace");
                      }}
                      style={{ color: "#8C8C8C", marginBottom: 16 }}
                    >
                      {translate("common.cleanFilter")}
                      <Icons.CloseOutlined />
                    </Button>
                  )}
              </Space>
              <ButtonTooltip
                type="primary"
                icon={<Icons.PlusOutlined />}
                onClick={onAdd}
                {...checkCreateHaveHelperText(PATH.caseNonSales)}
              >
                {translate("actions.create")}
              </ButtonTooltip>
            </div>
            <Form.Item name={["caseStatusCode"]} style={{ marginBottom: 0 }}>
              <Radio.Group
                defaultValue={-1}
                buttonStyle="solid"
                onChange={(e) => {
                  setCurrent(1);
                  setFilters([
                    {
                      field: "caseStatusCode",
                      operator: "eq",
                      value: e?.target?.value === -1 ? "" : e?.target?.value,
                    },
                  ]);
                }}
                style={{ display: "block", marginBottom: 16 }}
              >
                <Radio.Button value={-1}>
                  {translate("common.all")}
                </Radio.Button>
                {CASE_STATUS_OPTIONS.map(
                  ({ label: csLabel, value: csValue }) => {
                    return (
                      <Radio.Button value={csValue}>
                        {translate(csLabel as string)}
                      </Radio.Button>
                    );
                  }
                )}
              </Radio.Group>
            </Form.Item>
          </Form>

          <Form {...formProps} onFinish={onFinishedForm}>
            <Table
              dataSource={dataList}
              rowKey="code"
              locale={{
                emptyText: (
                  <EmptyData
                    text={isSearch ? translate("common.noDataSearch") : ""}
                  />
                ),
              }}
              onRow={(record: any) => {
                return {
                  onClick: () => {
                    if (record?.isCreate || isEditingRow(record)) return;
                    navigate(`show/${record.id}`);
                  },
                };
              }}
              pagination={{
                pageSize: isAdding ? pageSize + 1 : pageSize,
                current: current,
                total: dataTable?.total,
                showSizeChanger: true,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                onShowSizeChange(_, size) {
                  setIsAdding(false);
                  setPageSize(size);
                },
                onChange: (cur) => {
                  setCurrent(cur);
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0;
                },
                locale: {
                  items_per_page: translate("common.pageNumber"),
                },
                showTotal: (total) => (
                  <p>{translate("common.totalPage", { total: total })}</p>
                ),
              }}
              onChange={(_, f, s) => {
                const newSort = s as any;
                setSorter([
                  {
                    field:
                      newSort?.field === "department"
                        ? "department.name"
                        : newSort?.field,
                    order: newSort?.order,
                  },
                ]);
              }}
              showSorterTooltip={false}
              scroll={{ x: "1900px" }}
            >
              <Table.Column
                key="id"
                dataIndex="id"
                title={translate("common.no")}
                width={60}
                render={(_, __, index) =>
                  isAddingRow(index)
                    ? "-"
                    : (current - 1) * pageSize + index + (isAdding ? 0 : 1)
                }
              />
              <Table.Column
                dataIndex="code"
                title={translate("cases.fields.code")}
                defaultSortOrder={getDefaultSortOrder("code", sorter)}
                width="170px"
                sorter
                render={(value, _, index) => {
                  return (
                    <DataText
                      className="purple"
                      value={value}
                      defaultValue="-"
                    />
                  );
                }}
              />
              <Table.Column
                dataIndex="description"
                title={translate("cases.fields.name")}
                defaultSortOrder={getDefaultSortOrder("description", sorter)}
                sorter
                render={(value, record, index) => {
                  if (isAddingRow(index) || isEditingRow(record)) {
                    return (
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        name="description"
                        rules={[
                          {
                            max: 1000,
                            message: translate("errors.ER014", { max: 1000 }),
                          },
                        ]}
                      >
                        <Input autoFocus placeholder="Nhập mô tả" />
                      </Form.Item>
                    );
                  }

                  return <DataText value={value} defaultValue="-" />;
                }}
              />
              <Table.Column
                dataIndex="department"
                title={translate("cases.fields.department")}
                width={220}
                render={(value, record, index) => {
                  if (isAddingRow(index)) {
                    return (
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        name="departmentId"
                        rules={[
                          {
                            required: true,
                            message: translate("errors.ER005"),
                          },
                        ]}
                      >
                        <Select
                          {...departmentSelectProps}
                          placeholder="Chọn phòng ban"
                          className="w-full"
                          filterOption={(inputValue: string, option: any) => {
                            return (
                              option && searchSelect(inputValue, option?.label)
                            );
                          }}
                        />
                      </Form.Item>
                    );
                  }
                  return <DataText value={value?.name} defaultValue="-" />;
                }}
                defaultSortOrder={getDefaultSortOrder("department", sorter)}
                sorter
              />
              <Table.Column
                width={180}
                dataIndex="status"
                title={translate("payments.fields.status")}
                render={(value, record: ICase, index) => {
                  const isNotDraft = record?.status?.code !== CaseStatus.Draft;
                  if (isAddingRow(index)) {
                    return "-";
                  }
                  if (isEditingRow(record)) {
                    return (
                      <Form.Item name="statusId" style={{ marginBottom: 0 }}>
                        <Select
                          {...statusSelectProps}
                          options={
                            isNotDraft
                              ? statusSelectProps?.options
                              : [
                                  ...(statusSelectProps?.options ?? []),
                                  { label: "Nháp", value: record?.status?.id },
                                ]
                          }
                        />
                      </Form.Item>
                    );
                  }
                  return (
                    <Tag color={getTagColor(record?.status?.code)}>
                      {record?.status?.name}
                    </Tag>
                  );
                }}
              />

              <Table.Column
                key="syncStatus"
                dataIndex="syncStatus"
                title={translate("integration.syncStatus")}
                render={(_, record: ICase, __) => {
                  return <IntegrationStatusTag status={record?.syncStatus} />;
                }}
              />

              <Table.Column
                width={200}
                dataIndex="createdName"
                title={translate("common.createdUsername")}
                render={(value, _, index) => {
                  if (isAddingRow(index)) {
                    return "-";
                  }
                  return <TextField className="primary" value={value} />;
                }}
                defaultSortOrder={getDefaultSortOrder("createdName", sorter)}
                sorter
              />
              <Table.Column
                width={200}
                dataIndex="createdDate"
                title={translate("common.createdDate")}
                defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
                sorter
                render={(value, _, index) => {
                  if (isAddingRow(index)) {
                    return "-";
                  }
                  return value && <DateTimeFieldCustom value={value} />;
                }}
              />
              <Table.Column
                width={200}
                dataIndex="updatedName"
                title={translate("common.updatedUsername")}
                render={(value, _, index) => {
                  if (isAddingRow(index)) {
                    return "-";
                  }
                  return <TextField className="primary" value={value} />;
                }}
                defaultSortOrder={getDefaultSortOrder("updatedName", sorter)}
                sorter
              />
              <Table.Column
                width={200}
                dataIndex="updatedDate"
                title={translate("common.updatedDate")}
                defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
                sorter
                render={(value, _, index) => {
                  if (isAddingRow(index)) {
                    return "-";
                  }
                  return value && <DateTimeFieldCustom value={value} />;
                }}
              />
              <Table.Column
                title={translate("table.actions")}
                dataIndex="actions"
                align="center"
                fixed="right"
                width={120}
                onCell={() => ({
                  onClick: (e) => {
                    e.stopPropagation();
                  },
                })}
                render={(_, record: any, index) => {
                  if (isAddingRow(index) || isEditingRow(record)) {
                    return (
                      <Space>
                        <ButtonTooltip
                          type="text"
                          icon={<Icons.CheckCircleOutlined />}
                          className="green"
                          {...saveButtonProps}
                        />
                        <ButtonConfirm
                          danger
                          type="text"
                          icon={<Icons.CloseCircleOutlined />}
                          className="red"
                          noCancel={false}
                          description="Sau khi hủy bỏ, mọi tháo tác của bạn sẽ không được ghi nhận!"
                          onClick={(e) =>
                            isAddingRow(index)
                              ? setIsAdding(false)
                              : setRowSelected(undefined)
                          }
                        />
                      </Space>
                    );
                  }
                  return (
                    <Space>
                      <ButtonConfirm
                        type="text"
                        icon={<Icons.EditOutlined />}
                        className="gray"
                        visible={
                          visibleModalConfirmChangeRow &&
                          draftRowSelect?.current?.id === record?.id
                        }
                        toggle={() => {
                          draftRowSelect.current = record;

                          if ((!rowSelected || !isChanged) && !isAdding) {
                            setIsAdding(false);
                            form.setFieldsValue({ ...record });
                            setRowSelected(record);
                            return;
                          } else {
                            toggleModalConfirmChangeRow();
                          }
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleModalConfirmChangeRow();
                          setIsAdding(false);
                          form.setFieldsValue({ ...record });
                          setRowSelected(record);
                        }}
                        danger
                        noCancel={false}
                        text={isAdding ? "Tạo mới MVV" : "Chỉnh sửa MVV"}
                        description="Sau khi hủy bỏ, mọi tháo tác của bạn sẽ không được ghi nhận!"
                        {...checkEditHaveHelperText(PATH.caseNonSales)}
                      />
                      <ButtonConfirm
                        type="text"
                        icon={<Icons.DeleteOutlined />}
                        className="gray"
                        danger
                        noCancel={false}
                        text={
                          <span>
                            Bạn muốn xóa MVV nonsale này:{" "}
                            <span className="primary">
                              {draftRowDelete?.current?.code}
                            </span>{" "}
                            ?
                          </span>
                        }
                        // description={
                        //   <>
                        //     <div>
                        //       Lưu ý: MVV nonsale chỉ được xóa khi chưa có bản
                        //       ghi nào.
                        //     </div>
                        //     <div>
                        //       Đối với MVV nonsale đã có bản ghi, hệ thống chỉ
                        //       chuyển trạng thái MVV sang *Ngưng hoạt động*.
                        //     </div>
                        //   </>
                        // }
                        visible={
                          visibleModalConfirmDelete &&
                          draftRowDelete.current?.id === record?.id
                        }
                        toggle={() => {
                          draftRowDelete.current = record;
                          toggleModalConfirmDelete();
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          onRemove(record);
                        }}
                        loading={isLoadingDelete}
                        {...checkDeleteHaveHelperText(PATH.caseNonSales)}
                      />
                    </Space>
                  );
                }}
              />
            </Table>
          </Form>
        </div>
      </List>
      <Modal
        title=""
        visible={visibleModalConfirmCreate}
        onOk={onSubmit}
        onCancel={toggleModalConfirmCreate}
        okText={translate("buttons.confirm")}
        cancelText={translate("buttons.reject")}
        okButtonProps={{ loading: isLoading }}
        cancelButtonProps={{ disabled: isLoading }}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <div>
            <Text style={{ fontWeight: 500, fontSize: 16 }}>
              {isAdding
                ? "Bạn muốn tạo MVV này?"
                : "Bạn muốn chỉnh sửa MVV này?"}
            </Text>
          </div>
        </Space>
      </Modal>
    </>
  );
};
