import { Descriptions } from "@pankod/refine-antd";
import { ProcessApprovalStatusLabel } from "components/ApprovalStatusLabel";
import { DataText } from "components/DataText";
import { DATE_AND_TIME_FORMAT } from "configs/constants";
import { IPayment } from "interfaces";
import { Typography } from "@pankod/refine-antd";
import { formatISODateTimeToView } from "utils/commons";
import { useTranslate } from "@pankod/refine-core";

const { Text } = Typography;

interface props {
  record?: IPayment;
  data?: any;
  renderStatus: React.ReactNode;
}

export const SummaryApproval = ({ record, data, renderStatus }: props) => {
  const t = useTranslate();

  return (
    <Descriptions
      column={1}
      title={t("payments.info")}
      layout="vertical"
      className="no-padding"
    >
      <Descriptions.Item label={t("payments.fields.name")}>
        <DataText value={data?.requestName} />
      </Descriptions.Item>
      <Descriptions.Item label={t("payments.fields.code")}>
        <DataText value={data?.requestCode} />
      </Descriptions.Item>
      <Descriptions.Item label={t("payments.fields.submitter")}>
        <DataText
          value={
            data?.submitterName
              ? `${data?.submitterCode} - ${data?.submitterName}`
              : undefined
          }
        />
      </Descriptions.Item>
      <Descriptions.Item label={t("payments.fields.department")}>
        <DataText value={data?.department?.name} />
      </Descriptions.Item>
      <Descriptions.Item label={t("payments.fields.submitRequestDate")}>
        <DataText
          value={
            data?.submitRequestDate
              ? formatISODateTimeToView(
                  data?.submitRequestDate?.toString(),
                  DATE_AND_TIME_FORMAT
                )
              : ""
          }
        />
      </Descriptions.Item>
      <Descriptions.Item label={t("payments.fields.receivedTime")}>
        <DataText
          value={
            data?.receivedTime
              ? formatISODateTimeToView(
                  data?.receivedTime?.toString(),
                  DATE_AND_TIME_FORMAT
                )
              : ""
          }
        />
      </Descriptions.Item>
      <Descriptions.Item label={t("payments.fields.processStatus")}>
        {renderStatus}
      </Descriptions.Item>
      <Descriptions.Item label={t("payments.fields.processedEmployees")}>
        {data?.processedEmployees?.length > 0 ? (
          <Text>
            {data?.processedEmployees.map((el: any, index: number) => (
              <span key={index}>{`${el?.code} - ${el?.fullName}${
                index !== data?.processedEmployees?.length - 1 ? ", " : ""
              }`}</span>
            ))}
          </Text>
        ) : (
          <Text type="secondary">{t("common.emptyText")}</Text>
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};
