import {
  Button,
  Col,
  Form,
  Icons,
  Input,
  Row,
  Select,
  Table,
  Typography,
  useSelect,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import ButtonConfirm from "components/ButtonConfirm";
import { API_PATH } from "configs/path";
import { cloneDeep } from "lodash";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useContext,
  useEffect,
} from "react";
import { searchSelect } from "utils/commons";
import { v4 as uuid } from "uuid";
import { IForm, ServiceRequestFormContext } from "../../..";
import { Currency } from "components/Currency";
import { ServiceRequestType } from "interfaces/ServiceRequest";
import { useFormRule } from "hooks/useFormRule";

const { Title } = Typography;

interface Props {
  list: string[];
  setList: Dispatch<SetStateAction<string[]>>;
  loadingProduct: boolean;
  onFetchProduct: () => void;
}
const { TextArea } = Input;
const { useWatch, useFormInstance } = Form;

const CaseInfo: FC<Props> = memo((props) => {
  const { initialValues, isEdit } = useContext(ServiceRequestFormContext);
  const { required } = useFormRule();
  const form = useFormInstance<IForm>();
  const { list, setList, loadingProduct, onFetchProduct } = props;
  const translate = useTranslate();
  const isShowRemoveButton = list.length > 1;

  const listServiceRequestProduct = useWatch("listServiceRequestProduct", form);
  const serviceRequestTypeCode = useWatch("serviceRequestTypeCode", form);
  const caseId = useWatch("caseId", form);

  const isImplementation =
    serviceRequestTypeCode === ServiceRequestType.Implementation;

  const onRemoveProduct = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  const { selectProps: productSelectProps } = useSelect({
    resource: API_PATH.serviceRequestProductDropdownlist,
    optionLabel: "label",
    optionValue: "id",
    metaData: {
      formatData: (r: any) => ({
        ...r,
        label: `${r.code} - ${r.name}`,
      }),
    },
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const { selectProps: unitSelectProps } = useSelect({
    resource: API_PATH.serviceRequestUnitDropdownlist,
    optionLabel: "label",
    optionValue: "value",
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  useEffect(() => {
    if (isEdit) {
      const ids: string[] = [];
      const data: any = {};

      initialValues?.serviceRequestView?.serviceRequestViewProducts?.forEach(
        (product) => {
          const id = uuid();
          ids.push(id);
          data[id] = {
            ...product,
            productId: product.product?.id,
            unitId: product?.unit?.id,
          };
        }
      );

      setList(ids.length ? ids : [uuid()]);
      setTimeout(
        () => form.setFieldsValue({ listServiceRequestProduct: data }),
        0
      );
    }
  }, [isEdit]);

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Row style={{ marginBottom: 8 }} gutter={8}>
        <Col flex={1}>
          <Title level={5}>{translate("Danh sách sản phẩm")}</Title>
        </Col>
        <Col hidden={!caseId || isImplementation}>
          <ButtonConfirm
            type="primary"
            onClick={onFetchProduct}
            text={translate(
              "Bạn muốn lấy dữ liệu danh sách sản phẩm/dịch vụ từ CHKD?"
            )}
            noCancel={false}
            description={
              "Lưu ý: Sau khi lấy dữ liệu, các thông tin trong danh sách sản phẩm hiện tại sẽ bị xóa."
            }
          >
            {translate("Lấy từ CHKD")}
          </ButtonConfirm>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setList([...list, uuid()]);
            }}
            icon={<Icons.PlusOutlined />}
          >
            {translate("actions.create")}
          </Button>
        </Col>
      </Row>
      <div style={{ paddingLeft: 16 }}>
        <Table<String>
          size="small"
          className="vertical-align-top"
          dataSource={list}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 1000,
          }}
          locale={{
            emptyText: " ",
          }}
          scroll={{ x: 1400 }}
          loading={loadingProduct}
        >
          <Table.Column
            title={translate("STT")}
            dataIndex="code"
            width={50}
            align="center"
            render={(v, keyRow: string, index) => index + 1}
          />
          <Table.Column
            title={translate("Tên sản phẩm")}
            dataIndex="productGroup"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <>
                  <Form.Item hidden name={["listServiceRequestProduct", keyRow, "id"]}/>
                  <Form.Item
                    style={{ margin: 0 }}
                    name={["listServiceRequestProduct", keyRow, "productId"]}
                    rules={required}
                  >
                    <Select
                      {...productSelectProps}
                      placeholder="Chọn sản phẩm"
                      filterOption={(inputValue: string, option: any) => {
                        return (
                          option && searchSelect(inputValue, option?.label)
                        );
                      }}
                    />
                  </Form.Item>
                </>
              );
            }}
          />
          <Table.Column
            title={translate("Part number")}
            dataIndex="partNumber"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <Form.Item
                  style={{ margin: 0 }}
                  name={["listServiceRequestProduct", keyRow, "partNumber"]}
                  rules={[
                    {
                      max: 200,
                      message: translate("errors.ER014", { max: "200" }),
                    },
                  ]}
                >
                  <TextArea
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    resource=""
                    placeholder="Nhập part number"
                  />
                </Form.Item>
              );
            }}
          />
          <Table.Column
            title={translate("Mô tả")}
            dataIndex="description"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <Form.Item
                  style={{ margin: 0 }}
                  name={["listServiceRequestProduct", keyRow, "description"]}
                  rules={[
                    ...required,
                    {
                      max: 3000,
                      message: translate("errors.ER014", { max: "3000" }),
                    },
                  ]}
                >
                  <TextArea
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    placeholder="Nhập mô tả part number"
                  />
                </Form.Item>
              );
            }}
          />
          <Table.Column
            title={translate("Số lượng")}
            dataIndex="productGroup"
            width={100}
            render={(v, keyRow: string, index) => {
              return (
                <InputMoney
                  style={{ margin: 0 }}
                  name={["listServiceRequestProduct", keyRow, "quantity"]}
                  placeholder={translate("Nhập số lượng")}
                  isRequired
                  isLimit
                />
              );
            }}
          />
          <Table.Column
            title={translate("Đơn vị tính")}
            dataIndex="productGroup"
            width={100}
            render={(v, keyRow: string, index) => {
              return (
                <Form.Item
                  style={{ margin: 0 }}
                  name={["listServiceRequestProduct", keyRow, "unitId"]}
                  rules={required}
                >
                  <Select
                    {...unitSelectProps}
                    placeholder="Chọn đơn vị"
                    filterOption={(inputValue: string, option: any) => {
                      return option && searchSelect(inputValue, option?.label);
                    }}
                  />
                </Form.Item>
              );
            }}
          />
          <Table.Column
            title={translate("Đơn giá bán")}
            dataIndex="productGroup"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <InputMoney
                  style={{ margin: 0 }}
                  name={["listServiceRequestProduct", keyRow, "unitPrice"]}
                  placeholder={translate("Nhập giá trị")}
                  isRequired
                  isLimit
                  allowDecimal
                />
              );
            }}
          />
          <Table.Column
            title={translate("Thành tiền")}
            dataIndex="name"
            width={200}
            render={(v, keyRow: string) => {
              const unitPrice =
                listServiceRequestProduct?.[keyRow]?.unitPrice || 0;
              const quantity =
                listServiceRequestProduct?.[keyRow]?.quantity || 0;
              const money = unitPrice * quantity;
              return <Currency value={money} showCurrency={false} />;
            }}
          />
          <Table.Column
            align="center"
            width={90}
            title={translate("table.actions")}
            fixed="right"
            render={(v, keyRow, index) => {
              if (isShowRemoveButton)
                return (
                  <ButtonConfirm
                    text={translate("Bạn muốn xóa sản phẩm dịch vụ này?")}
                    description=""
                    onClick={onRemoveProduct(index)}
                    type="text"
                    danger
                    noCancel={false}
                  >
                    <Icons.DeleteOutlined />
                  </ButtonConfirm>
                );
            }}
          />
        </Table>
      </div>
    </div>
  );
});

export default CaseInfo;
