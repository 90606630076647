import { Collapse, Table, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { FC, memo, useCallback, useContext, useMemo } from "react";
import styled from "styled-components";
import { SettlementShowContext } from "../../..";
import type { ColumnsType } from "antd/es/table";
import { SettlementLaborManday } from "interfaces/Settlement";
import { DataText } from "components";
import { isEmpty } from "utils/commons";
import { Currency } from "components/Currency";

interface RowData extends SettlementLaborManday {
  employee?: any;
  isChildren?: boolean;
}

const { Panel } = Collapse;
const { Text } = Typography;

const StyledWrapper = styled.div`
  margin-top: 24px;
`;

const CostByManday: FC = memo(() => {
  const translate = useTranslate();
  const settlement = useContext(SettlementShowContext);

  const {
    isAllProducts,
    totalEstimatedQuantityMandayTable,
    totalEstimatedValueMandayTable,
    totalSettlementQuantityMandayTable,
    totalSettlementValueMandayTable,
    settlementLaborMandays = [],
  } = settlement;

  const isSum = useCallback((cost: RowData) => cost.refId === "sum", []);

  const isChildren = useCallback((cost: RowData) => cost.isChildren, []);

  const dataTable = useMemo(() => {
    const datas: Partial<RowData>[] = [];
    settlementLaborMandays.forEach((cost) => {
      datas.push(cost);
      if (cost.settlementLaborEmployees) {
        cost.settlementLaborEmployees.forEach((emp) => {
          datas.push({
            ...emp,
            isChildren: true,
            employee: `${emp?.employee?.code} - ${emp?.employee?.name}`,
          });
        });
      }
    });
    datas.push({ refId: "sum" });
    return datas;
  }, [settlementLaborMandays]);

  const columns = useMemo<ColumnsType<RowData>>(
    () => [
      {
        title: translate("STT"),
        width: 70,
        dataIndex: "id",
        key: "id",
        render: (_, record, index) => {
          if (isSum(record) || isChildren(record)) return null;
          return <DataText value={index + 1} />;
        },
      },
      {
        title: translate("Tên sản phẩm"),
        width: 250,
        dataIndex: "productName",
        key: "productName",
        render: (_, record) => {
          if (isSum(record) || isChildren(record)) return null;
          return (
            <DataText
              value={
                isAllProducts
                  ? "Tất cả sản phẩm"
                  : `${record?.product?.code} - ${record?.product?.name}`
              }
            />
          );
        },
        sorter: (a, b) => a?.product?.code?.length - b?.product?.code?.length,
      },
      {
        title: translate("Công việc"),
        width: 200,
        dataIndex: "internalRateCard",
        key: "internalRateCard",
        render: (_, record) => {
          if (isSum(record) || isChildren(record)) return null;
          return (
            <DataText
              value={
                !isEmpty(record?.proportion)
                  ? "-"
                  : record?.internalRateCard?.position
              }
            />
          );
        },
        sorter: (a, b) =>
          a?.internalRateCard?.position?.length -
          b?.internalRateCard?.position?.length,
      },
      {
        title: translate("Tỷ lệ"),
        width: 100,
        dataIndex: "proportionPolicy",
        key: "proportionPolicy",
        render: (_, record) => {
          if (isSum(record) || isChildren(record)) return null;
          return (
            <DataText
              value={
                !isEmpty(record?.proportion) ? (
                  <Currency
                    value={record?.proportion * 100}
                    showCurrency={false}
                    after="%"
                  />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.proportion - b?.proportion,
      },
      {
        title: translate("Đơn giá"),
        width: 200,
        dataIndex: "unitPrice",
        key: "unitPrice",
        render: (_, record) => {
          if (isSum(record))
            return <Text className="font-bold">{translate("Tổng cộng")}</Text>;
          if (isChildren(record)) return null;
          return (
            <DataText
              value={
                record?.unitPrice ? (
                  <Currency value={record?.unitPrice} showCurrency={false} />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.unitPrice - b?.unitPrice,
      },
      {
        title: translate(`Số lượng dự toán (manday)`),
        width: 100,
        dataIndex: "estimateQuantity",
        key: "estimateQuantity",
        render: (_, record) => {
          if (isSum(record))
            return (
              <Currency
                value={totalEstimatedQuantityMandayTable}
                showCurrency={false}
              />
            );
          if (isChildren(record)) return null;
          return (
            <DataText
              value={
                !isEmpty(record?.proportion) ? (
                  "-"
                ) : (
                  <Currency
                    value={record?.estimateQuantity}
                    showCurrency={false}
                  />
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.estimateQuantity - b?.estimateQuantity,
      },
      {
        title: translate("Thành tiền dự toán"),
        width: 200,
        dataIndex: "estimatedValue",
        key: "estimatedValue",
        render: (_, record) => {
          if (isSum(record))
            return (
              <Currency
                value={totalEstimatedValueMandayTable}
                showCurrency={false}
              />
            );
          if (isChildren(record)) return null;
          return (
            <DataText
              value={
                record?.estimatedValue ? (
                  <Currency
                    value={record?.estimatedValue}
                    showCurrency={false}
                  />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.estimatedValue - b?.estimatedValue,
      },
      {
        title: translate("Nhân sự"),
        width: 250,
        dataIndex: "employee",
        key: "employee",
        render: (_, record) => {
          if (isSum(record) || !isChildren(record)) return null;
          return <DataText value={record?.employee || "-"} />;
        },
        sorter: (a, b) => a?.employee - b?.employee,
      },
      {
        title: translate("Số lượng quyết toán (manday)"),
        width: 200,
        dataIndex: "settlementQuantity",
        key: "settlementQuantity",
        render: (_, record) => {
          if (isSum(record))
            return (
              <Currency
                value={totalSettlementQuantityMandayTable}
                showCurrency={false}
              />
            );
          return (
            <DataText
              value={
                record?.settlementQuantity ? (
                  <Currency
                    value={record?.settlementQuantity}
                    showCurrency={false}
                  />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.estimatedValue - b?.estimatedValue,
      },
      {
        title: translate("Thành tiền quyết toán"),
        width: 200,
        dataIndex: "settlementValue",
        key: "settlementValue",
        render: (_, record) => {
          if (isSum(record))
            return (
              <Currency
                value={totalSettlementValueMandayTable}
                showCurrency={false}
              />
            );
          return (
            <DataText
              value={
                record?.settlementValue ? (
                  <Currency
                    value={record?.settlementValue}
                    showCurrency={false}
                  />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.settlementValue - b?.settlementValue,
      },
    ],
    [
      isAllProducts,
      totalEstimatedQuantityMandayTable,
      totalEstimatedValueMandayTable,
      totalSettlementQuantityMandayTable,
      totalSettlementValueMandayTable,
      isChildren,
      isSum,
      translate,
    ]
  );

  return (
    <StyledWrapper>
      <Collapse
        className="w-full"
        defaultActiveKey={["1"]}
        ghost
        collapsible="header"
      >
        <Panel header={translate("Nhân công")} key="1">
          <Table
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
            rowKey="id"
            columns={columns}
            dataSource={dataTable as RowData[]}
            scroll={{ x: 1770 }}
            style={{ marginTop: 16 }}
          />
        </Panel>
      </Collapse>
    </StyledWrapper>
  );
});

export default CostByManday;
