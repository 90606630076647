import { Collapse, Table, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { SettlementCost } from "interfaces/Settlement";
import { FC, memo, useCallback, useContext, useMemo } from "react";
import type { ColumnsType } from "antd/es/table";
import { DataText } from "components";
import { SettlementShowContext } from "../../..";
import { isEmpty } from "utils/commons";
import { Currency } from "components/Currency";
const { Panel } = Collapse;
const { Text } = Typography;

const CostByMoney: FC = memo(() => {
  const translate = useTranslate();
  const settlement = useContext(SettlementShowContext);

  const {
    isAllProducts,
    totalQuantityCostTable,
    totalEstimatedCostTable,
    totalSettlementCostTable,
  } = settlement;

  const isSum = useCallback((cost: SettlementCost) => cost.refId === "sum", []);

  const columns = useMemo<ColumnsType<SettlementCost>>(
    () => [
      {
        title: translate("STT"),
        width: 70,
        dataIndex: "id",
        key: "id",
        render: (_, record, index) => {
          if (isSum(record)) return null;
          return <DataText value={index + 1} />;
        },
      },
      {
        title: translate("Tên sản phẩm"),
        width: 250,
        dataIndex: "productName",
        key: "productName",
        render: (_, record) => {
          if (isSum(record)) return null;
          return (
            <DataText
              value={
                isAllProducts
                  ? "Tất cả sản phẩm"
                  : `${record?.product?.code} - ${record?.product?.name}`
              }
            />
          );
        },
        sorter: (a, b) => a?.product?.code?.length - b?.product?.code?.length,
      },
      {
        title: translate("Tên chi phí"),
        width: 200,
        dataIndex: "cost",
        key: "cost",
        render: (_, record) => {
          if (isSum(record)) return null;
          return (
            <DataText
              value={
                isEmpty(record?.proportion)
                  ? `${record?.cost?.code} - ${record?.cost?.name}`
                  : "-"
              }
            />
          );
        },
        sorter: (a, b) => a?.cost?.code?.length - b?.cost?.code?.length,
      },
      {
        title: translate("Ghi chú"),
        width: 200,
        dataIndex: "note",
        key: "note",
        render: (_, record) => {
          if (isSum(record)) return null;
          return <DataText value={record?.note || "-"} />;
        },
      },
      {
        title: translate("Tỷ lệ"),
        width: 100,
        dataIndex: "proportionPolicy",
        key: "proportionPolicy",
        render: (_, record) => {
          if (isSum(record)) return null;
          return (
            <DataText
              value={
                isEmpty(record?.proportion) ? (
                  "-"
                ) : (
                  <Currency
                    value={record?.proportion * 100}
                    showCurrency={false}
                    after="%"
                  />
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.proportion - b?.proportion,
      },

      {
        title: translate("Đơn giá"),
        width: 200,
        dataIndex: "unitPrice",
        key: "unitPrice",
        render: (_, record) => {
          if (isSum(record))
            return <Text className="font-bold">{translate("Tổng cộng")}</Text>;

          return (
            <DataText
              value={
                record?.unitPrice ? (
                  <Currency value={record?.unitPrice} showCurrency={false} />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.unitPrice - b?.unitPrice,
      },
      {
        title: translate("Số lượng dự toán"),
        width: 100,
        dataIndex: "quantity",
        key: "quantity",
        render: (_, record) => {
          if (isSum(record))
            return (
              <Currency value={totalQuantityCostTable} showCurrency={false} />
            );
          return (
            <DataText
              value={
                isEmpty(record?.proportion) ? (
                  <Currency value={record?.quantity} showCurrency={false} />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.quantity - b?.quantity,
      },
      {
        title: translate("Thành tiền dự toán"),
        width: 200,
        dataIndex: "estimatedValue",
        key: "estimatedValue",
        render: (_, record) => {
          if (isSum(record))
            return (
              <Currency value={totalEstimatedCostTable} showCurrency={false} />
            );
          return (
            <DataText
              value={
                record?.estimatedValue ? (
                  <Currency
                    value={record?.estimatedValue}
                    showCurrency={false}
                  />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.estimatedValue - b?.estimatedValue,
      },
      {
        title: translate("Thành tiền quyết toán"),
        width: 200,
        dataIndex: "settlementValue",
        key: "settlementValue",
        render: (_, record) => {
          if (isSum(record))
            return (
              <Currency value={totalSettlementCostTable} showCurrency={false} />
            );
          return (
            <DataText
              value={
                record?.settlementValue ? (
                  <Currency
                    value={record?.settlementValue}
                    showCurrency={false}
                  />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.settlementValue - b?.settlementValue,
      },
    ],
    [
      isAllProducts,
      totalEstimatedCostTable,
      totalQuantityCostTable,
      totalSettlementCostTable,
      isSum,
      translate,
    ]
  );

  return (
    <Collapse
      className="w-full"
      defaultActiveKey={["1"]}
      ghost
      collapsible="header"
    >
      <Panel header={translate("Bằng tiền")} key="1">
        <Table
          size="small"
          pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
          rowKey="id"
          columns={columns}
          dataSource={[
            ...(settlement.settlementCosts || []),
            { refId: "sum" } as SettlementCost,
          ]}
          scroll={{ x: 1520 }}
          style={{ marginTop: 16 }}
        />
      </Panel>
    </Collapse>
  );
});

export default CostByMoney;
