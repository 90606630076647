import {
  Form,
  Icons,
  Typography,
  UseSelectReturnType,
  notification,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import {
  BaseRecord,
  IResourceComponentsProps,
  useCreate,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { useParams, useSearchParams } from "@pankod/refine-react-router-v6";
import { getDataForCreateSettlement } from "api";
import { CreateCustom } from "components/layout";
import { API_PATH, PATH } from "configs/path";
import {
  DataForCreate,
  SettlemenData,
  SettlementCost,
  SettlementLaborManday,
} from "interfaces/Settlement";
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  memo,
  useEffect,
  useMemo,
  useState
} from "react";
import { isEmpty, mappingErrorFromApi } from "utils/commons";
import { v4 as uuid } from "uuid";
import SettlementForm from "./components/Form";

type ListCostByManday = {
  [key: string]: {
    settlementLaborEmployees?: {
      [key: string]: {
        employeeId: string;
        settlementQuantity: number;
        settlementValue: number | null;
      };
    };
    productName: string;
    refId: string;
    productId: string;
    internalRateCardName: string;
    internalRateCardId: string;
    proportion: number;
    unitPrice: number;
    estimateQuantity: number;
    editable: boolean;
    id?: string;
    estimatedValue: number;
  };
};

export type ListConstByMoney = {
  productName: string;
  refId?: string;
  productId: string;
  costName: string;
  costId: string;
  note: string;
  proportion: number;
  unitPrice: number;
  quantity: number;
  estimatedValue: number;
  settlementValue: number | null;
  editable: boolean;
  id?: string;
};

export interface IForm {
  caseId: string;
  content: string;
  listCostByMoney: {
    [key: string]: ListConstByMoney;
  };
  listCostByManday: ListCostByManday;
  documents: { fileId: string }[];
}

type MandayKeyState = {
  id: string;
  children: MandayKeyState[];
};

interface State {
  isEdit: boolean;
  initialValues?: any;
  onChangeCase: (caseId: string) => void;
  costByMoneyKeyState: [string[], Dispatch<SetStateAction<string[]>>];
  costByMandayKeyState: [
    MandayKeyState[],
    Dispatch<SetStateAction<MandayKeyState[]>>
  ];
  isAllProducts: boolean;
  productSelect?: UseSelectReturnType<BaseRecord>;
  costSelect?: UseSelectReturnType<BaseRecord>;
  totalSettlementQuantityManday: number;
  totalSettlementValueManday: number;
  calcTotal: <T>(listData: T[], keyCount: keyof T) => number;
  dataForCreate: DataForCreate;
}

export const SettlementFormContext = createContext<State>({
  isEdit: false,
  initialValues: {},
  onChangeCase: () => {},
  costByMoneyKeyState: [[], () => {}],
  costByMandayKeyState: [[], () => {}],
  isAllProducts: false,
  totalSettlementQuantityManday: 0,
  totalSettlementValueManday: 0,
  calcTotal: () => 0,
  dataForCreate: {},
});

const { Text } = Typography;
const { useWatch } = Form;

const SettlementCreate: FC<IResourceComponentsProps> = memo((props) => {
  const { form, formProps, saveButtonProps, formLoading } = useForm<
    any,
    any,
    IForm
  >();
  const { mutate: mutateCreate, isLoading } = useCreate<{ id: string }, any>();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>();
  const { show } = useNavigation();
  const translate = useTranslate();
  const { id } = useParams();

  const costByMoneyKeyState = useState<string[]>([]);
  const costByMandayKeyState = useState<MandayKeyState[]>([]);
  const [dataForCreate, setDataForCreate] = useState<DataForCreate>({});

  const [_, setListKeyCostByMoney] = costByMoneyKeyState;
  const [__, setListKeyCostByManday] = costByMandayKeyState;
  const [visibleModalConfirmCreate, setVisibleShowModalConfirm] =
    useState(false);

  const caseId = useWatch("caseId", form);
  const listCostByManday = useWatch("listCostByManday", form);

  const productSelect = useSelect({
    resource: API_PATH.settlementProductDropdownlist,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      formatData: (r: any) => ({
        ...r,
        name: `${r?.code} - ${r?.name}`,
      }),
    },
    filters: [
      {
        field: "caseId",
        operator: "containss",
        value: caseId,
      },
    ],
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
    queryOptions: {
      enabled: !!caseId,
    },
  });

  const costSelect = useSelect({
    resource: API_PATH.internalEstimateCostDropdownlist,
    optionLabel: "label",
    optionValue: "value",
    metaData: {
      formatData: (r: any) => ({
        ...r,
        label: `${r.code} - ${r.label}`,
      }),
    },
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const initialValues = useMemo<SettlemenData>(
    () => formProps?.initialValues as SettlemenData,
    [formProps.initialValues]
  );

  const isEdit = useMemo(() => !!id && !formLoading, [id, formLoading]);

  const toggleModalConfirm = (isOpen: boolean) =>
    setVisibleShowModalConfirm(isOpen);

  const onSubmit = (isDraft?: boolean) => () => {
    const dataForm = form.getFieldsValue();

    const settlementCosts = Object.values(dataForm.listCostByMoney || {});

    const settlementLaborMandays = Object.values(
      dataForm.listCostByManday
    )?.map((slm) => {
      const settlementLaborEmployees = Object.values(
        slm.settlementLaborEmployees || {}
      );
      const settlementValue = settlementLaborEmployees?.reduce(
        (cur, next) =>
          cur + (next.settlementValue ? Number(next.settlementValue) : 0),
        0
      );
      return {
        ...slm,
        settlementValue,
        settlementLaborEmployees,
      };
    });

    const documents = dataForm.documents?.map((d) => d.fileId);

    const dataRequest = {
      resource: PATH.settlements,
      values: {
        content: dataForm.content,
        caseId: dataForm.caseId,
        documents,
        settlementCosts,
        settlementLaborMandays,
        isAllProducts: dataForCreate.isAllProducts,
      },
      metaData: {
        type: isDraft ? "draft" : "",
      },
      id: id!,
    };
    const handleResponse = {
      onSuccess: (res: any) => {
        notification.success({
          message: translate(
            isDraft
              ? "Lưu nháp quyết toán triển khai thành công"
              : "Lưu và chuyển quyết toán triển khai thành công"
          ),
        });

        show(PATH.settlements, res?.data?.id);
      },
      onError: (error: any) => {
        toggleModalConfirm(false);
        mappingErrorFromApi(error, form);
      },
    };

    if (isEdit) {
      mutateUpdate(dataRequest, handleResponse);
    } else {
      mutateCreate(dataRequest, handleResponse);
    }
  };

  const validateDraft = async () => {
    try {
      await form.validateFields();
      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.resolve(false);
    }
  };

  const mapDataCost = (
    settlementCosts: SettlementCost[],
    settlementLaborMandays: SettlementLaborManday[]
  ) => {
    const listCostByMoney: { [key: string]: ListConstByMoney } = {};
    const listCostByManday: { [key: string]: any } = {};
    const moneyIds: string[] = [];
    const mandayIds: MandayKeyState[] = [];

    settlementCosts?.forEach((cost) => {
      const rowId = uuid();
      moneyIds.push(rowId);
      listCostByMoney[rowId] = {
        ...cost,
        editable: !!cost?.editable,
        productName:
          !!cost.product && `${cost?.product?.code} - ${cost?.product?.name}`,
        costName: !!cost.cost && `${cost?.cost?.code} - ${cost?.cost?.name}`,
        settlementValue: cost?.settlementValue || null,
      };
    });

    settlementLaborMandays?.forEach((cost) => {
      const rowId = uuid();
      listCostByManday[rowId] = {
        ...cost,
        settlementLaborEmployees: {},
        editable: !!cost?.editable,
        productName: !!cost.product
          ? `${cost?.product?.code} - ${cost?.product?.name}`
          : "",
        internalRateCardName:
          !!cost.internalRateCard && cost?.internalRateCard?.position,
      };
      if (cost?.settlementLaborEmployees) {
        cost.settlementLaborEmployees.forEach((emp) => {
          const childId = uuid();
          listCostByManday[rowId].settlementLaborEmployees[childId] = emp;
        });
      }
    });

    Object.keys(listCostByManday || {}).forEach((costKey) => {
      const settlementLaborEmployees = Object.keys(
        listCostByManday[costKey]?.settlementLaborEmployees || {}
      );
      mandayIds.push({
        id: costKey,
        children: settlementLaborEmployees.map((empKey) => ({
          id: empKey,
          children: [],
        })),
      });
    });

    setListKeyCostByMoney(moneyIds);
    setListKeyCostByManday(mandayIds);
    form.setFieldsValue({ listCostByMoney, listCostByManday });
  };

  const onChangeCase = async (caseId: string) => {
    try {
      const res = await getDataForCreateSettlement(
        API_PATH.settlementForCreate,
        caseId
      );
      mapDataCost(
        res?.data?.settlementCosts || [],
        res?.data?.settlementLaborMandays || []
      );
      setDataForCreate(res.data);
    } catch (error) {
      //
    }
  };

  const totalSettlementQuantityManday = useMemo(
    () =>
      Object.values(listCostByManday || {})
        .filter((c) => isEmpty(c.proportion))
        .reduce((cur, next) => {
          let money = 0;

          money = Object.values(next.settlementLaborEmployees || {}).reduce(
            (curE, nextE) => {
              return curE + (Number(nextE.settlementQuantity) || 0);
            },
            0
          );

          return money + cur;
        }, 0),
    [listCostByManday]
  );

  const totalSettlementValueManday = useMemo(
    () =>
      Object.values(listCostByManday || {})
        .filter((c) => isEmpty(c.proportion))
        .reduce((cur, next) => {
          let money = 0;

          money = Object.values(next.settlementLaborEmployees || {}).reduce(
            (curE, nextE) => {
              return curE + (Number(nextE.settlementValue) || 0);
            },
            0
          );

          return money + cur;
        }, 0),
    [listCostByManday]
  );

  const calcTotal = <T,>(listData: T[], keyCount: keyof T) =>
    listData.reduce((cur, next) => cur + Number(next?.[keyCount] || 0), 0);
  
  const params = useSearchParams();
  useEffect(() => {
    if(params[0].get("caseId")) {
      form.setFieldsValue({ caseId: params[0].get("caseId") ?? ""});
      onChangeCase(form.getFieldValue("caseId"));
    }
    if (isEdit && initialValues) {
      mapDataCost(
        initialValues?.settlementCosts,
        initialValues?.settlementLaborMandays
      );
      setDataForCreate({ isAllProducts: initialValues?.isAllProducts });
    }
  }, [isEdit, initialValues]);

  return (
    <SettlementFormContext.Provider
      value={{
        isEdit,
        initialValues,
        costByMoneyKeyState,
        costByMandayKeyState,
        isAllProducts: !!dataForCreate?.isAllProducts,
        productSelect,
        costSelect,
        totalSettlementQuantityManday,
        totalSettlementValueManday,
        dataForCreate,
        calcTotal,
        onChangeCase,
      }}
    >
      <CreateCustom
        {...props}
        title={
          isEdit ? (
            <Text style={{ marginBottom: 0 }}>
              {translate("Chỉnh sửa dự án")}:{" "}
              <Text className="primary">{initialValues?.case?.name}</Text>
            </Text>
          ) : (
            translate("Tạo mới quyết toán triển khai")
          )
        }
        onSubmit={onSubmit()}
        visibleShowModal={visibleModalConfirmCreate}
        setVisibleShowModal={toggleModalConfirm}
        saving={isLoading || isLoadingUpdate}
        confirmModalProps={{
          title: translate(
            isEdit
              ? "assignExploiting.editConfirm"
              : "assignExploiting.createConfirm"
          ),
        }}
        saveButtonProps={{
          ...saveButtonProps,
          title: translate(isEdit ? "common.saveEdit" : "actions.create"),
          icon: isEdit && <Icons.SaveOutlined />,
        }}
        isLoading={formLoading}
        bodyStyle={{ background: "transparent", padding: 0 }}
        contentStyles={{ background: "transparent" }}
        onDraft={onSubmit(true)}
        validateDraft={validateDraft}
        draftButtonProps={{
          title: isEdit ? "Lưu chỉnh sửa" : "Lưu nháp",
          disabled: formLoading,
        }}
        draftModalProps={{
          title: "Bạn muốn lưu nháp quyết toán triển khai này?",
        }}
        // cancelModalProps={{
        //   title: isEdit
        //     ? "Bạn muốn hủy chỉnh sửa quyết toán triển khai này"
        //     : "Bạn muốn hủy tạo quyết toán triển khai này",
        //   description:
        //     `Sau khi hủy${isEdit? "chỉnh sửa" : "tạo"} quyết toán triển khai, mọi thông tin của bạn sẽ không được lưu lại.`,
        // }}
      >
        <SettlementForm
          form={form}
          formProps={formProps}
          onFinishedFormValidate={toggleModalConfirm}
        />
      </CreateCustom>
    </SettlementFormContext.Provider>
  );
});

export default SettlementCreate;
