import { Form, FormInstance, FormProps } from "@pankod/refine-antd";
import { FC, memo } from "react";
import { IForm } from "../..";
import Settlement from "./SettlementInfo";
import CaseInfo from "./CaseInfo";
import Cost from "./Cost";

interface Props {
  form: FormInstance<IForm>;
  formProps: FormProps<IForm>;
  onFinishedFormValidate: (visible: boolean) => void;
}
const SettlementForm: FC<Props> = memo((props) => {
  const { form, formProps, onFinishedFormValidate } = props;

  return (
    <Form
      {...formProps}
      initialValues={{
        ...formProps.initialValues,
        settlementLaborEmployees: undefined,
      }}
      form={form}
      onFinish={() => onFinishedFormValidate(true)}
      layout="vertical"
      style={{ display: "flex", flexDirection: "column", gap: 8 }}
    >
      <CaseInfo />
      <Settlement />
      <Cost />
    </Form>
  );
});

export default SettlementForm;
