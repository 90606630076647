import { ApprovalStatus } from "api/enums";
import CaseId from "./CaseId";
import {
  IApproverInfomation,
  IAttachment,
  SubmitEmployee,
  WaitingProcessEmployees,
} from "interfaces";

export default interface IServiceRequest {
  approvalData: {
    approvalApproverDetail: {
      allowAccept: false;
      allowReject: false;
      allowReturn: false;
      case: null;
      createdDate: Date;
      department: {
        id: string;
        name: string;
      };
      processStatus: ApprovalStatus;
      processedEmployees: IApproverInfomation[];
      receivedTime: Date;
      requestCode: string;
      requestId: string;
      requestName: string;
      submitRequestDate: Date;
      submitterCode: string;
      submitterId: string;
      submitterName: string;
    };
    approvalSubmitterDetail: {
      allowCancel: boolean;
      allowRetrieve: boolean;
      allowSubmit: boolean;
      case: string;
      createdDate: Date;
      department: {
        id: string;
        name: string;
      };
      processedEmployees: IApproverInfomation[];
      requestCode: string;
      requestId: string;
      requestName: string;
      status: ApprovalStatus;
      submitRequestDate: string;
      submitterCode: string;
      submitterId: string;
      submitterName: string;
      waitingProcessEmployees: IApproverInfomation[];
    };
  };
  objectData: ServiceRequestData;
}

export interface ServiceRequestData {
  case: CaseId;
  code: string;
  createdDate: Date;
  createdName: string;
  description: string;
  id: string;
  name: string;
  status: ApprovalStatus;
  requestCompletionDate: string;
  requestDate: string;
  serviceRequestType: RequestType;
  serviceRequestView: {
    serviceRequestViewDocuments: IAttachment[];
    serviceRequestViewProducts: ServiceProduct[];
  };
  updatedDate: Date;
  updatedName: string;
  requestHandler: {
    code: string;
    id: string;
    name: string;
    parentId: string;
  };
  submitter: {
    code: string;
    id: string;
    name: string;
    parentId: string;
  };
}

export enum ServiceRequestType {
  Consulting = "ServiceRequest_Consulting",
  Implementation = "ServiceRequest_Implementation",
}

export type ServiceProduct = {
  code: string;
  id: string;
  name: string;
  productGroup: {
    id: string;
    code: string;
    name: string;
  };
  product: {
    code: string;
    id: string;
    name: string;
  };
  partNumber: string;
  description: string;
  quantity: number;
  totalPrice: number;
  unit: { id: string; code: string; name: string };
  unitPrice: number;
};

export type RequestType = {
  code: ServiceRequestType;
  id: string;
  name: string;
};

export enum OptionViewAproval {
  MINE,
  APPROVING,
}

export type ServiceRequestDetail = {
  code: string;
  name: string;
  description: string;
  status: ApprovalStatus;
  serviceRequestType: {
    id: string;
    code: ServiceRequestType;
    name: string;
  };
  ownerEmployee: {
    code: string;
    fullName: string;
    id: string;
  };
  requestDate: string;
  requestCompletionDate: string;
  case: {
    employeeId: string;
    ownerDepartment: {
      id: string;
      code: string;
      name: string;
    };
    customer: {
      id: string;
      code: string;
      name: string;
    };
    marketArea: {
      id: string;
      code: string;
      name: string;
    };
    ownerEmployee: {
      id: string;
      code: string;
      name: string;
    };
    businessOpportunity: {
      id: string;
      code: string;
      name: string;
    };
    businessOpportunityStatus: {
      percentSuccess: number;
      id: string;
      code: string;
      name: string;
    };
    status: {
      id: string;
      code: number;
      name: string;
    };
    createdDate: string;
    createdName: string;
    updatedDate: string;
    updatedName: string;
    id: string;
    name: string;
    code: string;
    description: string;
    type: true;
    statusId: string;
  };
  createdDate: string;
  createdName: string;
  updatedDate: string;
  updatedName: string;
  id: string;
};

export type ServiceRequestTableRow = {
  objectData: ServiceRequestData;
  submitEmployee: SubmitEmployee;
  waitingProcessEmployees: WaitingProcessEmployees[];
};
