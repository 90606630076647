import {
  Button,
  Card,
  Col,
  Form,
  Icons,
  Input,
  Modal,
  notification,
  Row,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import {
  useNavigation,
  useOne,
  useShow,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { showErrorToast } from "api/common";
import { ApprovalActionStatus, ApprovalStatus } from "api/enums";
import { ButtonTooltip } from "components";
import { ShowCustom } from "components/layout";
import usePermissions from "hooks/permission";
import {
  IApprovalApproverDetail,
  IApprovalSubmiterDetail,
  IHeaderTab,
} from "interfaces";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Histories } from "./Histories";
import { Summary } from "./Summary";
import { SummaryApproval } from "./SummaryApproval";
import {
  ApprovalStatusLabel,
  ProcessApprovalStatusLabel,
} from "components/ApprovalStatusLabel";

const { Text, Paragraph } = Typography;
const INFO_TAB = "info";
const HISTORY_TAB = "history";

interface ApprovalPageProps {
  isApprover?: boolean;
  renderInformation?: Function;
  resource: string;
  redirectPath: string;
  pageTitle?: string;
  backUrl: string;
  propsId?: string;
  showTitle?: boolean;
  showInfo?: boolean;
  tabs?: IHeaderTab[];
  tabKey?: string;
  outsideParams?: any;
  onChangeTab?: (activeTab: string) => void;
}

export const ApprovalPage: React.FC<ApprovalPageProps> = (props) => {
  const {
    isApprover,
    renderInformation,
    resource,
    pageTitle,
    redirectPath,
    backUrl,
    propsId,
    showTitle = true,
    showInfo = true,
    tabKey = "",
    tabs,
    outsideParams = {},
  } = props;

  const t = useTranslate();
  const navigate = useNavigate();
  const { list } = useNavigation();
  const { id } = useParams();
  const { checkEditHaveHelperText } = usePermissions();

  const { queryResult, showId } = useShow<any>({
    resource: resource,
    id: id,
    metaData: isApprover ? { after: "/approvals" } : undefined,
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const [activeTab, setActiveTab] = React.useState<any>(INFO_TAB);
  const [isRetrieve, showRetrieve] = useState(false);
  const [isCancel, showCancel] = useState(false);
  const [isApprove, showApprove] = useState(false);
  const [isSubmit, showSubmit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [mode, setMode] = useState(ApprovalActionStatus.Approved);

  const { mutate } = useUpdate();

  const headerTabs: IHeaderTab[] = [
    {
      name: t("approval.info"),
      key: INFO_TAB,
      isActive: true,
      hidden: false,
    },
    {
      name: t("approval.history"),
      key: HISTORY_TAB,
      isActive: false,
      hidden: false,
    },
  ];

  const onChangeTab = (activeKey: any) => {
    setActiveTab(activeKey);
  };

  const { data: approvalDataRes, isLoading: isLoadingAction } = useOne<
    IApprovalApproverDetail & IApprovalSubmiterDetail
  >({
    resource: resource,
    id: showId || "",
    metaData: {
      after: isApprover ? "/approvals/approver" : "/approvals/submitter",
    },
  });

  const approvalData = approvalDataRes?.data;

  const requiredReason =
    mode === ApprovalActionStatus.Rejected ||
    mode === ApprovalActionStatus.Returned;
  const { formProps, form, saveButtonProps } = useForm();

  const onApprove = (action: ApprovalActionStatus) => {
    showApprove(true);
    setMode(action);

    setTimeout(() => {
      form?.setFieldsValue({
        action: action,
        reason: "",
      });
    }, 300);
  };
  const onCancel = () => {
    showCancel(true);
  };
  const onRetrieve = () => {
    showRetrieve(true);
  };

  const onApprovalSuccess = () => list(backUrl);

  return (
    <>
      <ShowCustom
        {...props}
        isLoading={isLoading || isLoadingAction}
        title={
          <Text style={{ marginBottom: 0 }}>
            {isApprover ? t("approval.handle") : pageTitle}
            <span className="primary">
              {record?.code ? `: ${record?.code}` : ""}
            </span>
          </Text>
        }
        headerProps={{
          onBack: () => navigate(redirectPath),
        }}
        tabs={headerTabs}
        onChangeTab={onChangeTab}
        name={backUrl}
        recordItemId={id}
        breadcrumbText={isApprover ? t("approval.infoApproval") : pageTitle}
        contentStyles={{ backgroundColor: "transparent", padding: 0 }}
        bodyStyle={{ padding: 0 }}
        headerButtons={
          <>
            {record?.status !== ApprovalStatus.Draft && isApprover && (
              <>
                <Button
                  onClick={() => onApprove(ApprovalActionStatus.Returned)}
                  hidden={!approvalData?.allowReturn}
                >
                  {t("histories.returned")}
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() => onApprove(ApprovalActionStatus.Rejected)}
                  hidden={!approvalData?.allowReject}
                >
                  {t("histories.rejected")}
                </Button>
                <Button
                  type="primary"
                  onClick={() => onApprove(ApprovalActionStatus.Approved)}
                  hidden={!approvalData?.allowAccept}
                >
                  {t("histories.approved")}
                </Button>
              </>
            )}
            {record?.status !== ApprovalStatus.Draft && !isApprover && (
              <>
                <Button
                  icon={<Icons.UndoOutlined />}
                  type="primary"
                  onClick={() => onRetrieve()}
                  hidden={!approvalData?.allowRetrieve}
                >
                  {t("histories.retrieve")}
                </Button>

                <Button
                  hidden={!approvalData?.allowCancel}
                  onClick={() => onCancel()}
                >
                  {t("approval.cancel")}
                </Button>
              </>
            )}
            {(record?.status === ApprovalStatus.Draft ||
              record?.status === ApprovalStatus.Returned) &&
              !isApprover && (
                <ButtonTooltip
                  type="primary"
                  icon={<Icons.EditOutlined />}
                  onClick={() =>
                    navigate(`/${redirectPath}/edit/${record?.id}`)
                  }
                  {...checkEditHaveHelperText(resource)}
                >
                  {t("actions.edit")}
                </ButtonTooltip>
              )}

            {record?.status === ApprovalStatus.Returned && !isApprover && (
              <Button
                icon={<Icons.SendOutlined />}
                type="primary"
                onClick={() => showSubmit(true)}
                hidden={!approvalData?.allowSubmit}
              >
                {t("approval.send")}
              </Button>
            )}
          </>
        }
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} lg={18}>
            <Card style={{ minHeight: 400 }}>
              {activeTab === INFO_TAB &&
                renderInformation &&
                renderInformation(record)}
              {activeTab === HISTORY_TAB && record?.id && (
                <Histories
                  recordId={record?.id}
                  resource={resource}
                  recordStatus={record?.status}
                />
              )}
            </Card>
          </Col>
          <Col xs={24} lg={6}>
            <Card style={{ minHeight: 400 }}>
              {!isApprover && (
                <Summary
                  data={{ ...approvalData }}
                  renderStatus={
                    <ApprovalStatusLabel status={approvalData?.status as any} />
                  }
                />
              )}
              {isApprover && (
                <SummaryApproval
                  data={{ ...approvalData }}
                  renderStatus={
                    <ProcessApprovalStatusLabel
                      status={approvalData?.processStatus as any}
                    />
                  }
                />
              )}
            </Card>
          </Col>
        </Row>
      </ShowCustom>

      <Modal
        visible={isApprove}
        title=""
        closable={!saving}
        okText={t("buttons.confirm")}
        cancelText={t("buttons.reject")}
        style={{ maxWidth: 500 }}
        onCancel={() => {
          if (saving) return;
          showApprove(false);
        }}
        okButtonProps={{
          ...saveButtonProps,
          disabled: saving,
          loading: saving,
          danger: mode === ApprovalActionStatus.Rejected,
        }}
        cancelButtonProps={{
          disabled: saving,
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          onFinish={() => {
            setSaving(true);
            mutate(
              {
                resource: resource,
                metaData: {
                  type: "/process-approval",
                  method: "POST",
                },
                id: showId || "",
                values: form.getFieldsValue(),
              },
              {
                onError: (e) => {
                  showErrorToast(e);
                  setSaving(false);
                  showApprove(false);
                },
                onSuccess: () => {
                  showApprove(false);
                  setSaving(false);
                  onApprovalSuccess();
                  if (mode === ApprovalActionStatus.Approved) {
                    notification.success({
                      message: t("approval.message.approvedSuccess"),
                    });
                  }
                  if (mode === ApprovalActionStatus.Rejected) {
                    notification.success({
                      message: t("approval.message.rejectedSuccess"),
                    });
                  }
                  if (mode === ApprovalActionStatus.Returned) {
                    notification.success({
                      message: t("approval.message.returnedSuccess"),
                    });
                  }
                },
              }
            );
          }}
        >
          <Row>
            <Col flex="none">
              <Paragraph
                style={{ fontSize: 22, marginRight: 10 }}
                type="warning"
              >
                <Icons.InfoCircleOutlined />
              </Paragraph>
            </Col>
            <Col flex="auto">
              <Paragraph
                style={{ fontWeight: 500, fontSize: 16, marginBottom: 12 }}
              >
                {mode === ApprovalActionStatus.Returned &&
                  ` ${t("approval.message.returnConfirm")}`}
                {mode === ApprovalActionStatus.Rejected &&
                  ` ${t("approval.message.rejectConfirm")}`}
                {mode === ApprovalActionStatus.Approved &&
                  ` ${t("approval.message.approveConfirm")}`}
              </Paragraph>

              <Form.Item name="action" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="requestId" initialValue={showId} hidden>
                <Input />
              </Form.Item>

              <Form.Item
                label=""
                name="reason"
                required={requiredReason}
                rules={[
                  {
                    required: requiredReason,
                    message: t("approval.required"),
                  },
                  {
                    max: 500,
                    message: t("errors.ER014", { max: "500" }),
                  },
                ]}
              >
                <Input
                  disabled={saving}
                  placeholder={`${t("approval.resonPlaceholder")} (${t(
                    requiredReason
                      ? "approval.required1"
                      : "approval.noRequired"
                  )})`}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        visible={isCancel}
        title=""
        closable={!saving}
        okText={t("buttons.confirm")}
        cancelText={t("buttons.reject")}
        style={{ maxWidth: 500 }}
        cancelButtonProps={{
          disabled: saving,
        }}
        onCancel={() => {
          if (saving) return;
          showCancel(false);
        }}
        okButtonProps={{
          disabled: saving,
          loading: saving,
          onClick: () => {
            setSaving(true);
            mutate(
              {
                resource: resource,
                id: showId || "",
                values: {},
                metaData: {
                  type: "/cancel",
                },
              },
              {
                onError: (e) => {
                  setSaving(false);
                  showCancel(false);
                  showErrorToast(e);
                },
                onSuccess: () => {
                  showRetrieve(false);
                  showCancel(false);
                  notification.success({
                    message: t("approval.message.cancelSuccess"),
                  });
                },
              }
            );
          },
        }}
      >
        <Row>
          <Col flex="none">
            <Paragraph style={{ fontSize: 22, marginRight: 10 }} type="warning">
              <Icons.InfoCircleOutlined />
            </Paragraph>
          </Col>
          <Col flex="auto">
            <Paragraph
              style={{ fontWeight: 500, fontSize: 16, marginBottom: 12 }}
            >
              {t("approval.message.cancelConfirm")}
            </Paragraph>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={isRetrieve}
        title=""
        okText={t("buttons.confirm")}
        closable={!saving}
        cancelText={t("buttons.reject")}
        style={{ maxWidth: 500 }}
        cancelButtonProps={{
          disabled: saving,
        }}
        onCancel={() => {
          if (saving) return;
          showRetrieve(false);
        }}
        okButtonProps={{
          disabled: saving,
          loading: saving,
          onClick: () => {
            setSaving(true);
            mutate(
              {
                resource: resource,
                id: showId || "",
                values: {},
                metaData: {
                  type: "/retrieve",
                },
              },
              {
                onError: (e) => {
                  setSaving(false);
                  showRetrieve(false);
                  showErrorToast(e);
                },
                onSuccess: () => {
                  showRetrieve(false);
                  setSaving(false);
                  notification.success({
                    message: t("approval.message.retrieveSuccess"),
                  });
                },
              }
            );
          },
        }}
      >
        <Row style={{ flexFlow: "row" }}>
          <Col flex="none">
            <Paragraph style={{ fontSize: 22, marginRight: 10 }} type="warning">
              <Icons.InfoCircleOutlined />
            </Paragraph>
          </Col>
          <Col flex="auto">
            <Paragraph
              style={{ fontWeight: 500, fontSize: 16, marginBottom: 12 }}
            >
              {t("approval.message.retrieveConfirm")}
            </Paragraph>

            <Text>{t("approval.message.retrieveConfirmDescription")}</Text>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={isSubmit}
        title=""
        okText={t("buttons.confirm")}
        closable={!saving}
        cancelText={t("buttons.reject")}
        style={{ maxWidth: 500 }}
        cancelButtonProps={{
          disabled: saving,
        }}
        onCancel={() => {
          if (saving) return;
          showSubmit(false);
        }}
        okButtonProps={{
          disabled: saving,
          loading: saving,
          onClick: () => {
            setSaving(true);
            mutate(
              {
                resource: resource,
                id: showId || "",
                values: {},
                metaData: {
                  type: "/submit",
                },
              },
              {
                onError: (e) => {
                  setSaving(false);
                  showSubmit(false);
                  showErrorToast(e);
                },
                onSuccess: () => {
                  showSubmit(false);
                  setSaving(false);
                  notification.success({
                    message: t("approval.message.sentSuccess"),
                  });
                },
              }
            );
          },
        }}
      >
        <Row>
          <Col flex="none">
            <Paragraph style={{ fontSize: 22, marginRight: 10 }} type="warning">
              <Icons.InfoCircleOutlined />
            </Paragraph>
          </Col>
          <Col flex="auto">
            <Paragraph
              style={{ fontWeight: 500, fontSize: 16, marginBottom: 12 }}
            >
              {t("approval.message.sentConfirm")}
            </Paragraph>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
