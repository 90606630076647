import { Table, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { memo, useContext, useMemo } from "react";
import styled from "styled-components";
import type { ColumnsType } from "antd/es/table";
import { Currency } from "components/Currency";
import { SettlementShowContext } from "../../..";

const StyledWrapper = styled(Table)`
  margin-top: 24px;
`;

const { Text } = Typography;

type Data = {
  label: string;
  totalEstimated?: number;
  totalSettlement: number;
  totalTime: number;
};

const TotalCost = memo(() => {
  const {
    totalEstimatedByCash,
    totalEstimatedLaborCost,
    totalEstimatedManday,
    totalSettlementByCash,
    totalSettlementLaborCost,
    totalSettlementManday,
  } = useContext(SettlementShowContext);
  const translate = useTranslate();

  const dataTable = useMemo<Data[]>(
    () => [
      {
        label: translate("Dự toán"),
        totalEstimated: totalEstimatedByCash,
        totalSettlement: totalEstimatedLaborCost || 0,
        totalTime: totalEstimatedManday || 0,
      },
      {
        label: translate("Quyết toán"),
        totalEstimated: totalSettlementByCash,
        totalSettlement: totalSettlementLaborCost || 0,
        totalTime: totalSettlementManday || 0,
      },
    ],
    [
      totalEstimatedByCash,
      totalEstimatedLaborCost,
      totalEstimatedManday,
      totalSettlementByCash,
      totalSettlementLaborCost,
      totalSettlementManday,
      translate,
    ]
  );

  const columns = useMemo<ColumnsType<Data>>(() => {
    return [
      {
        width: 150,
        title: "",
        dataIndex: "label",
        align: "center",
        key: "label",
        render: (value) => <Text>{value}</Text>,
      },
      {
        title: translate("Tổng chi phí bằng tiền"),
        dataIndex: "totalEstimated",
        key: "totalEstimated",
        render: (value) => <Currency value={value} showCurrency={false} />,
      },
      {
        title: translate("Tổng chi phí nhân công"),
        dataIndex: "totalSettlement",
        key: "totalSettlement",
        render: (value) => <Currency value={value} showCurrency={false} />,
      },
      {
        title: translate("Tổng thời gian"),
        dataIndex: "totalTime",
        key: "totalTime",
        render: (value) => <Currency value={value} showCurrency={false} />,
      },
    ];
  }, [translate]);

  return (
    <StyledWrapper
      pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
      rowKey="id"
      columns={columns}
      dataSource={dataTable}
      scroll={{ x: 550 }}
    />
  );
});

export default TotalCost;
