import { SyncOutlined } from '@ant-design/icons';
import { Button, Typography, notification } from "@pankod/refine-antd";
import { CurrencyType } from "api/enums";
import { Currency, generateCurrency } from "components/Currency";
import { TPlanItem } from "interfaces/SettlementContract";
import { FC, memo, useMemo } from "react";
import { formatNumber } from "utils/commons";

interface TableShowProps {
    plans: TPlanItem[];
    showButtonGetSettlementNumber?: boolean;
}
const { Text } = Typography;

const DetailContractNumber: FC<TableShowProps> = memo(({plans, showButtonGetSettlementNumber}) => {
    const currency = useMemo(() => generateCurrency(CurrencyType.VND), []);

    const renderPlans = () => {
      return plans?.map((d, index) => {
        const { isStrong } = d;
        
        return (
          <div className={`row col-active`} key={d.code}>
            <div className={`col ${isStrong ? "font-bold" : ""}`}>{d.code}</div>
            <div className={`col ${isStrong ? "font-bold" : ""}`}>{d.name}</div>
            <div className={`col text-right`}>
              <Currency
                showCurrency={false}
                value={d.value}
                customValueShow={formatNumber}
                strong={isStrong}
              />
            </div>
            <div className="col text-right" style={{ whiteSpace: "nowrap" }}>
  
              <Currency
                showCurrency={false}
                value={d.contractEstimatedValue}
                customValueShow={formatNumber}
                strong={isStrong}
              />
  
              <div className="ant-form-item-explain-error">
                {/* {d.errorMessage || getEstimateValueError(d)} */}
              </div>
            </div>
            <div className={`col text-right ${isStrong ? "font-bold" : ""}`}>
              <Currency
                showCurrency={false}
                value={d.margin}
                customValueShow={formatNumber}
                strong={isStrong}
              />
            </div>
            <div className={`col text-right ${isStrong ? "font-bold" : ""}`}>
              <Currency
                showCurrency={false}
                value={d.businessPlanValue}
                customValueShow={formatNumber}
                strong={isStrong}
              />
            </div>
            <div
              className="col text-right"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              <Currency
                value={d?.percentage * 100}
                showCurrency={false}
                customValueShow={(value) => `${formatNumber(value || 0, 3)}%`}
                strong={isStrong}
                hidden={index === 0}
              />
            </div>
            <div
              className={`col ${isStrong ? "font-bold" : ""}`}
              dangerouslySetInnerHTML={{ __html: d?.note }}
            ></div>
          </div>
        );
      });
    };
  
    return (
      <>
        <div className="flex justify-end" hidden={showButtonGetSettlementNumber == false}>
          <Button type="primary" onClick={() => {
            // TODO: call api get settlement number
            notification.warning({
                message: "Tính năng đang phát triển ... Đừng bấm nữa",
            });
           }} loading={false} icon={<SyncOutlined />}>Get number settlement contracts</Button>
        </div>
        <div className="flex items-center justify-end">
          <Text>
            Đơn vị tính:{" "}
            <Text style={{ color: currency.unitColor, fontWeight: "bold" }}>
              {currency.unit}
            </Text>
          </Text>
        </div>
        <div style={{ overflow: "auto", position: "relative" }}>
          <section>
            <header>
              <div style={{ width: 50 }} className="col col-head">
                STT
              </div>
              <div className="col col-head" style={{ minWidth: 250 }}>
                Chỉ tiêu
              </div>
              <div className="col col-head text-right" style={{ minWidth: 220 }}>
                Số quyết toán
              </div>
              <div className="col col-head text-right" style={{ minWidth: 220 }}>
                Số Dự toán
              </div>
              <div className="col col-head text-right" style={{ minWidth: 220 }}>
                Chệnh lệch
              </div>
              <div className="col col-head text-right" style={{ minWidth: 220 }}>
                PAKD
              </div>
              <div className="col col-head text-right" style={{ minWidth: 100 }}>
                Tỷ lệ
              </div>
              <div className="col col-head" style={{ minWidth: 200 }}>
                Ghi chú
              </div>
            </header>
            {renderPlans()}
          </section>
        </div>
      </>
    );
});

export default DetailContractNumber;