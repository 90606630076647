import { Descriptions } from "@pankod/refine-antd";
import { DataText } from "components/DataText";
import { DATE_AND_TIME_FORMAT } from "configs/constants";
import { Typography } from "@pankod/refine-antd";
import { formatISODateTimeToView } from "utils/commons";
import { useTranslate } from "@pankod/refine-core";
import IServiceRequest from "interfaces/ServiceRequest";
import { FC } from "react";
import { ApprovalStatusLabel } from "components/ApprovalStatusLabel";

const { Text } = Typography;

interface Props {
  record?: IServiceRequest;
  requestName?: string;
}

export const SummaryApproval: FC<Props> = ({ record, requestName }) => {
  const t = useTranslate();

  const { approvalSubmitterDetail, approvalApproverDetail } =
    record?.approvalData || {};
  const processedEmployees = approvalSubmitterDetail?.processedEmployees || [];
  const waitingProcessEmployees =
    approvalSubmitterDetail?.waitingProcessEmployees || [];

  return (
    <Descriptions
      column={1}
      title={t("Thông tin chung")}
      layout="vertical"
      className="no-padding"
    >
      <Descriptions.Item label={t("Tên yêu cầu")}>
        <DataText value={requestName || approvalSubmitterDetail?.requestName} />
      </Descriptions.Item>
      <Descriptions.Item label={t("Mã yêu cầu")}>
        <DataText value={approvalSubmitterDetail?.requestCode} />
      </Descriptions.Item>
      <Descriptions.Item label={t("Người yêu cầu")}>
        <DataText
          value={
            approvalSubmitterDetail?.submitterName
              ? `${approvalSubmitterDetail?.submitterCode} - ${approvalSubmitterDetail?.submitterName}`
              : undefined
          }
        />
      </Descriptions.Item>
      <Descriptions.Item label={t("Phòng ban")}>
        <DataText value={approvalSubmitterDetail?.department?.name} />
      </Descriptions.Item>
      <Descriptions.Item label={t("Thời gian gửi")}>
        <DataText
          value={
            approvalSubmitterDetail?.submitRequestDate
              ? formatISODateTimeToView(
                  approvalSubmitterDetail?.submitRequestDate,
                  DATE_AND_TIME_FORMAT
                )
              : ""
          }
        />
      </Descriptions.Item>
      <Descriptions.Item label={t("Thời gian nhận")}>
        <DataText
          value={
            approvalApproverDetail?.receivedTime
              ? formatISODateTimeToView(
                  approvalApproverDetail?.receivedTime,
                  DATE_AND_TIME_FORMAT
                )
              : ""
          }
        />
      </Descriptions.Item>
      <Descriptions.Item label={t("Trạng thái xử lý")}>
        <ApprovalStatusLabel status={approvalSubmitterDetail?.status} />
      </Descriptions.Item>
      <Descriptions.Item label={t("Đang chờ xử lý")}>
        {waitingProcessEmployees?.length > 0 ? (
          <Text>
            {waitingProcessEmployees.map((el, index: number) => (
              <span key={index}>{`${el?.code} - ${el?.fullName}${
                index !== waitingProcessEmployees?.length - 1 ? ", " : ""
              }`}</span>
            ))}
          </Text>
        ) : (
          <Text type="secondary">{t("common.emptyText")}</Text>
        )}
      </Descriptions.Item>
      <Descriptions.Item label={t("Đối tượng đã phê duyệt")}>
        {processedEmployees?.length > 0 ? (
          <Text>
            {processedEmployees.map((el: any, index: number) => (
              <span key={index}>{`${el?.code} - ${el?.fullName}${
                index !== processedEmployees?.length - 1 ? ", " : ""
              }`}</span>
            ))}
          </Text>
        ) : (
          <Text type="secondary">{t("common.emptyText")}</Text>
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};
