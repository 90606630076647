import { Form, Table, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import {
  IForm,
  SettlementFormContext,
} from "pages/projectManagement/settlements/create";
import { memo, useContext, useMemo } from "react";
import styled from "styled-components";
import type { ColumnsType } from "antd/es/table";
import { Currency } from "components/Currency";
import useFormInstance from "antd/lib/form/hooks/useFormInstance";

const StyledWrapper = styled(Table)`
  margin-top: 24px;
`;

const { Text } = Typography;
const { useWatch } = Form;

type Data = {
  label: string;
  totalEstimated?: number;
  totalSettlement: number;
  totalTime: number;
};

const TotalCost = memo(() => {
  const {
    dataForCreate,
    totalSettlementValueManday,
    totalSettlementQuantityManday,

    calcTotal,
  } = useContext(SettlementFormContext);
  const translate = useTranslate();
  const form = useFormInstance<IForm>();
  const listCostByMoney = useWatch("listCostByMoney", form);

  const dataTable = useMemo<Data[]>(
    () => [
      {
        label: translate("Dự toán"),
        totalEstimated: dataForCreate?.totalEstimatedCostTable,
        totalSettlement: dataForCreate?.totalEstimatedValueMandayTable!,
        totalTime: dataForCreate?.totalEstimatedQuantityMandayTable!,
      },
      {
        label: translate("Quyết toán"),
        totalEstimated: calcTotal(
          Object.values(listCostByMoney || {}),
          "settlementValue"
        ),
        totalSettlement: totalSettlementValueManday,
        totalTime: totalSettlementQuantityManday,
      },
    ],
    [
      dataForCreate,
      listCostByMoney,
      totalSettlementValueManday,
      totalSettlementQuantityManday,
      calcTotal,
      translate,
    ]
  );

  const columns = useMemo<ColumnsType<Data>>(() => {
    return [
      {
        width: 150,
        title: "",
        dataIndex: "label",
        align: "center",
        key: "label",
        render: (value) => <Text>{value}</Text>,
      },
      {
        title: translate("Tổng chi phí bằng tiền"),
        dataIndex: "totalEstimated",
        key: "totalEstimated",
        render: (value) => <Currency value={value} showCurrency={false} />,
      },
      {
        title: translate("Tổng chi phí nhân công"),
        dataIndex: "totalSettlement",
        key: "totalSettlement",
        render: (value) => <Currency value={value} showCurrency={false} />,
      },
      {
        title: translate("Tổng thời gian"),
        dataIndex: "totalTime",
        key: "totalTime",
        render: (value) => <Currency value={value} showCurrency={false} />,
      },
    ];
  }, [translate]);

  return (
    <StyledWrapper
      pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
      rowKey="id"
      columns={columns}
      dataSource={dataTable}
      scroll={{ x: 550 }}
    />
  );
});

export default TotalCost;
