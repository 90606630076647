
import { Tooltip, TextField } from "@pankod/refine-antd";
import { BaseKey, useTranslate } from '@pankod/refine-core'
import { PATH } from "configs/path";

interface Props {
    value?: any
    defaultValue?: any
    resource?: string,
    showFullInfo?: boolean,
    showTooltip?: boolean
}

export const LinkText = ({ value, defaultValue,  resource, showFullInfo, showTooltip = true }: Props) => {
    const t = useTranslate();

    const handleClickCase = (e : any, recordId: BaseKey) =>{
        e.stopPropagation();
        if (recordId && resource) {
            window.open(`/${resource}/show/${recordId}`, '_blank')
        }
      }
    const displayText = (showFullInfo && value) ? `${value?.code} - ${value?.description}` : (value?.code || defaultValue || '-')
    const tooltip = showTooltip ? value?.description : ''

    return  <Tooltip placement="bottom" title={tooltip} arrowPointAtCenter>
        <TextField className='purple text-link' value={displayText} onClick={(e) => handleClickCase(e, value?.id)}/>
    </Tooltip>
}