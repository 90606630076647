import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Typography,
  notification,
} from "@pankod/refine-antd";
import { useOne, useTranslate } from "@pankod/refine-core";
import { FC, memo, useContext, useEffect } from "react";
import styled from "styled-components";
import { IForm, InternalEstimateFormContext } from "../../..";
import { API_PATH } from "configs/path";
import { SelectCustom } from "components";
import CCollapse, { ConsultInfoPanel } from "components/CCollapse";
import ModalConfirm from "components/ModalConfirm";
import { EstimateType } from "interfaces/InternalEstimate";
import { disabledDate, objectToQueryString } from "utils/commons";
import { DATE_FORMAT } from "configs/constants";
import dayjs from "dayjs";
import UploadMulti from "components/UploadMulti";
import { useFormRule } from "hooks/useFormRule";
import { ServiceRequestDetail } from "interfaces/ServiceRequest";

const CaseInfoStyle = styled.div`
  background-color: white;
  padding: 24px;
`;

const { Title } = Typography;
const { TextArea } = Input;
const { useWatch, useFormInstance } = Form;

const General: FC = memo(() => {
  const { canEditCaseId, initialValues, caseData, isEdit, onFetchPolicy } =
    useContext(InternalEstimateFormContext);

  const { required } = useFormRule();
  const translate = useTranslate();
  const form = useFormInstance<IForm>();

  const internalEstimateType = useWatch("internalEstimateType", form);
  const caseId = useWatch("caseId", form);
  const referenceRequestID = useWatch("referenceRequestID", form);
  const internalEstimateCode = useWatch("internalEstimateCode", form);
  const startDate = useWatch("startDate", form);
  const endDate = useWatch("endDate", form);
  const listInternalEstimateProduct = useWatch(
    "listInternalEstimateProduct",
    form
  );
  const isAllProducts = useWatch("isAllProducts", form);
  const referenceRequestCode = useWatch("referenceRequestCode", form);

  const isLoadingCase = caseData?.isFetching;

  const onChangeConsultation = (value: any, __: any, option: any) => {
    if (value) form.setFieldsValue({ caseId: option?.case?.id });
  };

  useEffect(() => {
    if (!!startDate && !!endDate) {
      form.setFields([
        {
          name: "endDate",
          errors:
            dayjs(startDate).valueOf() <= dayjs(endDate).valueOf()
              ? undefined
              : [
                  translate(
                    "businessOpportunities.theClosingDateMustBeFfterTheOpeningDate"
                  ),
                ],
        },
      ]);
    }
  }, [startDate, endDate]);

  const onChangeRequestType =
    (isDone?: boolean) => (value: string, __: any, selectedItem: any) => {
      if (internalEstimateType && !isDone) return;
      form.setFieldsValue({
        internalEstimateType: value,
        internalEstimateCode: selectedItem?.code,
        referenceRequestID: null,
        referenceRequestCode: null,
      });
      const products = Object.values(listInternalEstimateProduct || {});
      onFetchPolicy(products, internalEstimateType, isAllProducts);
    };

  const onCancelChangeEstimateType = () =>
    form.setFieldsValue({
      internalEstimateType,
      internalEstimateCode,
      referenceRequestID,
      referenceRequestCode,
    });

  const onChangeReferenceRequest =
    (isDone?: boolean) => (value: string, __: any, selectedItem: any) => {
      if (referenceRequestID && !isDone) return;

      form.setFieldsValue({
        referenceRequestID: value,
        caseId: selectedItem?.case?.id || caseId,
        referenceRequestCode: selectedItem?.code,
      });
    };

  const onCancelChangeReferenceRequest = () =>
    form.setFieldsValue({
      referenceRequestID,
      caseId,
      referenceRequestCode,
    });

  const serviceRequestData = useOne<ServiceRequestDetail>({
    id: referenceRequestCode!,
    resource: API_PATH.serviceRequestDetail,
    queryOptions: {
      enabled: !!referenceRequestCode,
    },
  });

  const request = serviceRequestData?.data?.data;
  const isLoadingRequest = serviceRequestData?.isFetching;

  return (
    <CaseInfoStyle>
      <Title level={5}>{translate("Thông tin tổng quan")}</Title>
      <div style={{ paddingLeft: 16 }}>
        <Row gutter={16}>
          <Col xs={24} lg={8}>
            <Form.Item hidden name="internalEstimateCode" />
            <Form.Item
              name="internalEstimateType"
              label="Loại dự toán"
              rules={required}
            >
              <ModalConfirm
                title={translate("Bạn muốn thay đổi loại dự toán?")}
                enable={!!internalEstimateType}
                desc={
                  "Lưu ý: Sau khi thay đổi loại dự toán, tỷ lệ dự toán của các sản phẩm ở tab Chi tiết dự toán có thể bị thay đổi."
                }
                onCancel={onCancelChangeEstimateType}
                onChange={onChangeRequestType()}
                onDone={onChangeRequestType(true)}
              >
                <SelectCustom
                  optionValue="id"
                  optionLabel="name"
                  resource={API_PATH.internalEstimateTypeApi}
                  placeholder="Chọn loại dự toán"
                  allowClear
                  disabled={!canEditCaseId}
                />
              </ModalConfirm>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item hidden name="referenceRequestCode" />
            <Form.Item
              name="referenceRequestID"
              label="Mã yêu cầu"
              rules={[
                {
                  required:
                    internalEstimateCode !==
                    EstimateType.Estimated_Implementation,
                  message: translate("errors.ER005"),
                },
              ]}
            >
              <ModalConfirm
                title={translate("Bạn muốn thay đổi mã yêu cầu?")}
                enable={!!referenceRequestID}
                desc={
                  "Lưu ý: Khi thay đổi, các thông tin chi phí phân công sẽ bị xóa"
                }
                onCancel={onCancelChangeReferenceRequest}
                onChange={onChangeReferenceRequest()}
                onDone={onChangeReferenceRequest(true)}
              >
                <SelectCustom
                  resource={API_PATH.internalEstimatesRequestDropdownlist}
                  onChangeOption={onChangeConsultation}
                  optionLabel="label"
                  optionValue="id"
                  placeholder={translate("Chọn mã yêu cầu")}
                  style={{ width: "100%", color: "#000" }}
                  subFilter={objectToQueryString({
                    caseId,
                    estimateTypeId: internalEstimateType,
                  })}
                  enabledFetching={!!internalEstimateType}
                  allowClear
                  formatData={(r: any) => ({
                    ...r,
                    label: `${r?.code} - ${r?.name}`,
                  })}
                />
              </ModalConfirm>
            </Form.Item>
          </Col>
          <Col xs={0} lg={8}></Col>
          <Col span={24}>
            <Form.Item hidden={!referenceRequestID}>
              <CCollapse
                title={translate("Chi tiết yêu cầu dịch vụ")}
                loading={isLoadingCase || isLoadingRequest}
                hidden={!referenceRequestID}
              >
                <ConsultInfoPanel requestDetail={request} />
              </CCollapse>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="startDate"
              label={translate("Ngày bắt đầu")}
              rules={[{ required: true, message: translate("errors.ER005") }]}
              getValueProps={(value) => ({
                value: value ? dayjs(value) : dayjs().startOf("date"),
              })}
            >
              <DatePicker
                placeholder={DATE_FORMAT}
                format={DATE_FORMAT}
                style={{ width: "100%" }}
                disabled={isEdit}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="endDate"
              label={translate("Ngày kết thúc")}
              rules={[
                {
                  required: true,
                  message: translate("errors.ER005"),
                },
                {
                  validator: (_, value) => {
                    if (
                      !!value &&
                      dayjs(value).valueOf() < dayjs(startDate).valueOf()
                    ) {
                      return Promise.reject(
                        new Error(
                          translate(
                            "businessOpportunities.theClosingDateMustBeFfterTheOpeningDate"
                          )
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              getValueProps={(value) => ({
                value: value ? dayjs(value).endOf("date") : "",
              })}
            >
              <DatePicker
                placeholder={DATE_FORMAT}
                format={DATE_FORMAT}
                style={{ width: "100%" }}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col xs={0} lg={8}></Col>
          <Col span={24}>
            <Form.Item
              label={translate("Nội dung")}
              name="description"
              rules={[
                { required: true, message: translate("errors.ER005") },
                {
                  max: 3000,
                  message: translate("errors.ER014", { max: 3000 }),
                },
              ]}
            >
              <TextArea rows={4} placeholder={translate("Nhập nội dung")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="listInternalEstimateDocument"
              validateStatus="success"
              label=""
              className="m-0"
            >
              <UploadMulti
                title={translate("Tài liệu đính kèm")}
                url={`${process.env.REACT_APP_API_URL}${API_PATH.interEstimateUploadFile}?caseId=${caseId}`}
                idKey="id"
                onClickButtonUpload={(action) => {
                  if (!caseId) {
                    notification.error({
                      message: translate(
                        "Vui lòng chọn mã vụ việc trước khi tải file."
                      ),
                    });
                  } else {
                    action();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </CaseInfoStyle>
  );
});

export default General;
