import { FC } from "react";
import { useController } from "./controller";
import {
  Button,
  Col,
  Form,
  Icons,
  List,
  Radio,
  Row,
  Select,
  Table,
  TextField,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { useNavigation, useTranslate } from "@pankod/refine-core";
import { ButtonTooltip, EmptyData, SearchBox } from "components";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { formatISODateTimeToView, searchSelect } from "utils/commons";
import CTableColumn from "components/CTableColumn";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "configs/constants";
import {
  OptionViewAproval,
  ServiceRequestData,
} from "interfaces/ServiceRequest";
import { ApprovalStatusLabel } from "components/ApprovalStatusLabel";
import { ApprovalStatus } from "api/enums";
import styled from "styled-components";
import { SubmitEmployee, WaitingProcessEmployees } from "interfaces";

interface RowData extends ServiceRequestData {
  submitEmployee: SubmitEmployee;
  waitingProcessEmployees: WaitingProcessEmployees[];
}

const Column = CTableColumn<RowData>();

const { useWatch } = Form;

const RowStyle = styled(Row)`
  justify-content: flex-start;

  @media only screen and (min-width: 1200px) {
    justify-content: flex-end;
  }
`;

const SearchBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8;
`;

const ContractList: FC = () => {
  const {
    formProps,
    form,
    table: {
      tableQueryResult: { data, isLoading },
      sorter,
      pageSize,
      current,
      filters,
      setSorter,
      setPageSize,
      setCurrent,
      setFilters,
    },
    serviceRequstTypeSelectProps,
    statusOptions,
    dataTable,
    onChangeOptionView,
  } = useController();
  const { edit, show, create } = useNavigation();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const translate = useTranslate();
  const optionViewAproval = useWatch("optionViewAproval", form);

  return (
    <>
      <List
        title={translate("Danh sách yêu cầu dịch vụ")}
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => create(PATH.serviceRequests)}
            {...checkCreateHaveHelperText(PATH.serviceRequests)}
          >
            {translate("actions.create")}
          </ButtonTooltip>
        }
        breadcrumb={null}
      >
        <div className="list-content">
          <Form {...formProps} style={{ marginBottom: 16 }}>
            <Row gutter={[16, 16]}>
              <Col lg={24} xl={18}>
                <SearchBoxWrapper>
                  <SearchBox
                    placeholder={translate(
                      "Tìm kiếm mã yêu cầu, tên yêu cầu, MVV, tên MVV"
                    )}
                    style={{ width: 400, marginBottom: 0 }}
                    onSearch={(e) => {
                      setFilters([
                        {
                          field: "q",
                          operator: "eq",
                          value: e,
                        },
                      ]);
                    }}
                  />
                  <Form.Item name={["serviceRequestTypeId"]} className="m-0">
                    <Select
                      {...serviceRequstTypeSelectProps}
                      allowClear
                      onChange={(e) => {
                        setFilters([
                          {
                            field: "serviceRequestTypeId",
                            operator: "eq",
                            value: e,
                          },
                        ]);
                      }}
                      className={"placeholderBlack"}
                      dropdownStyle={{ color: "black" }}
                      dropdownMatchSelectWidth={false}
                      bordered={false}
                      placeholder={translate("Loại yêu cầu")}
                      filterOption={(inputValue: string, option: any) =>
                        option && searchSelect(inputValue, option?.label)
                      }
                    />
                  </Form.Item>

                  {filters &&
                    filters.length > 0 &&
                    !(
                      filters.length === 1 &&
                      filters[0]?.operator === "containss"
                    ) && (
                      <Button
                        type="text"
                        onClick={(e) => {
                          form.resetFields();
                          setFilters([], "replace");
                        }}
                        style={{ color: "#8C8C8C" }}
                      >
                        {translate("common.cleanFilter")}
                        <Icons.CloseOutlined />
                      </Button>
                    )}
                </SearchBoxWrapper>
              </Col>
              <Form.Item name={["optionViewAproval"]} hidden />
              <Form.Item name={["oldStatus"]} hidden />

              <Col lg={24} xl={6}>
                <RowStyle gutter={8}>
                  <Col>
                    <Button
                      onClick={onChangeOptionView(OptionViewAproval.MINE)}
                      shape="round"
                      type={
                        optionViewAproval === OptionViewAproval.MINE
                          ? "primary"
                          : "ghost"
                      }
                    >
                      Của tôi
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={onChangeOptionView(OptionViewAproval.APPROVING)}
                      shape="round"
                      type={
                        optionViewAproval === OptionViewAproval.APPROVING
                          ? "primary"
                          : "ghost"
                      }
                    >
                      Cần xử lý
                    </Button>
                  </Col>
                </RowStyle>
              </Col>
            </Row>
            <Form.Item
              name={["status"]}
              style={{ marginTop: 16 }}
              className="m-0"
            >
              <Radio.Group
                defaultValue={-1}
                buttonStyle="solid"
                onChange={(e) => {
                  setCurrent(1);
                  form.setFieldsValue({ oldStatus: e.target.value });
                  setFilters([
                    {
                      field: "status",
                      operator: "eq",
                      value:
                        e?.target?.value === -1 ? undefined : e?.target?.value,
                    },
                  ]);
                }}
              >
                <Radio.Button disabled={optionViewAproval === 1} value={-1}>
                  {translate("common.all")}
                </Radio.Button>
                {statusOptions?.map(({ label, value }) => {
                  return (
                    <Radio.Button
                      disabled={
                        optionViewAproval === 1 &&
                        value !== ApprovalStatus.Approving
                      }
                      value={value}
                    >
                      {translate(label as string)}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Form>
          <Table
            sticky
            loading={isLoading}
            dataSource={dataTable}
            rowKey="id"
            locale={{
              emptyText: <EmptyData text={translate("common.noDataSearch")} />,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  show(PATH.serviceRequests, record.id);
                },
              };
            }}
            pagination={{
              pageSize: pageSize,
              current: current,
              total: data?.total,
              showSizeChanger: true,
              onChange: (cur, size) => {
                setCurrent(cur);
                setPageSize(size);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
              },
              locale: {
                items_per_page: translate("common.pageNumber"),
              },
              showTotal: (total) => (
                <p>{translate("common.totalPage", { total: total })}</p>
              ),
            }}
            onChange={(_, __, s) => {
              const newSort = s as any;
              setSorter([
                {
                  field: newSort?.field,
                  order: newSort?.order,
                },
              ]);
            }}
            scroll={{ x: 2200 }}
            showSorterTooltip={false}
          >
            <Column
              key="code"
              dataIndex="code"
              width={200}
              title={translate("Mã yêu cầu dịch vụ")}
              render={(value, record) => (
                <TextField
                  value={value || "-"}
                  className={value ? "primary" : ""}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("code", sorter)}
              sorter
            />
            <Column
              key="case"
              dataIndex="case"
              width={150}
              title={translate("Vụ việc")}
              render={(value, record) => (
                <TextField value={record?.case?.code} />
              )}
              defaultSortOrder={getDefaultSortOrder("case", sorter)}
              sorter
            />

            <Column
              key="case"
              dataIndex="case"
              width={200}
              title={translate("Tên MVV")}
              render={(value, record) => (
                <TextField value={record?.case?.name} />
              )}
              defaultSortOrder={getDefaultSortOrder("case", sorter)}
              sorter
            />

            <Column
              key="name"
              dataIndex="name"
              width={200}
              title={translate("Tên yêu cầu")}
              render={(value, record) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("name", sorter)}
              sorter
            />

            <Column
              key="serviceRequestType"
              dataIndex="serviceRequestType"
              width={200}
              title={translate("Loại yêu cầu")}
              render={(value, record) => (
                <TextField value={record?.serviceRequestType?.name} />
              )}
              defaultSortOrder={getDefaultSortOrder(
                "serviceRequestType",
                sorter
              )}
              sorter
            />

            <Column
              key="status"
              dataIndex="status"
              width={200}
              title={translate("Trạng thái xử lý")}
              render={(value, record) => <ApprovalStatusLabel status={value} />}
              defaultSortOrder={getDefaultSortOrder("status", sorter)}
              sorter
            />

            <Column
              width={200}
              key="waitingProcessEmployees"
              dataIndex="waitingProcessEmployees"
              title={translate("Người xử lý")}
              render={(value, record) => {
                const content = record?.waitingProcessEmployees
                  ?.map((emp) => `${emp.code} - ${emp.fullName}`)
                  ?.join(",");
                return <TextField value={content || "-"} />;
              }}
              defaultSortOrder={getDefaultSortOrder(
                "waitingProcessEmployees",
                sorter
              )}
              sorter
            />

            <Column
              key="submitEmployee"
              dataIndex="submitEmployee"
              width={200}
              title={translate("Người yêu cầu")}
              render={(value, record) => (
                <TextField
                  value={
                    record?.submitEmployee
                      ? `${record?.submitEmployee?.code} - ${record?.submitEmployee?.fullName}`
                      : "-"
                  }
                />
              )}
              defaultSortOrder={getDefaultSortOrder("submitEmployee", sorter)}
              sorter
            />

            <Column
              key="requestDate"
              dataIndex="requestDate"
              width={200}
              title={translate("Ngày yêu cầu")}
              render={(value, record) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("requestDate", sorter)}
              sorter
            />

            <Column
              key="requestCompletionDate"
              dataIndex="requestCompletionDate"
              width={200}
              title={translate("Ngày yêu cầu hoàn thành")}
              render={(value, record) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder(
                "requestCompletionDate",
                sorter
              )}
              sorter
            />

            <Column
              key="updatedName"
              dataIndex="updatedName"
              width={200}
              title={translate("common.updatedName")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("updatedName", sorter)}
              sorter
            />

            <Column
              key="updatedDate"
              dataIndex="updatedDate"
              width={200}
              title={translate("common.updatedDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
              sorter
            />

            <Column
              title={translate("table.actions")}
              dataIndex="actions"
              align="center"
              fixed="right"
              width={120}
              render={(_, record) => {
                if (
                  [ApprovalStatus.Draft, ApprovalStatus.Returned].includes(
                    record?.status
                  )
                )
                  return (
                    <ButtonTooltip
                      type="text"
                      icon={<Icons.EditOutlined />}
                      className="gray"
                      onClick={(e) => {
                        e.stopPropagation();
                        edit(PATH.serviceRequests, record.id!);
                      }}
                      {...checkEditHaveHelperText(PATH.serviceRequests)}
                    />
                  );
              }}
            />
          </Table>
        </div>
      </List>
    </>
  );
};

export default ContractList;
