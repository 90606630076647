import {
  Button,
  Card,
  Col,
  Form,
  Icons,
  Input,
  Modal,
  notification,
  Row,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useCreate,
  useNavigation,
  useShow,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { showErrorToast } from "api/common";
import { ApprovalActionStatus, ApprovalStatus } from "api/enums";
import { ButtonTooltip } from "components";
import { ShowCustom } from "components/layout";
import usePermissions from "hooks/permission";
import { IHeaderTab } from "interfaces";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import IServiceRequest from "interfaces/ServiceRequest";
import { ReactNode } from "react-markdown";
import { Summary } from "./Summary";
import { SummaryApproval } from "./SumaryApproval";
import { Histories } from "components/Approval/Histories";

const { Text, Paragraph } = Typography;

export enum TabKey {
  INFO_TAB = "info",
  HISTORY_TAB = "history",
}

interface Props extends IResourceComponentsProps {
  renderInformation: (record?: any, tab?: any) => ReactNode;
  resource: string;
  title?: (record?: any) => ReactNode;
  onApprovalSuccess: () => void;
  headerTabs?: IHeaderTab[];
  defaultTab?: any;
  requestName?: string;
  headerButton?: (record?: any) => ReactNode;
  elements?: (record?: any) => ReactNode;
}

export const ApprovalServiceRequest: React.FC<Props> = (props) => {
  const {
    renderInformation,
    resource,
    title,
    onApprovalSuccess,
    headerTabs: headerTabsProp,
    defaultTab = TabKey.INFO_TAB,
    requestName = "",
    headerButton,
    elements,
  } = props;
  const t = useTranslate();
  const { edit } = useNavigation();
  const { id } = useParams();
  const { checkEditHaveHelperText } = usePermissions();

  const { queryResult } = useShow<IServiceRequest>({
    resource: resource,
    id,
    metaData: { isConverting: false },
  });
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;

  const statusCode = Number(record?.objectData?.status);

  const { approvalApproverDetail, approvalSubmitterDetail } =
    record?.approvalData || {};

  const dataRecord = record?.objectData;

  const [activeTab, setActiveTab] = React.useState<any>(defaultTab);
  const [isRetrieve, showRetrieve] = useState(false);
  const [isCancel, showCancel] = useState(false);
  const [isApprove, showApprove] = useState(false);
  const [isSubmit, showSubmit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [mode, setMode] = useState(ApprovalActionStatus.Approved);

  const { mutate: mutatePut } = useUpdate();
  const { mutate: mutatePost } = useCreate();

  const headerTabs: IHeaderTab[] = headerTabsProp || [
    {
      name: t("approval.info"),
      key: TabKey.INFO_TAB,
      isActive: true,
      hidden: false,
    },
    {
      name: t("approval.history"),
      key: TabKey.HISTORY_TAB,
      isActive: false,
      hidden: false,
    },
  ];

  const onChangeTab = (activeKey: any) => {
    setActiveTab(activeKey);
    window.scrollTo(0, 0);
  };

  const requiredReason =
    mode === ApprovalActionStatus.Rejected ||
    mode === ApprovalActionStatus.Returned;
  const { formProps, form, saveButtonProps } = useForm();

  const onApprove = (action: ApprovalActionStatus) => {
    showApprove(true);
    setMode(action);
    form.resetFields();

    setTimeout(() => {
      form?.setFieldsValue({
        action: action,
        reason: "",
      });
    }, 300);
  };
  const onCancel = () => {
    showCancel(true);
  };
  const onRetrieve = () => {
    showRetrieve(true);
  };

  return (
    <>
      <ShowCustom
        {...props}
        isLoading={isLoading}
        title={
          !!title && <Text style={{ marginBottom: 0 }}>{title(record)}</Text>
        }
        tabs={headerTabs}
        onChangeTab={onChangeTab}
        contentStyles={{ backgroundColor: "transparent", padding: 0 }}
        breadcrumbText={dataRecord?.name}
        bodyStyle={{ padding: 0 }}
        headerButtons={
          <>
            {statusCode !== ApprovalStatus.Draft && (
              <>
                <Button
                  onClick={() => onApprove(ApprovalActionStatus.Returned)}
                  hidden={!approvalApproverDetail?.allowReturn}
                >
                  {t("histories.returned")}
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() => onApprove(ApprovalActionStatus.Rejected)}
                  hidden={!approvalApproverDetail?.allowReject}
                >
                  {t("histories.rejected")}
                </Button>
                <Button
                  type="primary"
                  onClick={() => onApprove(ApprovalActionStatus.Approved)}
                  hidden={!approvalApproverDetail?.allowAccept}
                >
                  {t("histories.approved")}
                </Button>
              </>
            )}
            {statusCode !== ApprovalStatus.Draft && (
              <>
                <Button
                  icon={<Icons.UndoOutlined />}
                  type="primary"
                  onClick={() => onRetrieve()}
                  hidden={!approvalSubmitterDetail?.allowRetrieve}
                >
                  {t("histories.retrieve")}
                </Button>

                <Button
                  hidden={!approvalSubmitterDetail?.allowCancel}
                  onClick={() => onCancel()}
                >
                  {t("approval.cancel")}
                </Button>
              </>
            )}
            {[ApprovalStatus.Draft, ApprovalStatus.Returned].includes(
              statusCode!
            ) && (
              <ButtonTooltip
                type="primary"
                icon={<Icons.EditOutlined />}
                onClick={() => edit(resource, dataRecord?.id!)}
                hidden={isLoading}
                {...checkEditHaveHelperText(resource)}
              >
                {t("actions.edit")}
              </ButtonTooltip>
            )}

            {statusCode === ApprovalStatus.Returned && (
              <Button
                icon={<Icons.SendOutlined />}
                type="primary"
                onClick={() => showSubmit(true)}
                hidden={!approvalSubmitterDetail?.allowSubmit}
              >
                {t("approval.send")}
              </Button>
            )}
            {headerButton && headerButton(record)}
          </>
        }
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} lg={18}>
            {renderInformation(record, activeTab)}
            {activeTab === TabKey.HISTORY_TAB && dataRecord?.id && (
              <Card>
                <Histories
                  recordId={dataRecord?.id}
                  resource={resource}
                  recordStatus={statusCode?.toString()}
                />
              </Card>
            )}
          </Col>
          <Col xs={24} lg={6}>
            <Card style={{ minHeight: 400 }}>
              {statusCode === ApprovalStatus.Approving ? (
                <SummaryApproval record={record} requestName={requestName} />
              ) : (
                <Summary record={record} requestName={requestName} />
              )}
            </Card>
          </Col>
        </Row>
      </ShowCustom>

      <Modal
        visible={isApprove}
        title=""
        closable={!saving}
        okText={t("buttons.confirm")}
        cancelText={t("buttons.reject")}
        style={{ maxWidth: 500 }}
        onCancel={() => {
          if (saving) return;
          showApprove(false);
        }}
        okButtonProps={{
          ...saveButtonProps,
          disabled: saving,
          loading: saving,
          danger: mode === ApprovalActionStatus.Rejected,
        }}
        cancelButtonProps={{
          disabled: saving,
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          onFinish={() => {
            setSaving(true);
            mutatePost(
              {
                resource: resource,
                metaData: {
                  type: "/approval",
                  method: "POST",
                },
                values: form.getFieldsValue(),
              },
              {
                onError: (e) => {
                  showErrorToast(e);
                  setSaving(false);
                  showApprove(false);
                },
                onSuccess: () => {
                  onApprovalSuccess && onApprovalSuccess();
                  refetch();
                  showApprove(false);
                  setSaving(false);
                  if (mode === ApprovalActionStatus.Approved) {
                    notification.success({
                      message: t("approval.message.approvedSuccess"),
                    });
                  }
                  if (mode === ApprovalActionStatus.Rejected) {
                    notification.success({
                      message: t("approval.message.rejectedSuccess"),
                    });
                  }
                  if (mode === ApprovalActionStatus.Returned) {
                    notification.success({
                      message: t("approval.message.returnedSuccess"),
                    });
                  }
                },
              }
            );
          }}
        >
          <Row>
            <Col flex="none">
              <Paragraph
                style={{ fontSize: 22, marginRight: 10 }}
                type="warning"
              >
                <Icons.InfoCircleOutlined />
              </Paragraph>
            </Col>
            <Col flex="auto">
              <Paragraph
                style={{ fontWeight: 500, fontSize: 16, marginBottom: 12 }}
              >
                {mode === ApprovalActionStatus.Returned &&
                  ` ${t("approval.message.returnConfirm")}`}
                {mode === ApprovalActionStatus.Rejected &&
                  ` ${t("approval.message.rejectConfirm")}`}
                {mode === ApprovalActionStatus.Approved &&
                  ` ${t("approval.message.approveConfirm")}`}
              </Paragraph>

              <Form.Item name="action" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="requestId" initialValue={id} hidden>
                <Input />
              </Form.Item>

              <Form.Item
                label=""
                name="reason"
                className="m-0"
                required={requiredReason}
                rules={[
                  {
                    required: requiredReason,
                    message: t("approval.required"),
                  },
                  {
                    max: 500,
                    message: t("errors.ER014", { max: "500" }),
                  },
                ]}
              >
                <Input
                  disabled={saving}
                  placeholder={`${t("approval.resonPlaceholder")} (${t(
                    requiredReason
                      ? "approval.required1"
                      : "approval.noRequired"
                  )})`}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        visible={isCancel}
        title=""
        closable={!saving}
        okText={t("buttons.confirm")}
        cancelText={t("buttons.reject")}
        style={{ maxWidth: 500 }}
        cancelButtonProps={{
          disabled: saving,
        }}
        onCancel={() => {
          if (saving) return;
          showCancel(false);
        }}
        okButtonProps={{
          disabled: saving,
          loading: saving,
          onClick: () => {
            setSaving(true);
            mutatePut(
              {
                resource: resource,
                id: id || "",
                values: {},
                metaData: {
                  type: "/cancel",
                },
              },
              {
                onError: (e) => {
                  setSaving(false);
                  showCancel(false);
                  showErrorToast(e);
                },
                onSuccess: () => {
                  onApprovalSuccess && onApprovalSuccess();
                  showRetrieve(false);
                  showCancel(false);
                  notification.success({
                    message: t("approval.message.cancelSuccess"),
                  });
                },
              }
            );
          },
        }}
      >
        <Row>
          <Col flex="none">
            <Paragraph style={{ fontSize: 22, marginRight: 10 }} type="warning">
              <Icons.InfoCircleOutlined />
            </Paragraph>
          </Col>
          <Col flex="auto">
            <Paragraph
              style={{ fontWeight: 500, fontSize: 16, marginBottom: 12 }}
            >
              {t("approval.message.cancelConfirm")}
            </Paragraph>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={isRetrieve}
        title=""
        okText={t("buttons.confirm")}
        closable={!saving}
        cancelText={t("buttons.reject")}
        style={{ maxWidth: 500 }}
        cancelButtonProps={{
          disabled: saving,
        }}
        onCancel={() => {
          if (saving) return;
          showRetrieve(false);
        }}
        okButtonProps={{
          disabled: saving,
          loading: saving,
          onClick: () => {
            setSaving(true);
            mutatePut(
              {
                resource: resource,
                id: id || "",
                values: {},
                metaData: {
                  type: "/retrieve",
                },
              },
              {
                onError: (e) => {
                  setSaving(false);
                  showRetrieve(false);
                  showErrorToast(e);
                },
                onSuccess: () => {
                  onApprovalSuccess && onApprovalSuccess();
                  showRetrieve(false);
                  setSaving(false);
                  notification.success({
                    message: t("approval.message.retrieveSuccess"),
                  });
                },
              }
            );
          },
        }}
      >
        <Row style={{ flexFlow: "row" }}>
          <Col flex="none">
            <Paragraph style={{ fontSize: 22, marginRight: 10 }} type="warning">
              <Icons.InfoCircleOutlined />
            </Paragraph>
          </Col>
          <Col flex="auto">
            <Paragraph
              style={{ fontWeight: 500, fontSize: 16, marginBottom: 12 }}
            >
              {t("approval.message.retrieveConfirm")}
            </Paragraph>

            <Text>{t("approval.message.retrieveConfirmDescription")}</Text>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={isSubmit}
        title=""
        okText={t("buttons.confirm")}
        closable={!saving}
        cancelText={t("buttons.reject")}
        style={{ maxWidth: 500 }}
        cancelButtonProps={{
          disabled: saving,
        }}
        onCancel={() => {
          if (saving) return;
          showSubmit(false);
        }}
        okButtonProps={{
          disabled: saving,
          loading: saving,
          onClick: () => {
            setSaving(true);
            mutatePut(
              {
                resource: resource,
                id: id || "",
                values: {},
                metaData: {
                  type: "/submit",
                },
              },
              {
                onError: (e) => {
                  setSaving(false);
                  showSubmit(false);
                  showErrorToast(e);
                },
                onSuccess: () => {
                  onApprovalSuccess && onApprovalSuccess();
                  showSubmit(false);
                  setSaving(false);
                  notification.success({
                    message: t("approval.message.sentSuccess"),
                  });
                },
              }
            );
          },
        }}
      >
        <Row>
          <Col flex="none">
            <Paragraph style={{ fontSize: 22, marginRight: 10 }} type="warning">
              <Icons.InfoCircleOutlined />
            </Paragraph>
          </Col>
          <Col flex="auto">
            <Paragraph
              style={{ fontWeight: 500, fontSize: 16, marginBottom: 12 }}
            >
              {t("approval.message.sentConfirm")}
            </Paragraph>
          </Col>
        </Row>
      </Modal>
      {elements && elements(record)}
    </>
  );
};
