import {
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { useParams } from "@pankod/refine-react-router-v6";
import type { ColumnsType } from "antd/es/table";
import { SubmitConditionInfoType } from "api/enums";
import { ObjectSubmitCondition } from "interfaces";
import { CheckBoxError } from "pages/sale/contractEstimation/show/components/SubmitionInformation";
import { FC, memo, useContext, useEffect, useMemo } from "react";
import {
  CreateSettlementsContractContext,
  IFormCreateSettlementsContract,
} from "..";
import { CheckBoxCondition } from "./checkBox";
import { v4 as uuid } from "uuid";

const { Title, Text } = Typography;
const { useFormInstance, useWatch } = Form;

const SubmitConditionsInfo: FC = memo(() => {
  const { isEdit, submitConditionFetch } = useContext(
    CreateSettlementsContractContext
  );
  const { refetch } = submitConditionFetch!;
  const translate = useTranslate();
  const { id } = useParams();
  const form = useFormInstance<IFormCreateSettlementsContract>();
  const columns = useMemo<ColumnsType<ObjectSubmitCondition>>(
    () => [
      {
        title: translate("STT"),
        width: 50,
        dataIndex: "index",
        key: "index",
        render: (_, __, index) => index + 1,
      },
      {
        title: translate("Điều kiện"),
        width: 200,
        dataIndex: "description",
        key: "description",
        render: (_, record, __) => (
          <Text>{record?.submitConditionInfo?.description}</Text>
        ),
      },
      {
        title: translate("Thông tin"),
        width: 200,
        dataIndex: "url",
        key: "url",
        render: (_, record, __) => (
          <div
            dangerouslySetInnerHTML={{
              __html: record?.submitConditionInfo?.url,
            }}
          />
        ),
      },
      {
        title: translate("Thỏa điều kiện"),
        width: 50,
        dataIndex: "action",
        key: "action",
        align: "center",
        render: (_, record, __) => {
          const { submitConditionInfo, isOk } = record;
          return submitConditionInfo?.type ===
            SubmitConditionInfoType.Manual ? (
            <CheckBoxCondition
              id={record.featureSubmitConditionId}
              value={isOk}
            />
          ) : isOk ? (
            <Checkbox checked />
          ) : (
            <CheckBoxError checked />
          );
        },
      },
    ],
    []
  );
  useEffect(() => {
    if (submitConditionFetch?.data?.data) {
      form.setFieldsValue({
        submitConditions: submitConditionFetch.data.data.map((item) => ({
          id: !!id ? item.id : null,
          isOk: item.isOk,
          featureSubmitConditionId: item.featureSubmitConditionId,
        })),
      });
    }
  }, [submitConditionFetch?.data?.data, id, form]);

  return (
    <Form.Item
      name="submitConditions"
      style={{ padding: 24, backgroundColor: "white" }}
    >
      <Title level={5}>{translate("Thông tin cần để chuyển DTHĐ")}</Title>
      <Row style={{ marginBottom: 16 }} align="middle">
        <Col flex={1} />

        <Button
          type="primary"
          onClick={() => refetch()}
          loading={isEdit ? false : submitConditionFetch?.isFetching}
        >
          {translate("Cập nhật")}
        </Button>
      </Row>
      <Table
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="featureSubmitConditionId"
        columns={columns}
        dataSource={
          submitConditionFetch?.data?.data
        }
        scroll={{ x: 500 }}
        size="small"
      />
    </Form.Item>
  );
});

export default SubmitConditionsInfo;
