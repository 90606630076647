import { useTranslate } from "@pankod/refine-core";
import { API_PATH } from "configs/path";
import { FC, memo, useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { IForm, InternalEstimateFormContext } from "../../..";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import { Currency } from "components/Currency";
import ButtonConfirm from "components/ButtonConfirm";
import {
  Icons,
  useSelect,
  Button,
  Form,
  Input,
  Select,
  Table,
  Typography,
  notification,
  Descriptions,
} from "@pankod/refine-antd";
import { cloneDeep } from "lodash";
import { v4 as uuid } from "uuid";
import { useFormRule } from "hooks/useFormRule";
import { mappingErrorFromApi, searchSelect } from "utils/commons";
import { EstimateType } from "interfaces/InternalEstimate";
import {
  getInternalEstimateProductFromBusinessPlan,
  getInternalEstimateProductFromConsult,
} from "api";

enum ModalType {
  PAKD,
  YCTV,
  YCTK,
  YCCPTK,
}

const { useWatch, useFormInstance } = Form;
const { Title, Text } = Typography;
const { TextArea } = Input;
const ProductListStyle = styled.div`
  background-color: white;
  padding: 24px;
`;
const ControlStyle = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;
const ControlButtonWrapperStyle = styled.div`
  display: flex;
  gap: 8px;
  overflow: auto;
`;

const DescriptionsStyle = styled(Descriptions)`
  .ant-descriptions-item {
    padding-bottom: 0 !important;
  }
`;

let typingTimeout: any;
const ProductList: FC = memo(() => {
  const {
    onFetchPolicy,
    manday,
    cost,
    totalCost,
    totalCostOfProductList,
    product,
    internalCost,
  } = useContext(InternalEstimateFormContext);
  const [_, setListCost] = cost;
  const [__, setListManday] = manday;
  const { required } = useFormRule();
  const translate = useTranslate();
  const [list, setList] = product;
  const form = useFormInstance<IForm>();
  const [visibleModalFetchProduct, setVisibleModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType | undefined>();

  const internalEstimateCode = useWatch("internalEstimateCode", form);
  const referenceRequestID = useWatch("referenceRequestID", form);
  const caseId = useWatch("caseId", form);
  const internalEstimateType = useWatch("internalEstimateType", form);
  const isAllProducts = useWatch("isAllProducts", form);
  const listInternalEstimateProduct = useWatch(
    "listInternalEstimateProduct",
    form
  );

  const listInternalEstimatedCost = useWatch("listInternalEstimatedCost", form);
  const listInternalEstimatedByManDays = useWatch(
    "listInternalEstimatedByManDays",
    form
  );

  const toggleModalFetchProduct = (type?: ModalType) => () => {
    if (type === ModalType.PAKD && !caseId) {
      return notification.error({
        message: translate("errors.ER0194"),
      });
    }
    if (type !== undefined && type !== ModalType.PAKD && !referenceRequestID) {
      return notification.error({
        message: translate("errors.ER0190"),
      });
    }
    setVisibleModal((prev) => !prev);
    setModalType(type);
  };

  const isShowRemoveButton = list.length > 1;

  const onUpdate = (list: any = {}, orderSort: number) => {
    const newCost: any = {};
    for (const cost in list || {}) {
      if (isAllProducts) {
        newCost[cost] = {
          orderSort:
            list?.[cost]?.orderSort === orderSort
              ? undefined
              : list?.[cost]?.orderSort,
          internalEstimatePolicyId:
            list?.[cost]?.orderSort === orderSort
              ? undefined
              : list?.[cost]?.internalEstimatePolicyId,
          ...list[cost],
        };
      } else if (
        list?.[cost]?.orderSort !== orderSort ||
        !list?.[cost]?.orderSort
      ) {
        newCost[cost] = list[cost];
      }
    }

    return newCost;
  };

  const onRemoveProduct = (index: number) => () => {
    const newList = cloneDeep(list);
    const orderSort = index + 1;
    newList.splice(index, 1);
    setList(newList);

    const newCost = onUpdate(listInternalEstimatedCost, orderSort);
    const newManday = onUpdate(listInternalEstimatedByManDays, orderSort);

    setListCost(Object.keys(newCost || {}));
    setListManday(Object.keys(newManday || {}));
    setTimeout(
      () =>
        form.setFieldsValue({
          listInternalEstimatedCost: newCost,
          listInternalEstimatedByManDays: newManday,
        }),
      0
    );
  };

  const { selectProps: productSelectProps } = useSelect<any>({
    resource: API_PATH.productDropdownList,
    optionLabel: "label",
    optionValue: "id",
    metaData: {
      formatData: (r: any) => ({
        ...r,
        label: `${r.code} - ${r.name}`,
      }),
    },
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const { selectProps: unitSelectProps } = useSelect({
    resource: API_PATH.unitsDropdownlist,
    optionLabel: "label",
    optionValue: "value",
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const convertProduct = (data: any[]) => {
    const products: any = {};
    const ids: string[] = [];

    data?.forEach((p, index) => {
      const id = uuid();
      ids.push(id);
      products[id] = {
        productId: p?.product?.id,
        unitId: p?.unit?.id,
        partNumber: p?.partNumber,
        description: p?.description,
        quantity: p?.quantity,
        unitPrice: p?.unitPrice,
      };
    });

    onFetchPolicy(Object.values(products), internalEstimateType, isAllProducts);
    setList(ids);
    setTimeout(
      () => form.setFieldsValue({ listInternalEstimateProduct: products }),
      0
    );
  };

  const onfetchProductFromServiceRequest = async () => {
    try {
      const res = await getInternalEstimateProductFromConsult(
        API_PATH.internalEstimateProductServiceRequest,
        referenceRequestID!
      );
      convertProduct(res?.data);
    } catch (error: any) {
      mappingErrorFromApi(error, form);
    } finally {
      toggleModalFetchProduct()();
    }
  };

  const onfetchProductFromBusinessPlan = async () => {
    try {
      const res = await getInternalEstimateProductFromBusinessPlan(
        API_PATH.internalEstimateProductBusinessPlan,
        caseId!
      );
      convertProduct(res?.data);
    } catch (error: any) {
      mappingErrorFromApi(error, form);
    } finally {
      toggleModalFetchProduct()();
    }
  };

  const onChangeProduct = (keyRow: string) => (value: any) => {
    const temp = cloneDeep(listInternalEstimateProduct);
    temp[keyRow].productId = value;
    const products = Object.values(temp || {});
    onFetchPolicy(products, internalEstimateType, isAllProducts);
  };

  const onUnitPriceChange =
    (index: number, keyRow: string, keyCalc: string) => (value: any) => {
      if (typingTimeout) clearTimeout(typingTimeout);
      typingTimeout = setTimeout(() => {
        const product = listInternalEstimateProduct?.[keyRow];
        const newCost = cloneDeep(listInternalEstimatedCost);
        const newManday = cloneDeep(listInternalEstimatedByManDays);

        Object.keys(newCost || {}).forEach((c) => {
          if (newCost?.[c]?.orderSort === index) {
            newCost[c].unitPrice = value * product?.[keyCalc];
          }
        });
        Object.keys(newManday || {}).forEach((m) => {
          if (newManday?.[m]?.orderSort === index) {
            newManday[m].unitPrice = value * product?.[keyCalc];
          }
        });

        form.setFieldsValue({
          listInternalEstimatedCost: newCost,
          listInternalEstimatedByManDays: newManday,
        });
      }, 500);
    };

  const getInternalCost = (orderSort: number, keyRow: string): number =>
    internalCost(orderSort, listInternalEstimateProduct?.[keyRow]);

  const margin = useMemo(
    () => totalCostOfProductList - totalCost,
    [totalCostOfProductList, totalCost]
  );

  const renderButtonFetchProducts = () => {
    switch (internalEstimateCode) {
      case EstimateType.Estimated_Consulting:
        return (
          <ButtonConfirm
            type="primary"
            onClick={onfetchProductFromServiceRequest}
            text={translate(
              "Bạn muốn lấy dữ liệu danh sách sản phẩm/dịch vụ từ Yêu cầu dịch vụ?"
            )}
            description={
              "Lưu ý: Sau khi lấy dữ liệu, các thông tin trong danh sách sản phẩm hiện tại sẽ bị xóa."
            }
            noCancel={false}
            visible={visibleModalFetchProduct && modalType === ModalType.YCTV}
            toggle={toggleModalFetchProduct(ModalType.YCTV)}
          >
            {translate("Lấy từ YCTV")}
          </ButtonConfirm>
        );
      case EstimateType.Estimated_Implementation_Plan:
        return (
          <ButtonConfirm
            type="primary"
            onClick={onfetchProductFromServiceRequest}
            text={translate(
              "Bạn muốn lấy dữ liệu danh sách sản phẩm/dịch vụ từ Yêu cầu dịch vụ?"
            )}
            description={
              "Lưu ý: Sau khi lấy dữ liệu, các thông tin trong danh sách sản phẩm hiện tại sẽ bị xóa."
            }
            noCancel={false}
            visible={visibleModalFetchProduct && modalType === ModalType.YCCPTK}
            toggle={toggleModalFetchProduct(ModalType.YCCPTK)}
          >
            {translate("Lấy từ YCCPTK")}
          </ButtonConfirm>
        );

      case EstimateType.Estimated_Implementation:
        return (
          <>
            <ButtonConfirm
              type="primary"
              onClick={onfetchProductFromBusinessPlan}
              text={translate(
                "Bạn muốn lấy dữ liệu danh sách sản phẩm/dịch vụ từ Phương án kinh doanh?"
              )}
              description={
                "Lưu ý: Sau khi lấy dữ liệu, các thông tin trong danh sách sản phẩm hiện tại sẽ bị xóa."
              }
              noCancel={false}
              visible={visibleModalFetchProduct && modalType === ModalType.PAKD}
              toggle={toggleModalFetchProduct(ModalType.PAKD)}
            >
              {translate("Lấy từ PAKD")}
            </ButtonConfirm>
            <ButtonConfirm
              type="primary"
              onClick={onfetchProductFromServiceRequest}
              text={translate(
                "Bạn muốn lấy dữ liệu danh sách sản phẩm/dịch vụ từ Yêu cầu dịch vụ?"
              )}
              description={
                "Lưu ý: Sau khi lấy dữ liệu, các thông tin trong danh sách sản phẩm hiện tại sẽ bị xóa."
              }
              noCancel={false}
              visible={visibleModalFetchProduct && modalType === ModalType.YCTK}
              toggle={toggleModalFetchProduct(ModalType.YCTK)}
            >
              {translate("Lấy từ YCTK")}
            </ButtonConfirm>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <ProductListStyle>
      <ControlStyle>
        <Title level={5}>{translate("Danh sách sản phẩm")}</Title>
        <ControlButtonWrapperStyle>
          {renderButtonFetchProducts()}
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              setList([...list, uuid()]);
            }}
            icon={<Icons.PlusOutlined />}
          >
            {translate("actions.create")}
          </Button>
        </ControlButtonWrapperStyle>
      </ControlStyle>
      <Table<String>
        style={{ marginTop: 8 }}
        size="small"
        className="vertical-align-top w-full"
        dataSource={list}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 1000,
        }}
        locale={{
          emptyText: " ",
        }}
        scroll={{ x: 1850 }}
      >
        <Table.Column
          title={translate("STT")}
          dataIndex="name"
          width="50px"
          align="center"
          render={(v, keyRow: string, index) => {
            return <Text>{index + 1}</Text>;
          }}
        />
        <Table.Column
          title={translate("Tên sản phẩm")}
          dataIndex="id"
          width="200px"
          render={(v, keyRow: string) => {
            return (
              <>
                <Form.Item hidden name={["listInternalEstimateProduct", keyRow, "id"]}/>
                <Form.Item
                  className="m-0"
                  name={["listInternalEstimateProduct", keyRow, "productId"]}
                  rules={required}
                >
                  <Select
                    {...productSelectProps}
                    placeholder={translate("Chọn sản phẩm")}
                    allowClear
                    onChange={onChangeProduct(keyRow)}
                    filterOption={(inputValue: string, option: any) => {
                      return option && searchSelect(inputValue, option?.label);
                    }}
                  />
                </Form.Item>
              </>
            );
          }}
        />

        <Table.Column
          title={translate("Part number")}
          dataIndex="partNumber"
          width="150px"
          render={(v, keyRow: string) => {
            return (
              <>
                <Form.Item
                  className="m-0"
                  name={["listInternalEstimateProduct", keyRow, "partNumber"]}
                >
                  <Input placeholder={translate("Nhập part number")} />
                </Form.Item>
              </>
            );
          }}
        />
        <Table.Column
          title={translate("Mô tả")}
          dataIndex="description"
          width="200px"
          render={(v, keyRow: string) => {
            return (
              <Form.Item
                className="m-0"
                name={["listInternalEstimateProduct", keyRow, "description"]}
                rules={required}
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 4 }}
                  placeholder={translate("Nhập mô tả")}
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          width="150px"
          title={translate("Số lượng")}
          dataIndex="name"
          render={(v, keyRow: string, index) => {
            return (
              <InputMoney
                name={["listInternalEstimateProduct", keyRow, "quantity"]}
                isRequired
                className="m-0"
                placeholder={translate("Nhập giá trị")}
                onChange={onUnitPriceChange(index + 1, keyRow, "unitPrice")}
              />
            );
          }}
        />
        <Table.Column
          title={translate("Đơn vị")}
          dataIndex="unitId"
          width={150}
          render={(v, keyRow: string) => {
            return (
              <Form.Item
                className="m-0"
                name={["listInternalEstimateProduct", keyRow, "unitId"]}
                rules={required}
              >
                <Select
                  {...unitSelectProps}
                  placeholder="Chọn đơn vị"
                  filterOption={(inputValue: string, option: any) => {
                    return option && searchSelect(inputValue, option?.label);
                  }}
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          width="200px"
          title={translate("Đơn giá bán")}
          dataIndex="unitPrice"
          render={(v, keyRow: string, index: number) => {
            return (
              <InputMoney
                name={["listInternalEstimateProduct", keyRow, "unitPrice"]}
                isRequired
                isLimit
                className="m-0"
                placeholder={translate("Nhập giá bán")}
                onChange={onUnitPriceChange(index + 1, keyRow, "quantity")}
              />
            );
          }}
        />
        <Table.Column
          title={translate("Giá bán khách hàng")}
          dataIndex="sellingPrice"
          width="200px"
          render={(v, keyRow: string) => {
            const unitPrice =
              listInternalEstimateProduct?.[keyRow]?.unitPrice || 0;
            const quantity =
              listInternalEstimateProduct?.[keyRow]?.quantity || 0;
            const money = unitPrice * quantity;
            return <Currency value={money} showCurrency={false} />;
          }}
        />
        <Table.Column
          title={translate("Giá vốn nội bộ")}
          dataIndex="sellingPrice"
          width="200px"
          render={(v, keyRow: string, index) => {
            return (
              <Currency
                value={getInternalCost(index + 1, keyRow)}
                showCurrency={false}
              />
            );
          }}
        />
        <Table.Column
          title={translate("Margin")}
          dataIndex="sellingPrice"
          width="200px"
          render={(v, keyRow: string, index) => {
            const unitPrice =
              listInternalEstimateProduct?.[keyRow]?.unitPrice || 0;
            const quantity =
              listInternalEstimateProduct?.[keyRow]?.quantity || 0;
            const money =
              unitPrice * quantity - getInternalCost(index + 1, keyRow);

            return (
              <Currency
                value={money}
                showCurrency={false}
                className={money >= 0 ? "green" : "red"}
              />
            );
          }}
        />

        <Table.Column
          align="center"
          fixed="right"
          width={90}
          title={translate("table.actions")}
          render={(_, __, index) => {
            if (!isShowRemoveButton) return null;
            return (
              <ButtonConfirm
                text={translate("Bạn muốn xóa sản phẩm dịch vụ này?")}
                description=""
                onClick={onRemoveProduct(index)}
                type="text"
              >
                <Icons.DeleteOutlined />
              </ButtonConfirm>
            );
          }}
        />
      </Table>
      <DescriptionsStyle
        style={{ marginTop: 4 }}
        layout="vertical"
        className="no-padding"
        column={{ lg: 3, xs: 1 }}
      >
        <Descriptions.Item label="Tổng giá bán khách hàng" span={1}>
          <Currency value={totalCostOfProductList} showCurrency={false} />
        </Descriptions.Item>
        <Descriptions.Item label="Tổng giá vốn nội bộ" span={1}>
          <Currency value={totalCost} showCurrency={false} />
        </Descriptions.Item>
        <Descriptions.Item label="Margin" span={1}>
          <Currency
            value={margin}
            showCurrency={false}
            className={margin >= 0 ? "green" : "red"}
          />
        </Descriptions.Item>
      </DescriptionsStyle>
    </ProductListStyle>
  );
});

export default ProductList;
