import {
  Button,
  Col,
  DatePicker,
  Form,
  Icons,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
  notification,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { getLinkPrewviewFile, uploadFileApi } from "api";
import {
  MAX_FILE,
  MAX_SIZE,
  acceptExtends,
  acceptFiles,
} from "components/UploadMulti";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "configs/constants";
import { API_PATH } from "configs/path";
import dayjs from "dayjs";
import {
  ChangeEvent,
  FC,
  memo,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";
import { ContractContext, IForm } from "../../..";
import { cloneDeep, isEmpty } from "lodash";
import ButtonConfirm from "components/ButtonConfirm";
import { ContractStatusCode } from "interfaces/Contract";
import { DataText } from "components";
import { formatISODateTimeToView } from "utils/commons";
import styled from "styled-components";

const { Title, Text } = Typography;
const { TextArea } = Input;
const { useWatch, useFormInstance } = Form;

const StyledWrapper = styled.div`
  background-color: #ffffff;
  padding: 24px;
`;

const ContractInfo: FC = memo(() => {
  const translate = useTranslate();
  const form = useFormInstance();
  const signDate = useWatch("signDate", form);
  const validDate = useWatch("validDate", form);
  const expirationDate = useWatch("expirationDate", form);

  useEffect(() => {
    if (!!signDate && !!validDate) {
      form.setFields([
        {
          name: "validDate",
          errors:
            dayjs(signDate).valueOf() <= dayjs(validDate).valueOf()
              ? undefined
              : [translate("Ngày hiệu lực phải diễn ra sau ngày ký")],
        },
      ]);
    }
  }, [signDate, validDate]);

  useEffect(() => {
    if (!!validDate && !!expirationDate) {
      form.setFields([
        {
          name: "expirationDate",
          errors:
            dayjs(validDate).valueOf() <= dayjs(expirationDate).valueOf()
              ? undefined
              : [translate("Ngày hết hiệu lực phải diễn ra sau ngày hiệu lực")],
        },
      ]);
    }
  }, [validDate, expirationDate]);

  return (
    <StyledWrapper>
      <Title level={5}>{translate("Thông tin hợp đồng")}</Title>
      <Row gutter={16}>
        <Col lg={8} xs={24}>
          <Form.Item
            name="signDate"
            rules={[{ required: true, message: translate("errors.ER005") }]}
            label="Ngày ký"
            getValueProps={(value) => ({
              value: value ? dayjs(value) : "",
            })}
          >
            <DatePicker
              placeholder={DATE_FORMAT}
              format={DATE_FORMAT}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col lg={16} xs={24} />
        <Col lg={8} xs={24}>
          <Form.Item
            name="validDate"
            getValueProps={(value) => ({
              value: value ? dayjs(value) : "",
            })}
            rules={[
              { required: true, message: translate("errors.ER005") },
              {
                validator: (_, value) => {
                  if (
                    !!value &&
                    dayjs(value).valueOf() < dayjs(signDate).valueOf()
                  ) {
                    return Promise.reject(
                      new Error(
                        translate("Ngày hiệu lực phải diễn ra sau ngày ký")
                      )
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            label="Ngày hiệu lực"
          >
            <DatePicker
              placeholder={DATE_FORMAT}
              format={DATE_FORMAT}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} xs={24}>
          <Form.Item
            name="expirationDate"
            getValueProps={(value) => ({
              value: value ? dayjs(value) : "",
            })}
            rules={[
              {
                validator: (_, value) => {
                  if (
                    !!value &&
                    dayjs(value).valueOf() < dayjs(validDate).valueOf()
                  ) {
                    return Promise.reject(
                      new Error(
                        translate(
                          "Ngày hết hiệu lực phải diễn ra sau ngày hiệu lực"
                        )
                      )
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            label="Ngày hết hiệu lực"
          >
            <DatePicker
              placeholder={DATE_FORMAT}
              format={DATE_FORMAT}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} xs={24} />
        <Col span={24}>
          <Form.Item
            label="Nội dung hợp đồng"
            name="description"
            rules={[
              { max: 3000, message: translate("errors.ER014", { max: 3000 }) },
            ]}
          >
            <TextArea rows={4} placeholder="Nhập nội dung hợp đồng" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <AttachmentsTable />
        </Col>
      </Row>
    </StyledWrapper>
  );
});

interface AttachmentsTableProps {}
const AttachmentsTable: FC<AttachmentsTableProps> = memo((props) => {
  const { isEdit, initialValue } = useContext(ContractContext);
  const form = useFormInstance<IForm>();
  const caseId = useWatch("caseId", form);
  const attachments = useWatch("attachments", form);
  const [list, setList] = useState<string[]>([]);
  const translate = useTranslate();
  const [isUploading, setUploading] = useState(false);
  const contractStatusCode = useWatch("contractStatusCode", form);
  const [isMaxFile, setMaxFile] = useState(false);
  const [isMaxSize, setMaxSize] = useState(false);
  const [isWrongType, setWrongType] = useState(false);

  const fileIds = useMemo(
    () => Object.values(attachments || {}).map((a) => a.fileId),
    [attachments]
  );

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files: any = [];
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        const extend = file.name.split(".").pop() || "";
        const isRightType = acceptExtends.indexOf(extend.toLowerCase()) > -1;
        const isLessThan5M = file.size / 1024 / 1024 < MAX_SIZE;
        if (!isLessThan5M) {
          setMaxSize(true);
        }
        if (!isRightType) {
          setWrongType(true);
        }

        if (isLessThan5M && isRightType) {
          files.push(file);
        }
      }

      if (files.length + (fileIds || []).length > MAX_FILE) {
        setMaxFile(true);
      } else {
        uploadFile(files.slice(0, 10 - (fileIds || []).length));
      }
      if (document.getElementById(idInput)) {
        const el = document.getElementById(idInput) as any;
        el.value = "";
      }
    }
  };

  const uploadFile = async (files: any[]) => {
    if (!files.length) return;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(`files`, file);
    });

    setUploading(true);
    try {
      const res = await uploadFileApi(
        API_PATH.contractsUploadFile,
        formData,
        caseId
      );
      const newAttachments = cloneDeep(attachments || {});
      const newList = cloneDeep(list);
      res?.data?.forEach((element: any) => {
        newList.push(element.fileId);
        newAttachments[element.fileId] = {
          description: "",
          fileUrl: element.fileUrl,
          fileId: element.fileId,
          fileName: element.fileUrlOriginal,
          uploadDate: element?.uploadDate,
        };
      });

      setList(newList);
      setTimeout(() => form.setFieldsValue({ attachments: newAttachments }), 0);
    } catch (error) {
      //
    } finally {
      setUploading(false);
    }
  };

  const onGetLinkView = (index: number) => async () => {
    try {
      const fileUrl = attachments[list[index]]?.fileUrl;
      const res = await getLinkPrewviewFile(
        API_PATH.contractGetLinkPreviewFile,
        fileUrl
      );
      const url: string = res?.data as unknown as string;
      window.open(url, "_blank");
    } catch (error) {
      notification.error({
        message: "Đã có lỗi xảy ra. Vui lòng liên hệ quản trị viên.",
      });
    }
  };

  const idInput = useId();

  const onRemoveItem = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  useEffect(() => {
    if (isEdit) {
      form.setFields([
        {
          name: "validateAttachments",
          errors:
            contractStatusCode === ContractStatusCode.Signed &&
            isEmpty(attachments)
              ? [translate("errors.ER005")]
              : undefined,
        },
      ]);
    }
  }, [attachments, isEdit, contractStatusCode]);

  useEffect(() => {
    if (isEdit && !!initialValue?.contractDocuments?.length) {
      const ids: string[] = [];
      const attachmentsData: any = {};
      initialValue?.contractDocuments?.forEach((attachment) => {
        ids.push(attachment.fileId);
        attachmentsData[attachment.fileId] = {
          ...attachment,
          fileName: attachment.fileUrlOrigin,
        };
      });

      setList(ids);
      setTimeout(
        () => form.setFieldsValue({ attachments: attachmentsData }),
        0
      );
    }
  }, [isEdit, initialValue]);

  return (
    <>
      <input
        type="file"
        accept={acceptFiles.join(",")}
        multiple
        id={idInput}
        onChange={onChangeFile}
        hidden
      />
      <Row align="middle" style={{ marginBottom: 8 }}>
        <Col flex={1}>
          {translate("Tài liệu đính kèm (Hợp đồng chính thức)")}
        </Col>
        <div style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={(e) => {
              if (!caseId) {
                notification.error({
                  message: "Hãy chọn mã vụ việc trước khi tải file!",
                });
              } else {
                document.getElementById(idInput)?.click();
              }
            }}
            icon={<Icons.UploadOutlined />}
          >
            {translate("Tải lên")}
          </Button>
        </div>
      </Row>
      <Table<String>
        size="small"
        className="vertical-align-top"
        dataSource={list}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 1000,
        }}
        locale={{
          emptyText: " ",
        }}
        loading={isUploading}
        scroll={{ x: 900 }}
      >
        <Table.Column
          title={translate("common.upload.requiredFile")}
          width={430}
          dataIndex="fileId"
          render={(v, keyRow: string, index) => {
            return (
              <>
                <Form.Item
                  hidden
                  name={["attachments", keyRow, "fileName"]}
                  className="m-0"
                />
                <Form.Item
                  name={["attachments", keyRow, "fileId"]}
                  className="m-0"
                >
                  <Form.Item
                    name={["attachments", keyRow, "fileUrl"]}
                    className="m-0"
                  ></Form.Item>
                  {attachments?.[keyRow]?.fileName}
                </Form.Item>
              </>
            );
          }}
        />
        <Table.Column
          title={translate("Mô tả")}
          dataIndex="uploadDate"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                className="m-0"
                name={["attachments", keyRow, "description"]}
                rules={[
                  {
                    max: 3000,
                    message: translate("errors.ER014", { max: 3000 }),
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 4 }}
                  placeholder={translate("Nhập giá trị")}
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("Ngày tải file")}
          dataIndex="uploadDate"
          width={150}
          render={(v, keyRow: string, index) => {
            return (
              <>
                <Form.Item
                  className="m-0"
                  name={["attachments", keyRow, "uploadDate"]}
                  hidden
                />
                <DataText
                  value={formatISODateTimeToView(
                    attachments?.[keyRow]?.uploadDate,
                    DATE_TIME_FORMAT
                  )}
                />
              </>
            );
          }}
        />
        <Table.Column
          align="center"
          width={80}
          fixed="right"
          title={translate("table.actions")}
          render={(v, record, index) => {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="text"
                  icon={<Icons.EyeOutlined />}
                  className="primary"
                  onClick={onGetLinkView(index)}
                />
                <ButtonConfirm
                  text={translate("Bạn muốn xóa thông tin này?")}
                  description=""
                  onClick={onRemoveItem(index)}
                  type="text"
                  danger
                >
                  <Icons.DeleteOutlined />
                </ButtonConfirm>
              </div>
            );
          }}
        />
      </Table>
      <Form.Item
        name="validateAttachments"
        className="m-0"
        rules={[
          {
            validator: () => {
              if (
                contractStatusCode === ContractStatusCode.Signed &&
                isEmpty(attachments)
              ) {
                return Promise.reject(translate("errors.ER005"));
              }
              return Promise.resolve();
            },
          },
        ]}
      />
      <Modal
        title=""
        visible={isMaxSize || isMaxFile || isWrongType}
        onOk={() => {
          setMaxFile(false);
          setMaxSize(false);
          setWrongType(false);
        }}
        onCancel={() => {
          setMaxFile(false);
          setMaxSize(false);
          setWrongType(false);
        }}
        okText={translate("common.understood")}
        cancelButtonProps={{ hidden: true }}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <Space direction="vertical">
            {[
              isMaxSize && (
                <>
                  <Text
                    style={{ fontWeight: 500, fontSize: 16, marginBottom: 0 }}
                  >
                    {translate("common.upload.errorTitleSize")}
                  </Text>
                  <Text>{translate("common.upload.errorDescSize")}</Text>
                  <br />
                </>
              ),
              isWrongType && (
                <>
                  <Text
                    style={{ fontWeight: 500, fontSize: 16, marginBottom: 0 }}
                  >
                    {translate("common.upload.errorTitleType")}
                  </Text>
                  <Text>{translate("common.upload.errorDescType")}</Text>
                  <br />
                </>
              ),
              isMaxFile && (
                <>
                  <Text
                    style={{ fontWeight: 500, fontSize: 16, marginBottom: 0 }}
                  >
                    {translate("common.upload.errorTitleCount")}
                  </Text>
                  <Text>{translate("common.upload.errorDescCount")}</Text>
                </>
              ),
            ]}
          </Space>
        </Space>
      </Modal>
    </>
  );
});

export default ContractInfo;
