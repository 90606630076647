import { Descriptions, Form, Icons, useSelect } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { Button, Collapse, Select, Table, Typography } from "antd";
import { DataText } from "components";
import { API_PATH } from "configs/path";
import { useFormRule } from "hooks/useFormRule";
import { FC, memo, useContext, useMemo, useState } from "react";
import { isEmpty, searchSelect } from "utils/commons";
import { v4 as uuid } from "uuid";
import { IForm, InternalEstimateFormContext } from "../../..";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import { Currency } from "components/Currency";
import ButtonConfirm from "components/ButtonConfirm";
import { cloneDeep } from "lodash";
import { UnitCode } from "interfaces/InternalEstimate";
import { PanelStyle } from "../CostTable";

const { Text } = Typography;
const { useWatch, useFormInstance } = Form;

const MandayTable: FC = memo(() => {
  const { manday, totalCostByManday: total } = useContext(
    InternalEstimateFormContext
  );
  const [list, setList] = manday;
  const { required } = useFormRule();
  const translate = useTranslate();
  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => setCollapse((prev) => !prev);
  const form = useFormInstance<IForm>();
  const listInternalEstimatedByManDays = useWatch(
    "listInternalEstimatedByManDays",
    form
  );
  const isAllProducts = useWatch("isAllProducts", form);
  const listInternalEstimateProduct = useWatch(
    "listInternalEstimateProduct",
    form
  );
  const productIdSelecteds = Object.values(listInternalEstimateProduct || {})
    .map((p) => p.productId)
    .filter(Boolean);

  const { selectProps: productSelectProps } = useSelect({
    resource: API_PATH.interalEstimateProductDropdownlist,
    optionLabel: "label",
    optionValue: "id",
    metaData: {
      formatData: (r: any) => ({
        ...r,
        label: `${r.code} - ${r.name}`,
      }),
    },
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const productOption = useMemo(
    () =>
      productSelectProps?.options?.filter((o) =>
        productIdSelecteds.includes(o.value as any)
      ),
    [productSelectProps, productIdSelecteds]
  );

  const {
    selectProps: rateCardSelectProps,
    queryResult: { data: rateCardData },
  } = useSelect({
    resource: API_PATH.internalEstimatesRateCardDropdownlist,
    optionLabel: "position",
    optionValue: "id",
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const {
    selectProps: unitSelectProps,
    queryResult: { data: unitData },
  } = useSelect({
    resource: API_PATH.internalEstimateUnitDropdownlist,
    optionLabel: "name",
    optionValue: "id",
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const onRemoveProduct = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  const onChangeRateCard = (keyRow: string) => (value: any) => {
    const rateCardSelected = rateCardData?.data?.find((u) => u.id === value);
    const newData = cloneDeep(listInternalEstimatedByManDays);
    const unitIdSelected = newData?.[keyRow]?.estimatedUnitId;
    if (unitIdSelected) {
      const unitSelected = unitData?.data?.find((r) => r.id === unitIdSelected);
      if (unitSelected) {
        newData[keyRow] = {
          ...newData[keyRow],
          internalRateCardId: value,
          unitPrice:
            unitSelected?.code === UnitCode.Estimated_Unit_Hour
              ? rateCardSelected?.rateCardByHour
              : rateCardSelected?.rateCardByDay,
        };
        form.setFieldsValue({ listInternalEstimatedByManDays: newData });
      }
    }
  };

  const onChangeUnit = (keyRow: string) => (value: any) => {
    const unitSelected = unitData?.data?.find((u) => u.id === value);
    const newData = cloneDeep(listInternalEstimatedByManDays);
    const rateCardIdSelected = newData?.[keyRow]?.internalRateCardId;
    if (rateCardIdSelected) {
      const rateCardSelected = rateCardData?.data?.find(
        (r) => r.id === rateCardIdSelected
      );
      if (rateCardSelected) {
        newData[keyRow] = {
          ...newData[keyRow],
          estimatedUnitId: value,
          unitPrice:
            unitSelected?.code === UnitCode.Estimated_Unit_Hour
              ? rateCardSelected?.rateCardByHour
              : rateCardSelected?.rateCardByDay,
          description: rateCardSelected?.description,
        };
        form.setFieldsValue({ listInternalEstimatedByManDays: newData });
      }
    }
  };

  return (
    <Collapse
      collapsible="header"
      className="w-full"
      defaultActiveKey={["1"]}
      ghost
      onChange={toggleCollapse}
    >
      <PanelStyle
        header={translate("Chi phí nhân công")}
        style={{ fontSize: 16 }}
        key="1"
        extra={
          collapse && (
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                setList([...list, uuid()]);
              }}
              icon={<Icons.PlusOutlined />}
            >
              {translate("actions.create")}
            </Button>
          )
        }
      >
        <Table<String>
          style={{ marginTop: 8 }}
          size="small"
          className="vertical-align-top w-full"
          dataSource={list}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 1000,
          }}
          locale={{
            emptyText: " ",
          }}
          scroll={{ x: 1650 }}
        >
          <Table.Column
            title={translate("STT")}
            dataIndex="name"
            width="50px"
            render={(v, keyRow: string, index) => {
              return <Text>{index + 1}</Text>;
            }}
          />
          <Table.Column
            title={translate("Sản phẩm")}
            dataIndex="productId"
            width="200px"
            render={(v, keyRow: string, index) => {
              const isPolicy =
                !!listInternalEstimatedByManDays?.[keyRow]?.orderSort &&
                !isAllProducts;

              const productName =
                listInternalEstimatedByManDays?.[keyRow]?.productName;

              return (
                <>
                  {isAllProducts
                    ? translate("Tất cả sản phẩm")
                    : isPolicy
                    ? productName
                    : null}
                  <Form.Item
                    hidden
                    name={[
                      "listInternalEstimatedByManDays",
                      keyRow,
                      "productName",
                    ]}
                  />
                  <Form.Item
                    name={[
                      "listInternalEstimatedByManDays",
                      keyRow,
                      "productId",
                    ]}
                    className="m-0"
                    rules={isAllProducts || isPolicy ? undefined : required}
                    hidden={isPolicy || isAllProducts}
                  >
                    <Select
                      {...productSelectProps}
                      options={productOption}
                      placeholder={translate("Chọn sản phẩm")}
                      filterOption={(inputValue: string, option: any) => {
                        return (
                          option && searchSelect(inputValue, option?.label)
                        );
                      }}
                    />
                  </Form.Item>
                </>
              );
            }}
          />
          <Table.Column
            title={translate("Công việc")}
            dataIndex="costId"
            width="200px"
            render={(v, keyRow: string, index) => {
              const isPolicy =
                listInternalEstimatedByManDays?.[keyRow]?.orderSort &&
                !isAllProducts;

              return (
                <Form.Item
                  name={[
                    "listInternalEstimatedByManDays",
                    keyRow,
                    "internalRateCardId",
                  ]}
                  className="m-0"
                  rules={isPolicy ? undefined : required}
                >
                  {isPolicy ? (
                    "-"
                  ) : (
                    <Select
                      {...rateCardSelectProps}
                      onChange={onChangeRateCard(keyRow)}
                      placeholder={translate("Chọn công việc")}
                      filterOption={(inputValue: string, option: any) => {
                        return (
                          option && searchSelect(inputValue, option?.label)
                        );
                      }}
                    />
                  )}
                </Form.Item>
              );
            }}
          />
          <Table.Column
            title={translate("Tỷ lệ")}
            dataIndex="percentage"
            width="150px"
            render={(v, keyRow: string, index) => {
              const percentage =
                listInternalEstimatedByManDays?.[keyRow]?.percentage;
              const isPolicy =
                listInternalEstimatedByManDays?.[keyRow]?.orderSort;

              return (
                <>
                  <Form.Item
                    hidden
                    name={[
                      "listInternalEstimatedByManDays",
                      keyRow,
                      "orderSort",
                    ]}
                  />
                  <Form.Item
                    name={[
                      "listInternalEstimatedByManDays",
                      keyRow,
                      "percentage",
                    ]}
                    hidden
                  />

                  <DataText
                    value={
                      !isPolicy || isAllProducts ? (
                        "-"
                      ) : (
                        <Currency
                          value={percentage * 100}
                          showCurrency={false}
                          after={"%"}
                        />
                      )
                    }
                  />
                </>
              );
            }}
          />
          <Table.Column
            width="150px"
            title={translate("Số lượng")}
            dataIndex="quantity"
            render={(v, keyRow: string) => {
              const isPolicy =
                listInternalEstimatedByManDays?.[keyRow]?.orderSort;

              return isPolicy && !isAllProducts ? (
                "-"
              ) : (
                <InputMoney
                  name={["listInternalEstimatedByManDays", keyRow, "quantity"]}
                  isRequired
                  style={{ margin: 0 }}
                  placeholder={translate("Nhập giá trị")}
                />
              );
            }}
          />

          <Table.Column
            width={150}
            title={translate("Đơn vị")}
            dataIndex="estimatedUnitId"
            render={(v, keyRow: string) => {
              const isPolicy =
                listInternalEstimatedByManDays?.[keyRow]?.orderSort;

              return (
                <Form.Item
                  rules={isPolicy && !isAllProducts ? undefined : required}
                  className="m-0"
                  name={[
                    "listInternalEstimatedByManDays",
                    keyRow,
                    "estimatedUnitId",
                  ]}
                >
                  {isPolicy && !isAllProducts ? (
                    "-"
                  ) : (
                    <Select
                      {...unitSelectProps}
                      onChange={onChangeUnit(keyRow)}
                      placeholder={translate("Chọn đơn vị")}
                      filterOption={(inputValue: string, option: any) => {
                        return (
                          option && searchSelect(inputValue, option?.label)
                        );
                      }}
                    />
                  )}
                </Form.Item>
              );
            }}
          />

          <Table.Column
            title={translate("Đơn giá")}
            dataIndex="unitPrice"
            width="200px"
            render={(v, keyRow: string) => {
              const unitPrice =
                listInternalEstimatedByManDays?.[keyRow]?.unitPrice;

              return (
                <>
                  <Form.Item
                    hidden
                    name={[
                      "listInternalEstimatedByManDays",
                      keyRow,
                      "unitPrice",
                    ]}
                  />

                  <DataText
                    value={
                      isEmpty(unitPrice) ? (
                        "-"
                      ) : (
                        <Currency value={unitPrice!} showCurrency={false} />
                      )
                    }
                  />
                </>
              );
            }}
          />
          <Table.Column
            title={translate("Thành tiền")}
            dataIndex="total"
            width="200px"
            render={(v, keyRow: string) => {
              const unitPrice =
                listInternalEstimatedByManDays?.[keyRow]?.unitPrice || 0;
              const quantity =
                listInternalEstimatedByManDays?.[keyRow]?.quantity || 0;
              const percentage =
                listInternalEstimatedByManDays?.[keyRow]?.percentage;
              const isPolicy =
                listInternalEstimatedByManDays?.[keyRow]?.orderSort;

              const money =
                unitPrice *
                (isPolicy && !isAllProducts ? percentage : quantity);
              return <Currency value={money} showCurrency={false} />;
            }}
          />
          <Table.Column
            title={translate("Mô tả")}
            dataIndex="description"
            width="200px"
            render={(v, keyRow: string) => {
              const isPolicy =
                listInternalEstimatedByManDays?.[keyRow]?.orderSort;

              return (
                <>
                  <Form.Item
                    hidden
                    name={[
                      "listInternalEstimatedByManDays",
                      keyRow,
                      "description",
                    ]}
                  />
                  {isPolicy && !isAllProducts ? (
                    translate("Theo định mức công ty quy định")
                  ) : (
                    <DataText
                      value={
                        listInternalEstimatedByManDays?.[keyRow]?.description ||
                        "-"
                      }
                    />
                  )}
                </>
              );
            }}
          />
          <Table.Column
            align="center"
            fixed="right"
            width={90}
            title={translate("table.actions")}
            render={(v, keyRow: string, index) => {
              const isPolicy =
                listInternalEstimatedByManDays?.[keyRow]?.percentage;

              if (isPolicy && !isAllProducts) return null;
              return (
                <ButtonConfirm
                  text={translate("Bạn muốn xóa sản phẩm dịch vụ này?")}
                  description=""
                  onClick={onRemoveProduct(index)}
                  type="text"
                  danger
                >
                  <Icons.DeleteOutlined />
                </ButtonConfirm>
              );
            }}
          />
        </Table>
        <Descriptions
          style={{ marginTop: 4 }}
          layout="horizontal"
          className="no-padding"
        >
          <Descriptions.Item label="Tổng chi phí nhân công">
            <DataText
              value={
                isEmpty(total) ? (
                  "-"
                ) : (
                  <Currency value={total} showCurrency={false} />
                )
              }
            />
          </Descriptions.Item>
        </Descriptions>
      </PanelStyle>
    </Collapse>
  );
});

export default MandayTable;
