import { FC, memo, useContext, useMemo } from "react";
import { ContractShowConText } from "../..";
import { Descriptions, Table, Typography } from "@pankod/refine-antd";
import type { ColumnsType } from "antd/es/table";
import { useTranslate } from "@pankod/refine-core";
import { DataText } from "components";
import { Currency } from "components/Currency";
import { ContractProducts } from "interfaces/Contract";

const { Text } = Typography;

const ServiceScope: FC = memo(() => {
  const record = useContext(ContractShowConText);
  const translate = useTranslate();

  const columns = useMemo<ColumnsType<ContractProducts>>(
    () => [
      {
        title: translate("STT"),
        width: 70,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <Text>{index + 1}</Text>;
        },
      },
      {
        title: translate("Tên sản phẩm"),
        width: 200,
        dataIndex: "productName",
        key: "productName",
        render: (_, record, index) => {
          return <DataText value={record?.productName} />;
        },
      },
      {
        title: translate("Part number"),
        width: 200,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <DataText value={record?.partNumber || "-"} />;
        },
      },
      {
        title: translate("Mô tả"),
        width: 250,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <DataText value={record?.description || "-"} />;
        },
      },
      {
        title: translate("Số lượng"),
        width: 100,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <Currency showCurrency={false} value={record?.quantity} />;
        },
      },
      {
        title: translate("Đơn vị tính"),
        width: 120,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <Text>{record?.unit?.name}</Text>;
        },
      },
      {
        title: translate("Đơn giá"),
        width: 200,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <Currency showCurrency={false} value={record?.unitPrice} />;
        },
      },
      {
        title: translate("% Thuế"),
        width: 100,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return (
            <Currency showCurrency={false} value={record?.taxPercent * 100} />
          );
        },
      },
      {
        title: translate("Trước thuế"),
        width: 200,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return (
            <Currency showCurrency={false} value={record?.beforeTaxValue} />
          );
        },
      },
      {
        title: translate("Thuế"),
        width: 200,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <Currency showCurrency={false} value={record?.taxValue} />;
        },
      },
      {
        title: translate("Sau thuế"),
        width: 200,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return (
            <Currency showCurrency={false} value={record?.afterTaxValue} />
          );
        },
      },
    ],
    []
  );

  return (
    <div style={{ background: "white", padding: 24 }}>
      <Descriptions
        column={2}
        title={translate("Phạm vi dịch vụ")}
        layout="vertical"
      />
      <Table
        size="small"
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={columns}
        dataSource={record?.contractProducts}
        scroll={{ x: 2100 }}
      />
      <Descriptions
        column={{ xs: 1, lg: 3 }}
        layout="vertical"
        className="no-padding"
        style={{ marginTop: 8 }}
      >
        <Descriptions.Item
          label={translate("Tổng giá trước thuế")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency showCurrency={false} value={record?.totalBeforeTaxValue} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Thuế")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency showCurrency={false} value={record?.totalTaxValue} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Tổng giá sau thuế")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency showCurrency={false} value={record?.totalAfterTaxValue} />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
});

export default ServiceScope;
