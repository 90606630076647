import { Icons } from "@pankod/refine-antd"

export interface PageLoadingProps {
    isLoading?: boolean
}
export const PageLoading = ({isLoading}: PageLoadingProps) => {
    if (!isLoading) return (<></>)
    return (
        <div style={{position: 'fixed', left: 0, top: 0, width: '100%', height: '100%', zIndex: 9999, background: 'white', opacity: 0.5, textAlign: 'center', alignItems: 'center'}}>
            <Icons.LoadingOutlined style={{fontSize: 50, position: 'absolute', top: '50%'}}/> 
        </div>
    )
}
