import { PaymentType } from "api/enums";
import { PATH } from "configs/path";
import {
  IAttachment,
  IPayment,
  IPrepay,
  IWorkflow,
  IMisRoles,
  IProduct,
  ISupplierConfig,
} from "interfaces";
import IBusinessPlan from "interfaces/BusinessPlan";
import IContractEstimate from "interfaces/ContractEstimate";
import IInternalEstimate from "interfaces/InternalEstimate";
import ISettlement from "interfaces/Settlement";
import _ from "lodash";
import { v4 as uuid } from "uuid";

export const formatData = (
  data: any,
  resource: string,
  isConverting: boolean = true
) => {
  if (!isConverting) return data;
  switch (resource) {
    case PATH.payments:
      return formatPayment(data);
    case PATH.prepays:
      return formatPrepay(data);
    case PATH.workflows:
      return formatWorkflow(data);
    case PATH.userTenantMisRoles:
      return formatMisRoles(data);
    case PATH.configProducts:
      return formatProduct(data);
    case PATH.configSuppliers:
      return formatSupplier(data);
    case PATH.internalEstimates:
      return formatInterEstimate(data);
    case PATH.businessPlans:
      return formatBusinesssPlan(data);
    case PATH.contractEstimateds:
      return formatContractEstiamteds(data);
    case PATH.settlements:
      return formatSettlement(data);
    default:
      return data;
  }
};

const formatPayment = (r: IPayment) => {
  var attachments = (r.attachments || []).map((item) => ({
    ...item,
    uid: item.fileUrl,
  }));

  const costs: any = {};
  var costListRaw = r?.refundPaymentListCosts || [];
  if (costListRaw.length) {
    costListRaw.forEach((a) => {
      costs[a.id + ""] = {
        costId: a.cost?.id,
        costName: a.cost?.name,
        money: a?.money || "",
        note: a.note,
        realMoney: a?.realMoney || "",
        ordinal: a.ordinal,
        costCode: a?.cost?.code,
      };
    });

    costListRaw = _.orderBy(costListRaw, "ordinal", "desc");
  } else {
    costs[uuid()] = {};
  }
  return {
    ...r,
    advancePaymentId: r.advancePayment?.id,
    listCosts: costs,
    attachments: attachments,
    bankId: r.bank?.bankId,
    bankAccountName: r.bank?.bankAccountName,
    bankAccountNumber: r.bank?.bankAccountNumber,
    bankName: r.bank?.bankName,
    bankBranch: r.bank?.bankBranch,
    employeeId: r.employee?.id,
    departmentId: r.department?.id,
    supplierId: r.supplier?.id,
    refundType: r.refundType === null ? PaymentType.Banking : r.refundType,
  };
};

const formatPrepay = (r: IPrepay) => {
  var attachments = (r.attachments || []).map((item) => ({
    ...item,
    uid: item.fileUrl,
  }));

  const costs: any = {};
  var costListRaw = r?.advancePaymentListCosts || [];
  if (costListRaw.length) {
    costListRaw.forEach((a) => {
      costs[a.id + ""] = {
        costId: a.cost?.id,
        costName: a.cost?.name,
        costCode: a?.cost?.code,
        money: a.money,
        note: a.note,
        ordinal: a.ordinal,
      };
    });
  } else {
    costs[uuid()] = {};
  }
  return {
    ...r,
    listCosts: costs,
    attachments: attachments,
    bankId: r.bank?.bankId,
    bankAccountName: r.bank?.bankAccountName,
    bankAccountNumber: r.bank?.bankAccountNumber,
    bankName: r.bank?.bankName,
    bankBranch: r.bank?.bankBranch,
    employeeId: r.employee?.id,
    departmentId: r.department?.id,
    supplierId: r.supplier?.id,
    advanceType: r.advanceType === null ? PaymentType.Banking : r.advanceType,
  };
};

const formatWorkflow = (w: IWorkflow) => {
  var attachments = formatAttachments(w.attachments);

  return {
    ...w,
    attachments,
  };
};

const formatAttachments = (attachments: IAttachment[] | undefined) => {
  return (attachments || []).map((item) => ({
    ...item,
    uid: `${uuid()}-${item.fileUrl}`,
  }));
};

const formatMisRoles = (data: IMisRoles[]) => {
  const misRoles = data.sort(
    (a: IMisRoles, b: IMisRoles) => a.ordinal - b.ordinal
  );
  return misRoles;
};

const formatProduct = (data: IProduct) => {
  return {
    ...data,
    unitId: data.unit?.id,
    productGroupId: data.productGroup?.id,
  };
};

const formatSupplier = (data: ISupplierConfig) => {
  return {
    ...data,
    businessIndustryId: data.businessIndustry?.id,
  } as ISupplierConfig;
};

const formatInterEstimate = (data: IInternalEstimate) => {
  const { objectData } = data;
  let listInternalEstimateProduct: any = {};
  let listInternalEstimatedCost: any = {};
  let listInternalEstimatedByManDays: any = {};
  objectData?.listInternalEstimatedProduct?.forEach((prod) => {
    const id = uuid();
    listInternalEstimateProduct[id] = {
      productId: prod?.product?.id,
      unitId: prod?.unit?.id,
      partNumber: prod?.partNumber,
      description: prod?.description,
      quantity: prod?.quantity,
      unitPrice: prod?.unitPrice,
      id: prod?.id,
    };
  });

  objectData?.listInternalEstimatedCost?.forEach((prod) => {
    const id = uuid();
    listInternalEstimatedCost[id] = {
      costId: prod?.cost?.id,
      productId: prod?.product?.id,
      productName: `${prod?.product?.code} - ${prod?.product?.name}`,
      internalEstimatePolicyId: prod?.proportionPolicy?.id,
      note: prod?.note,
      quantity: prod?.quantity,
      unitPrice: prod?.unitPrice,
      percentage: prod?.proportionPolicy?.proportion,
      orderSort: prod?.orderSort,
    };
  });

  objectData?.listInternalEstimatedByManDay?.forEach((prod) => {
    const id = uuid();
    listInternalEstimatedByManDays[id] = {
      internalRateCardId: prod?.internalRateCard?.id,
      productId: prod?.product?.id,
      productName: `${prod?.product?.code} - ${prod?.product?.name}`,
      internalEstimatePolicyId: prod?.proportionPolicy?.id,
      estimatedUnitId: prod?.estimatedUnit?.id,
      unitPrice: prod?.unitPrice,
      quantity: prod?.quantity,
      description: prod?.description,
      percentage: prod?.proportionPolicy?.proportion,
      orderSort: prod?.orderSort,
    };
  });
  return {
    ...objectData,
    caseId: objectData?.case?.id,
    referenceRequestID: objectData?.referenceRequestItem?.id,
    referenceRequestCode: objectData?.referenceRequestItem?.code,
    internalEstimateType: objectData?.estimateType?.id,
    internalEstimateCode: objectData?.estimateType?.code,
    listInternalEstimateDocument: objectData?.listInternalEstimatedViewDocument,
    listInternalEstimateProduct,
    listInternalEstimatedCost,
    listInternalEstimatedByManDays,
  };
};

const formatBusinesssPlan = (data: IBusinessPlan) => {
  const objectData = data?.objectData;
  return {
    ...objectData,
    caseId: objectData?.case?.id,
    note: objectData?.businessPlanOverView?.note,
    businessPlanOverViewDocuments:
      objectData?.businessPlanOverView?.businessPlanOverViewDocuments,
    listBusinessPlanItem: data?.objectData?.listBusinessPlanItem || [],
  };
};

const formatContractEstiamteds = (data: IContractEstimate) => {
  const objectData = data?.objectData;
  return {
    ...objectData,
    caseId: objectData?.case?.id,
    // note: objectData?.businessPlanOverView?.note,
    // businessPlanOverViewDocuments:
    //   objectData?.businessPlanOverView?.businessPlanOverViewDocuments,
    // listBusinessPlanItem: data?.objectData?.listBusinessPlanItem || [],
  };
};

const formatSettlement = (data: ISettlement) => {
  const objectData = data?.objectData;
  return {
    ...objectData,
    caseId: objectData?.case?.id,
  };
};
