import {
  Button,
  Card,
  Col,
  Descriptions,
  Icons,
  Row,
  Table,
  notification,
} from "@pankod/refine-antd";
import { FC, memo, useContext, useMemo } from "react";
import { ServiceRequestShowContext } from "../..";
import { useTranslate } from "@pankod/refine-core";
import { DataText } from "components";
import { formatISODateTimeToView } from "utils/commons";
import { DATE_FORMAT } from "configs/constants";
import type { ColumnsType } from "antd/es/table";
import { getLinkPrewviewFile } from "api";
import { API_PATH } from "configs/path";
import { IAttachment } from "interfaces";

const DetailInfo: FC = memo(() => {
  const record = useContext(ServiceRequestShowContext);
  const translate = useTranslate();

  const onGetLinkView = (fileUrl: string) => async () => {
    try {
      const res = await getLinkPrewviewFile(
        API_PATH.serviceRequestPreviewFile,
        fileUrl
      );
      const url: string = res?.data as unknown as string;
      window.open(url, "_blank");
    } catch (error) {
      notification.error({
        message: "Đã có lỗi xảy ra. Vui lòng liên hệ quản trị viên.",
      });
    }
  };

  const columns = useMemo<ColumnsType<IAttachment>>(
    () => [
      {
        title: translate("Tài liệu"),
        width: 200,
        dataIndex: "fileUrlOrigin",
        key: "fileUrlOrigin",
        render: (value, record) => {
          return (
            <Row gutter={16}>
              <Col flex={1}>
                <DataText value={value} />
              </Col>
              <Col>
                <Button
                  type="text"
                  icon={<Icons.EyeOutlined />}
                  className="primary"
                  onClick={onGetLinkView(record?.fileUrl!)}
                />
              </Col>
            </Row>
          );
        },
      },
    ],
    []
  );

  return (
    <Card>
      <Descriptions
        column={{ lg: 3, xs: 1 }}
        title={translate("Thông tin chi tiết")}
        layout="vertical"
      >
        <Descriptions.Item
          label={translate("Mã yêu cầu dịch vụ")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.code} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Tên yêu cầu")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.name} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Loại yêu cầu")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.serviceRequestType?.name} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Ngày yêu cầu")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText
            value={formatISODateTimeToView(record?.requestDate, DATE_FORMAT)}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Ngày yêu cầu hoàn thành")}
          labelStyle={{ fontWeight: 500 }}
          span={2}
        >
          <DataText
            value={formatISODateTimeToView(
              record?.requestCompletionDate,
              DATE_FORMAT
            )}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Mô tả yêu cầu")}
          labelStyle={{ fontWeight: 500 }}
          span={3}
        >
          <DataText value={record?.description} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Tài liệu đính kèm")}
          labelStyle={{ fontWeight: 500 }}
          span={3}
        >
          <Table
            className="w-full"
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
            rowKey="id"
            columns={columns}
            dataSource={record?.serviceRequestView?.serviceRequestViewDocuments}
          />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
});

export default DetailInfo;
