import {
  Card,
  Form,
  Input,
  Typography,
  notification,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import UploadMulti from "components/UploadMulti";
import { API_PATH } from "configs/path";
import { FC, memo } from "react";
import { IForm } from "../../..";

const { TextArea } = Input;
const { Title } = Typography;

const { useWatch, useFormInstance } = Form;

const General: FC = memo(() => {
  const translate = useTranslate();
  const form = useFormInstance<IForm>();
  const caseId = useWatch("caseId", form);
  return (
    <Card>
      <Title level={5}>{translate("Thông tin chung")}</Title>
      <div style={{ paddingLeft: 16 }}>
        <Form.Item
          label={translate("Ghi chú")}
          name="note"
          rules={[
            { required: true, message: translate("errors.ER005") },
            { max: 3000, message: translate("errors.ER014", { max: 3000 }) },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="businessPlanOverViewDocuments"
          validateStatus="success"
          label=""
        >
          <UploadMulti
            title={translate("Tài liệu đi kèm")}
            url={`${process.env.REACT_APP_API_URL}${API_PATH.businessPlanUploadFile}?caseId=${caseId}`}
            idKey="id"
            onClickButtonUpload={(action) => {
              if (!caseId) {
                notification.error({
                  message: translate(
                    "Vui lòng chọn mã vụ việc trước khi tải file."
                  ),
                });
              } else {
                action();
              }
            }}
          />
        </Form.Item>
      </div>
    </Card>
  );
});

export default General;
