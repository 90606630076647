import {
  Button,
  Form,
  Icons,
  List,
  Radio,
  Select,
  Space,
  Table,
  Tag,
  TextField,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { ButtonTooltip, EmptyData, LinkText, SearchBox } from "components";
import CTableColumn from "components/CTableColumn";
import BusinessOpportunities, {
  BusinessOpportunitiesStatus,
} from "interfaces/BusinessOpportunities";
import { FC } from "react";
import { formatISODateTimeToView, searchSelect } from "utils/commons";
import { useController } from "./controller";
import { PATH, SLUGS } from "configs/path";
import { DATE_FORMAT } from "configs/constants";
import { Currency } from "components/Currency";

const Column = CTableColumn<BusinessOpportunities>();

const BusinessOpportunitiesList: FC = () => {
  const {
    formProps,
    areaSelectProps,
    marketProps,
    employeeSelectProps,
    employeeOptions,
    form,
    statusList,
    table: {
      tableQueryResult: { data: dataTable, isLoading },
      sorter,
      pageSize,
      current,
      filters,
      setSorter,
      setPageSize,
      setCurrent,
      setFilters,
    },
    translate,
    create,
    checkCreateHaveHelperText,
    checkEditHaveHelperText,
    edit,
    show,
    renderStatus,
  } = useController();

  return (
    <>
      <List
        title={translate("businessOpportunities.listOfBusinessOpportunities")}
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => create(PATH.businessOpportunities)}
            {...checkCreateHaveHelperText(PATH.businessOpportunities)}
          >
            {translate("actions.create")}
          </ButtonTooltip>
        }
        breadcrumb={null}
      >
        <div className="list-content">
          <Form {...formProps}>
            <Space style={{ flexWrap: "wrap" }}>
              <SearchBox
                placeholder={translate("businessOpportunities.search")}
                onSearch={(e) => {
                  setFilters([
                    {
                      field: "q",
                      operator: "eq",
                      value: e,
                    },
                  ]);
                }}
              />
              <Form.Item name={["region"]} style={{ marginBottom: 16 }}>
                <Select
                  {...areaSelectProps}
                  allowClear
                  onChange={(e) => {
                    setFilters([
                      {
                        field: "region",
                        operator: "eq",
                        value: e,
                      },
                    ]);
                  }}
                  className={"placeholderBlack"}
                  dropdownStyle={{ color: "black" }}
                  style={{ width: 150 }}
                  bordered={false}
                  placeholder={translate("businessOpportunities.area")}
                  filterOption={(inputValue: string, option: any) =>
                    option && searchSelect(inputValue, option?.label)
                  }
                />
              </Form.Item>
              <Form.Item name={["marketArea"]} style={{ marginBottom: 16 }}>
                <Select
                  {...marketProps}
                  allowClear
                  onChange={(e) => {
                    setFilters([
                      {
                        field: "marketArea",
                        operator: "eq",
                        value: e,
                      },
                    ]);
                  }}
                  className={"placeholderBlack"}
                  dropdownStyle={{ color: "black" }}
                  style={{ width: 150 }}
                  bordered={false}
                  placeholder={translate("businessOpportunities.market")}
                  filterOption={(inputValue: string, option: any) =>
                    option && searchSelect(inputValue, option?.label)
                  }
                />
              </Form.Item>
              <Form.Item name={["employeeId"]} style={{ marginBottom: 16 }}>
                <Select
                  {...employeeSelectProps}
                  allowClear
                  onChange={(e) => {
                    setFilters([
                      {
                        field: "employeeId",
                        operator: "eq",
                        value: e,
                      },
                    ]);
                  }}
                  className={"placeholderBlack"}
                  dropdownStyle={{ color: "black" }}
                  style={{ width: 200 }}
                  bordered={false}
                  placeholder={translate("businessOpportunities.employee")}
                  filterOption={(inputValue: string, option: any) =>
                    option && searchSelect(inputValue, option?.label)
                  }
                  options={employeeOptions}
                />
              </Form.Item>

              {filters &&
                filters.length > 0 &&
                !(
                  filters.length === 1 && filters[0]?.operator === "containss"
                ) && (
                  <Button
                    type="text"
                    onClick={(e) => {
                      form.resetFields();
                      setFilters([], "replace");
                    }}
                    style={{ color: "#8C8C8C", marginBottom: 16 }}
                  >
                    {translate("common.cleanFilter")}
                    <Icons.CloseOutlined />
                  </Button>
                )}
            </Space>
            <Form.Item name={["status"]} style={{ marginBottom: 16 }}>
              <Radio.Group
                defaultValue={-1}
                buttonStyle="solid"
                onChange={(e) => {
                  setCurrent(1);
                  setFilters([
                    {
                      field: "status",
                      operator: "eq",
                      value: e?.target?.value === -1 ? "" : e?.target?.value,
                    },
                  ]);
                }}
              >
                <Radio.Button value={-1}>
                  {translate("common.all")}
                </Radio.Button>
                {statusList.map(({ label: csLabel, value: csValue }) => {
                  return (
                    <Radio.Button value={csValue}>
                      {translate(csLabel as string)}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Form>
          <Table
            sticky
            loading={isLoading}
            dataSource={dataTable?.data}
            rowKey="id"
            locale={{
              emptyText: <EmptyData text={translate("common.noDataSearch")} />,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  show(PATH.businessOpportunities, record.id);
                },
              };
            }}
            pagination={{
              pageSize: pageSize,
              current: current,
              total: dataTable?.total,
              showSizeChanger: true,
              onChange: (cur, size) => {
                setCurrent(cur);
                setPageSize(size);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
              },
              locale: {
                items_per_page: translate("common.pageNumber"),
              },
              showTotal: (total) => (
                <p>{translate("common.totalPage", { total: total })}</p>
              ),
            }}
            onChange={(_, __, s) => {
              const newSort = s as any;
              setSorter([
                {
                  field:
                    newSort?.field === "department"
                      ? "department.name"
                      : newSort?.field === "title"
                      ? "title.name"
                      : newSort?.field,
                  order: newSort?.order,
                },
              ]);
            }}
            scroll={{ x: "2500px" }}
            showSorterTooltip={false}
          >
            <Column
              key="code"
              dataIndex="code"
              title={translate("businessOpportunities.caseId")}
              render={(value, record) => (
                <LinkText
                  value={{ id: record.caseId, code: record.code }}
                  resource={SLUGS.caseForSale}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("code", sorter)}
              sorter
            />
            <Column
              key="name"
              dataIndex="name"
              title={translate(
                "businessOpportunities.nameOfBusinessOpportunity"
              )}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("name", sorter)}
              sorter
            />
            <Column
              key="customer"
              dataIndex="customer"
              title={translate("businessOpportunities.customer")}
              render={(value) => <TextField value={value.name} />}
              defaultSortOrder={getDefaultSortOrder("customer.name", sorter)}
              sorter
            />
            <Column
              key="marketArea"
              dataIndex="marketArea"
              title={translate("businessOpportunities.market")}
              render={(value) => <TextField value={value?.name} />}
              defaultSortOrder={getDefaultSortOrder("marketArea.name", sorter)}
              sorter
            />
            <Column
              key="status"
              dataIndex="businessOppsStatus"
              title={translate("businessOpportunities.status")}
              render={(_, record) => {
                return (
                  <Tag {...renderStatus(record?.businessOppsStatus?.code)}>
                    {record?.businessOppsStatus?.name}
                  </Tag>
                );
              }}
            />
            <Column
              key="percentSuccess"
              dataIndex="percentSuccess"
              title={translate("businessOpportunities.winRate")}
              render={(_, record) => (
                <TextField
                  value={`${record?.businessOppsStatus?.percentSuccess}%`}
                />
              )}
              align="center"
            />
            <Column
              width={200}
              key="budgetEstimation"
              dataIndex="budgetEstimation"
              title={translate("businessOpportunities.budget")}
              render={(value, record) => (
                <Currency value={record?.budgetEstimation} />
              )}
              sorter
              align="right"
            />

            <Column
              width={200}
              key="revenueEstimation"
              dataIndex="revenueEstimation"
              title={translate("businessOpportunities.estimatedContractValue")}
              render={(value, record) => (
                <Currency value={record?.revenueEstimation} />
              )}
              sorter
              align="right"
            />
            <Column
              width={200}
              key="profitEstimation"
              dataIndex="profitEstimation"
              title={translate("businessOpportunities.grossProfit")}
              render={(value, record) => (
                <Currency value={record?.profitEstimation} />
              )}
              sorter
              align="right"
            />

            <Column
              key="creationDate"
              dataIndex="creationDate"
              title={translate("businessOpportunities.openDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("creationDate", sorter)}
              sorter
            />

            <Column
              key="closedDate"
              dataIndex="closedDate"
              title={translate("businessOpportunities.closeDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("closedDate", sorter)}
              sorter
            />

            <Column
              key="ownerEmployee"
              dataIndex="ownerEmployee"
              title={translate("businessOpportunities.personInCharge")}
              render={(value) => (
                <TextField
                  value={!!value ? `${value?.code}-${value?.name}` : "-"}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("ownerEmployee", sorter)}
              sorter
            />

            <Column
              key="assignExloitingType"
              dataIndex="assignExloitingType"
              title={translate("Loại account")}
              render={(value) => <TextField value={value?.name || "-"} />}
              defaultSortOrder={getDefaultSortOrder(
                "assignExloitingType",
                sorter
              )}
              sorter
            />

            <Column
              key="createdName"
              dataIndex="createdName"
              title={translate("common.createdName")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("createdName", sorter)}
              sorter
            />

            <Column
              title={translate("table.actions")}
              dataIndex="actions"
              align="center"
              fixed="right"
              width={120}
              render={(_, record) => {
                const isClose = [
                  BusinessOpportunitiesStatus["Won Deal"],
                  BusinessOpportunitiesStatus["Lost Deal"],
                ].includes(Number(record?.businessOppsStatus?.code));
                return (
                  <Space>
                    <ButtonTooltip
                      type="text"
                      icon={
                        isClose ? (
                          <Icons.LockOutlined />
                        ) : (
                          <Icons.EditOutlined />
                        )
                      }
                      className="gray"
                      onClick={(e) => {
                        e.stopPropagation();
                        edit(PATH.businessOpportunities, record?.id);
                      }}
                      {...checkEditHaveHelperText(
                        PATH.businessOpportunities,
                        isClose
                      )}
                    />
                    <div className="line-between-action"></div>
                  </Space>
                );
              }}
            />
          </Table>
        </div>
      </List>
    </>
  );
};

export default BusinessOpportunitiesList;
