import { FormInstance, notificationProvider } from "@pankod/refine-antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { ErrorData, ErrorFieldData } from "api/types";
import { HttpError } from "@pankod/refine-core";
import { ERROR_CODE_MAPPING_FIELD } from "configs/constants";
import { ICase } from "interfaces";
import i18n from "i18n";
import { showErrorToast } from "api/common";
import { SLUGS } from "configs/path";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const patternDatatime: string = "DD/MM/YYYY - HH:mm:ss";
export const isDevEnv = () => {
  return process.env.NODE_ENV === "development";
};

export const isValidEmail = (email: string) => {
  const re = /^\w+([-.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g;
  return re.test(email.trim());
};

export const isValidRoleName = (role: string) => {
  const re = /(?=.*[!@#$%^*\(\)_\+\\={}<>,\.\|""'~`:;\\?\/\[\]])/;
  return !re.test(role.trim());
};

export const isTextMoney = (text: string) => {
  const re =
    /^[a-zA-Z ,ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]*$/;
  return re.test(text);
};

export const isValidCodeField = (code: string) => {
  const re = /^[a-zA-Z0-9]*$/;
  return re.test(code);
};

export const isValidConfigCodeField = (code: string) => {
  const re =
    /^[A-Za-z0-9~!@#$%^&*\_\-\+\=\`\|\(\)\{\}\[\]\:\;\"\'\<\>\,\.\?\/]+$/;
  return re.test(code);
};
export const isValidNumberAndDash = (code: string) => {
  const re = /^[0-9~\-]+$/;
  return re.test(code);
};

export const isValidPhonenumber = (phone?: string) => {
  if (!phone) {
    return true;
  }

  const re =
    /\b(086|096|097|098|032|033|034|035|036|037|038|039|089|090|093|070|079|077|076|078|088|091|094|083|084|085|081|082|092|056|058|099|059)/g;
  const reNum = /^-?\d+$/;
  return (
    re.test(phone.trim()) &&
    reNum.test(phone.trim()) &&
    phone.trim().length === 10
  );
};

export const isValidGeneralPhoneNumber = (phone?: string) => {
  if (!phone) {
    return true;
  }
  const re = /^[0-9\-\+\(\) ]+$/;
  return re.test(phone);
};

export const isValidUrl = (value?: string) => {
  if (!value) {
    return true;
  }
  const regex =
    /^(https?:\/\/)?([\w\d-_]+)\.([\w\d-_\.]+)\/?\??([^#\n\r]*)?#?([^\n\r]*)*$/;
  return regex.test(value);
};

export const formatISODateTimeToView = (
  time?: string | Date,
  format?: string
) => {
  if (!time) {
    return "";
  }
  return dayjs
    .utc(time)
    .local()
    .format(format || patternDatatime);
};

export const formatTimeToUTC = (time: Date) => {
  return dayjs.utc(time).format();
};

export const formatUTCTime = (time?: string | Date, format?: string) => {
  if (!time) {
    return "";
  }
  return dayjs(time).format(format || patternDatatime);
};

export const formatOnlyDateToUTC = (time?: Date) => {
  if (!time) return undefined;
  return dayjs(time).format("YYYY-MM-DDT00:00:00[Z]");
};

export function normalizeVietnamese(str: string): string {
  return str
    ? str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/đ/g, "d")
        .replace(/Đ/g, "D")
    : "";
}

export const normalizeInputForm = (value: string) => {
  // Xử lý giá trị nhập vào bằng cách loại bỏ khoảng trống
  return value.replace(/\s/g, "");
};

export const normalizeNumber = (value: any, prevValue: string) => {
  if (value && isNaN(value)) {
    return !!prevValue ? Number(prevValue) : null;
  }
  return !!value ? Number(value) : null;
};

export const normalizeNumberBetterThanZero = (
  value: any,
  prevValue: string
) => {
  if (value && isNaN(value)) {
    return !!prevValue ? Number(prevValue) : null;
  }
  return !!value && Number(value) > 0 ? Number(value) : "";
};

export function searchSelect(strSearch: string, string: string): boolean {
  const s = normalizeVietnamese(strSearch).toLowerCase();
  const str = normalizeVietnamese(string).toLowerCase();
  return str.toLowerCase().includes(s);
}

export const validateFieldsRequied = async (
  fieldsRequied: string[],
  form: FormInstance,
  errorIgnore: string
): Promise<boolean> => {
  return form
    .validateFields(fieldsRequied)
    .then((r) => {
      let isValid = true;
      const fieldsError = form
        .getFieldsError()
        .filter((f) => f.errors.length > 0);
      if (fieldsError.length > 0) {
        const fieldError = fieldsError.find(
          (f) => !!f.errors.find((er) => er !== errorIgnore)
        );
        if (fieldError) {
          form.scrollToField(fieldError.name, { block: "start" });
          isValid = false;
        }
      }
      return isValid;
    })
    .catch((e) => false);
};

export const mappingErrorFromApi = (
  error: HttpError | ErrorData,
  form: FormInstance,
  errorFieldMapping: any = {}
) => {
  const errorArray = error?.errors;
  if (errorArray && errorArray.length > 0) {
    const fieldData = errorArray.map((i: ErrorFieldData) => ({
      name: i.field.toLowerCase(),
      errors: [i18n.t(`errors.${i.errorCode}`, i?.value || {})],
    }));
    form.setFields(fieldData);
    return;
  }

  const errorDefined = errorFieldMapping[error?.errorCode];
  if (errorDefined) {
    form.setFields([
      {
        name: errorDefined,
        errors: [i18n.t(`errors.${error?.errorCode}`)],
      },
    ]);
    return;
  }
  showErrorToast(error);
};

export const b64toBlob = (base64Data: string) => {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  return new Blob([new Uint8Array(byteArrays)], {
    type: "application/octet-stream",
  });
};

export const disabledDate = (current: any): boolean => {
  // Can not select days before today
  return current && current < dayjs().startOf("day");
};

export const subString = ({
  str = "",
  from = 0,
  to = 50,
  tail = "...",
}: {
  str: string;
  from?: number;
  to?: number;
  tail?: string;
}) => {
  const { length } = str;
  if (!str || length < to || length < from) return str;
  return `${str.substring(from, Math.min(length, to))}${tail}`;
};

export const getSequent = ({
  index,
  pageSize,
  pageIndex,
}: {
  index: number;
  pageSize: number;
  pageIndex: number;
}) => index + pageSize * (pageIndex - 1) + 1;

/**
 *
 * @param number: number for round
 * @param l: length of decimal
 * @returns number
 */
export const roundToHundreds = (number: number, l: number = 3) => {
  if (!number) return 0;
  const length = Math.pow(10, l);
  let multipliedNumber = number * length;

  let roundedNumber = Math.round(multipliedNumber);

  let result = roundedNumber / length;

  return result;
};

export const formatNumberTo = (
  number: number,
  fixed: boolean = false,
  absoluteName?: boolean
) => {
  if (!number) return 0;
  if (number >= 1000 && number < 1000000) {
    return (
      (number / 1000).toFixed(0) +
      `${absoluteName ? " " : ""}${i18n
        .t(`currencyUnit.${absoluteName ? "Kilo" : "K"}`)
        .toLowerCase()}`
    );
  } else if (number >= 1000000 && number < 1000000000) {
    return (
      (number / 1000000).toFixed(fixed ? 3 : 0) +
      `${absoluteName ? " " : ""}${i18n
        .t(`currencyUnit.${absoluteName ? "Million" : "M"}`)
        .toLowerCase()}`
    );
  } else if (number >= 1000000000) {
    return (
      (number / 1000000000).toFixed(fixed ? 3 : 0) +
      `${absoluteName ? " " : ""}${i18n
        .t(`currencyUnit.${absoluteName ? "Billion" : "B"}`)
        .toLowerCase()}`
    );
  } else {
    return number.toString();
  }
};

/**
 * @param number  v: number
 * @param number  n: length of decimal
 * @param number  x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
export const formatNumber = (
  v: number = 0,
  n: number = 0,
  x: number = 3,
  s: any = ",",
  c: any = "."
) => {
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
    num = v.toFixed(Math.max(0, ~~n));

  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    "$&" + (s || ",")
  );
};

export const getSlugToRedirectWorkId = (isSale?: boolean) =>
  isSale ? SLUGS.caseForSale : SLUGS.caseNonSale;
export const validateGUID = (str: string) => {
  const regex =
    /(^\{?[A-Z0-9]{8}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{12}\}?)$/i;

  return regex.test(str);
};

export const convertNumberToCurrency = (
  str: string | number,
  isDecimal: boolean = false
) => {
  let temp = str;
  if (isDecimal) {
    temp = (str as Number).toFixed(3);
  }
  return temp.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const isEmpty = (data: any) =>
  data === null || data === undefined || data === "";

export const Regs = {
  checkNormalTextNoSpace: /^[a-zA-Z0-9]+$/,
};

export const uppercase = (value: string) => value.toUpperCase();

export const objectToQueryString = (object: { [key: string]: any }) => {
  const str = [];
  for (const p in object)
    if (object.hasOwnProperty(p) && !isEmpty(object[p])) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(object[p]));
    }
  return str?.length ? `?${str.join("&")}` : "";
};
