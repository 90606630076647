import {
  Button,
  Col,
  Collapse,
  Form,
  Icons,
  Input,
  Row,
  Select,
  Table,
  Typography,
  useSelect,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import ButtonConfirm from "components/ButtonConfirm";
import { Currency } from "components/Currency";
import { API_PATH } from "configs/path";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import { cloneDeep, isEmpty } from "lodash";
import {
  IForm,
  SettlementFormContext,
} from "pages/projectManagement/settlements/create";
import { useFormRule } from "hooks/useFormRule";
import { searchSelect } from "utils/commons";
import { DataText } from "components";

const { useFormInstance, useWatch } = Form;

const { Text } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;

const CostByMoney: FC = memo(() => {
  const {
    costByMoneyKeyState,
    productSelect,
    costSelect,
    isAllProducts,
    dataForCreate,
    calcTotal,
  } = useContext(SettlementFormContext);
  const form = useFormInstance<IForm>();

  const { required } = useFormRule();

  const listCostByMoney = useWatch("listCostByMoney", form);

  const translate = useTranslate();

  const [visibleCollapse, setVisibleCollapse] = useState(true);

  const [list, setList] = costByMoneyKeyState;

  const dataTable = useMemo(() => {
    if (list.length) {
      return [...list, "sum"];
    }
    return [];
  }, [list]);

  const onRemoveCost = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  const toggleCollapse = () => setVisibleCollapse((prev) => !prev);

  const isReadonly = useCallback(
    (keyRow: string) => !listCostByMoney?.[keyRow]?.editable,
    [listCostByMoney]
  );

  const isPolicy = useCallback(
    (keyRow: string) =>
      !isEmpty(listCostByMoney?.[keyRow]?.proportion) && !isAllProducts,
    [listCostByMoney, isAllProducts]
  );

  const convertListCostByMoney = useMemo(
    () => Object.values(listCostByMoney || {}),
    [listCostByMoney]
  );

  useEffect(() => {
    const a = document.getElementsByClassName("ant-row ant-row-middle");
    a[0]?.setAttribute("style", `margin-bottom: 0px`);
  });

  return (
    <Collapse
      className="w-full"
      defaultActiveKey={["1"]}
      ghost
      onChange={toggleCollapse}
      collapsible="header"
    >
      <Panel
        header={translate("Bằng tiền")}
        key="1"
        extra={
          visibleCollapse && (
            <Row align="middle">
              <Col flex={1} />
              <div style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setList([...list, uuid()]);
                  }}
                  icon={<Icons.PlusOutlined />}
                  style={{ marginTop: 10 }}
                >
                  {translate("actions.create")}
                </Button>
              </div>
            </Row>
          )
        }
      >
        <Table<String>
          style={{ marginTop: 8 }}
          size="small"
          className="vertical-align-top w-full"
          dataSource={dataTable}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 1000,
          }}
          locale={{
            emptyText: " ",
          }}
          scroll={{ x: 1000 }}
        >
          <Table.Column
            title={translate("STT")}
            dataIndex="name"
            width={50}
            align="center"
            render={(v, keyRow: string, index) => {
              if (keyRow === "sum") return null;

              return <Text>{index + 1}</Text>;
            }}
          />
          <Table.Column
            title={translate("Sản phẩm")}
            dataIndex="id"
            width={200}
            render={(v, keyRow: string) => {
              if (keyRow === "sum") return null;
              const readonly = isReadonly(keyRow);
              return (
                <>
                  <Form.Item
                    hidden
                    name={["listCostByMoney", keyRow, "productName"]}
                  />
                  <Form.Item
                    hidden
                    name={["listCostByMoney", keyRow, "editable"]}
                    initialValue={true}
                  />
                  <Form.Item
                    hidden
                    name={["listCostByMoney", keyRow, "refId"]}
                  />
                  <Form.Item hidden name={["listCostByMoney", keyRow, "id"]} />
                  <Form.Item
                    required
                    className="m-0"
                    name={["listCostByMoney", keyRow, "productId"]}
                    rules={[
                      {
                        required: !readonly && !isAllProducts,
                        message: translate("errors.ER005"),
                      },
                    ]}
                  >
                    {isAllProducts ? (
                      <Text>{translate("Tất cả sản phẩm")}</Text>
                    ) : readonly ? (
                      <Text>
                        {listCostByMoney?.[keyRow]?.productName || "-"}
                      </Text>
                    ) : (
                      <Select
                        {...productSelect?.selectProps}
                        placeholder={translate("Chọn sản phẩm")}
                        dropdownMatchSelectWidth={false}
                        filterOption={(inputValue: string, option: any) => {
                          return (
                            option && searchSelect(inputValue, option?.label)
                          );
                        }}
                      />
                    )}
                  </Form.Item>
                </>
              );
            }}
          />
          <Table.Column
            title={translate("Tên chi phí")}
            dataIndex="id"
            width={200}
            render={(v, keyRow: string) => {
              if (keyRow === "sum") return null;

              return (
                <>
                  <Form.Item
                    hidden
                    name={["listCostByMoney", keyRow, "costName"]}
                  />
                  <Form.Item
                    required
                    className="m-0"
                    name={["listCostByMoney", keyRow, "costId"]}
                    rules={isReadonly(keyRow) ? [] : required}
                  >
                    {isReadonly(keyRow) ? (
                      <Text>{listCostByMoney?.[keyRow]?.costName || "-"}</Text>
                    ) : (
                      <Select
                        {...costSelect?.selectProps}
                        placeholder={translate("Chọn chi phí")}
                        dropdownMatchSelectWidth={false}
                        filterOption={(inputValue: string, option: any) => {
                          return (
                            option && searchSelect(inputValue, option?.label)
                          );
                        }}
                      />
                    )}
                  </Form.Item>
                </>
              );
            }}
          />

          <Table.Column
            title={translate("Ghi chú")}
            dataIndex="unitPrice"
            width={200}
            render={(v, keyRow: string) => {
              if (keyRow === "sum") return null;
              const readonly = isReadonly(keyRow);
              return (
                <Form.Item
                  name={["listCostByMoney", keyRow, "note"]}
                  className="m-0"
                  rules={[
                    ...(!readonly ? required : []),
                    {
                      max: 2000,
                      message: translate("errors.ER014", { max: "2,000" }),
                    },
                  ]}
                >
                  {readonly ? (
                    <Text>{listCostByMoney?.[keyRow]?.note || "-"}</Text>
                  ) : (
                    <TextArea
                      autoSize={{ minRows: 1, maxRows: 4 }}
                      placeholder={translate("Nhập thông tin")}
                    />
                  )}
                </Form.Item>
              );
            }}
          />

          <Table.Column
            title={translate("Tỷ lệ")}
            dataIndex="proportion"
            width={200}
            render={(v, keyRow: string, index) => {
              if (keyRow === "sum") return null;
              if (isAllProducts) return "-";
              const proportion = listCostByMoney?.[keyRow]?.proportion;
              const policy = isPolicy(keyRow);

              return (
                <>
                  <Form.Item
                    name={["listCostByMoney", keyRow, "proportion"]}
                    hidden
                  />

                  <DataText
                    value={
                      policy ? (
                        <Currency
                          value={proportion * 100}
                          showCurrency={false}
                          after={"%"}
                        />
                      ) : (
                        "-"
                      )
                    }
                  />
                </>
              );
            }}
          />

          <Table.Column
            title={translate("Đơn giá")}
            dataIndex="unitPrice"
            width={200}
            render={(v, keyRow: string) => {
              if (keyRow === "sum")
                return (
                  <Text className="font-bold">{translate("Tổng cộng")}</Text>
                );
              const readonly = isReadonly(keyRow);

              return (
                <InputMoney
                  name={["listCostByMoney", keyRow, "unitPrice"]}
                  className="m-0"
                  style={{ margin: 0 }}
                  readonly
                  defaulValue={
                    readonly ? listCostByMoney?.[keyRow]?.unitPrice : ""
                  }
                  isEmpty={!readonly}
                />
              );
            }}
          />

          <Table.Column
            width={150}
            title={translate("Số lượng dự toán")}
            dataIndex="name"
            render={(v, keyRow: string) => {
              if (keyRow === "sum")
                return (
                  <Currency
                    value={dataForCreate?.totalQuantityCostTable}
                    showCurrency={false}
                  />
                );

              const readonly = isReadonly(keyRow);
              return (
                <InputMoney
                  className="m-0"
                  name={["listCostByMoney", keyRow, "quantity"]}
                  style={{ margin: 0 }}
                  isLimit={false}
                  readonly
                  defaulValue={
                    readonly ? listCostByMoney?.[keyRow]?.quantity : ""
                  }
                  isEmpty={!readonly}
                />
              );
            }}
          />

          <Table.Column
            title={translate("Thành tiền dự toán")}
            dataIndex="name"
            width={200}
            render={(v, keyRow: string) => {
              if (keyRow === "sum")
                return (
                  <Currency
                    value={dataForCreate?.totalEstimatedCostTable}
                    showCurrency={false}
                  />
                );
              const readonly = isReadonly(keyRow);

              return (
                <InputMoney
                  className="m-0"
                  name={["listCostByMoney", keyRow, "estimatedValue"]}
                  style={{ margin: 0 }}
                  readonly
                  defaulValue={
                    readonly ? listCostByMoney?.[keyRow]?.estimatedValue : ""
                  }
                  isEmpty={!readonly}
                />
              );
            }}
          />

          <Table.Column
            title={translate("Thành tiền quyết toán")}
            dataIndex="name"
            width={200}
            render={(v, keyRow: string) => {
              if (keyRow === "sum")
                return (
                  <Currency
                    value={calcTotal(convertListCostByMoney, "settlementValue")}
                    showCurrency={false}
                  />
                );

              return (
                <InputMoney
                  className="m-0"
                  name={["listCostByMoney", keyRow, "settlementValue"]}
                  isRequired
                  allowDecimal
                  style={{ margin: 0 }}
                  placeholder={translate("Nhập số liệu")}
                />
              );
            }}
          />

          <Table.Column
            align="center"
            fixed="right"
            width={90}
            title={translate("table.actions")}
            render={(v, keyRow: string, index) => {
              if (keyRow === "sum") {
                return null;
              }
              const readonly = isReadonly(keyRow);
              return (
                <ButtonConfirm
                  text={translate("Bạn muốn xóa sản phẩm dịch vụ này?")}
                  danger
                  description=""
                  onClick={onRemoveCost(index)}
                  type="text"
                  hidden={readonly}
                >
                  <Icons.DeleteOutlined />
                </ButtonConfirm>
              );
            }}
          />
        </Table>
      </Panel>
    </Collapse>
  );
});

export default CostByMoney;
