import React from "react";
import {
  EditButton,
  getDefaultSortOrder,
  Table,
  Tag,
  TextField,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { EmptyData, LinkText } from "components";
import { useNavigate } from "react-router-dom";
import { IPrepay } from "interfaces";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import { ApprovalStatus, RefundAdvancePaymentStatus } from "api/enums";
import { Currency } from "components/Currency";
import { ApprovalStatusLabel } from "components/ApprovalStatusLabel";
import usePermissions from "hooks/permission";
import { PATH, SLUGS } from "configs/path";

type Props = {
  isLoading: boolean;
  isRefetching: boolean;
  dataTable: any;
  isSearch: boolean;
  pageSize: number;
  current: number;
  setCurrent: (value: number) => void;
  setPageSize: (value: number) => void;
  sorter: any;
  setSorter: (value: any[]) => void;
  viewTab: string;
};

export const PrepayTable: React.FC<Props> = (props) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { checkEdit } = usePermissions();
  const resourcePath = PATH.prepays;

  const renderRefundStatus = (
    refundPaymentStatus: RefundAdvancePaymentStatus
  ) => {
    switch (refundPaymentStatus) {
      case RefundAdvancePaymentStatus.Paid:
        return <Tag color="blue">{t("prepays.refundStatuses.paid")}</Tag>;
      case RefundAdvancePaymentStatus.Unpaid:
        return <Tag color="gold">{t("prepays.refundStatuses.unpaid")}</Tag>;
    }
  };

  const {
    isLoading,
    isRefetching,
    dataTable,
    isSearch,
    pageSize,
    current,
    setCurrent,
    setPageSize,
    sorter,
    setSorter,
    viewTab,
  } = props;

  return (
    <Table
      loading={isLoading || isRefetching}
      dataSource={dataTable?.data}
      rowKey="id"
      locale={{
        emptyText: (
          <EmptyData text={isSearch ? t("common.noDataSearch") : ""} />
        ),
      }}
      onRow={(record) => {
        return {
          onClick: () => {
            navigate(`${viewTab}/${record.id}`);
          },
        };
      }}
      pagination={{
        pageSize: pageSize,
        current: current,
        total: dataTable?.total,
        showSizeChanger: true,
        onChange: (cur, size) => {
          setCurrent(cur);
          setPageSize(size);
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0;
        },
        showTotal: (total) => <p>{t("common.totalPage", { total: total })}</p>,
        locale: {
          items_per_page: t("common.pageNumber"),
        },
      }}
      onChange={(_, f, s) => {
        const newSort = s as any;
        let sorterField = "";
        switch (newSort?.field) {
          case "employee":
            sorterField = "employee.fullName";
            break;
          case "department":
            sorterField = "department.name";
            break;
          default:
            sorterField = newSort?.field;
            break;
        }
        setSorter([
          {
            field: sorterField,
            order: newSort?.order,
          },
        ]);
      }}
      showSorterTooltip={false}
      scroll={{ x: "2000px" }}
    >
      <Table.Column
        key="id"
        dataIndex="id"
        title={t("common.no")}
        render={(_, __, index) => (current - 1) * pageSize + index + 1}
        width={80}
      />

      <Table.Column
        dataIndex="code"
        title={t("prepays.fields.code")}
        defaultSortOrder={getDefaultSortOrder("code", sorter)}
        width={150}
        sorter
        render={(value, record: any) => (
          <TextField className="primary" value={value || "-"} />
        )}
      />
      <Table.Column
        dataIndex="case"
        title={t("prepays.fields.caseCode")}
        width={150}
        render={(value) => <TextField value={value?.code || "-"} />}
        sorter
      />
      <Table.Column
        dataIndex="name"
        title={t("prepays.fields.name")}
        defaultSortOrder={getDefaultSortOrder("name", sorter)}
        sorter
        width={220}
        render={(value) => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="status"
        title={t("prepays.fields.status")}
        defaultSortOrder={getDefaultSortOrder("status", sorter)}
        width={160}
        render={(value) => <ApprovalStatusLabel status={value} />}
      />
      <Table.Column
        dataIndex="totalAmount"
        title={t("prepays.fields.totalAmount")}
        defaultSortOrder={getDefaultSortOrder("totalAmount", sorter)}
        width={220}
        sorter
        align={"right"}
        render={(value, record: any) => (
          <Currency value={value} currencyType={record.currencyType} />
        )}
      />

      <Table.Column
        dataIndex="refundAdvancePaymentStatus"
        title={t("prepays.refundStatus")}
        defaultSortOrder={getDefaultSortOrder("status", sorter)}
        width={200}
        render={(value) => renderRefundStatus(value)}
      />
      <Table.Column<IPrepay>
        dataIndex="refundPayment.refundedAmount"
        title={t("prepays.fields.refundedAmount")}
        defaultSortOrder={getDefaultSortOrder(
          "refundPayment.refundedAmount",
          sorter
        )}
        width={200}
        sorter
        align={"right"}
        render={(value, record) => {
          return record?.refundPayment?.refundedAmount ? (
            <Currency
              value={record?.refundPayment?.refundedAmount}
              currencyType={record?.currencyType}
            />
          ) : (
            "-"
          );
        }}
      />
      <Table.Column<IPrepay>
        dataIndex="refundPayment.payMoreAmount"
        title={t("prepays.fields.payMoreAmount")}
        defaultSortOrder={getDefaultSortOrder(
          "refundPayment.payMoreAmount",
          sorter
        )}
        width={200}
        sorter
        align={"right"}
        render={(value, record) => {
          return record?.refundPayment?.payMoreAmount ? (
            <Currency
              value={record?.refundPayment?.payMoreAmount}
              currencyType={record.currencyType}
            />
          ) : (
            "-"
          );
        }}
      />
      <Table.Column<IPrepay>
        dataIndex="refundPayment.needRefundAmount"
        title={t("prepays.fields.needRefundAmount")}
        defaultSortOrder={getDefaultSortOrder(
          "refundPayment.needRefundAmount",
          sorter
        )}
        sorter
        width={200}
        align={"right"}
        render={(value, record) => {
          return record?.refundPayment?.needRefundAmount ? (
            <Currency
              value={record?.refundPayment?.needRefundAmount}
              currencyType={record.currencyType}
            />
          ) : (
            "-"
          );
        }}
      />

      <Table.Column
        dataIndex="createdName"
        width={220}
        title={t("common.createdUsername")}
        render={(value) => <TextField className="primary" value={value} />}
        sorter
        defaultSortOrder={getDefaultSortOrder("createdName", sorter)}
      />
      <Table.Column
        dataIndex="createdDate"
        width={220}
        title={t("common.createdDate")}
        defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
        sorter
        render={(value) => value && <DateTimeFieldCustom value={value} />}
      />
      <Table.Column<IPrepay>
        title={t("prepays.fields.receivedEmployee")}
        render={(value, record) => {
          const name = record?.employee?.name || record?.supplier?.name;
          return <TextField value={name || "-"} />;
        }}
        width={220}
      />
      <Table.Column
        dataIndex="department"
        title={t("prepays.fields.department")}
        defaultSortOrder={getDefaultSortOrder("department", sorter)}
        render={(value) => <TextField value={value?.name || "-"} />}
        sorter
        width={220}
      />
      <Table.Column
        dataIndex="submitRequestDate"
        title={t("prepays.fields.submitRequestDate")}
        defaultSortOrder={getDefaultSortOrder("submitRequestDate", sorter)}
        sorter
        render={(value) =>
          value ? <DateTimeFieldCustom value={value} /> : "-"
        }
        width={220}
      />

      <Table.Column<IPrepay>
        title={t("table.actions")}
        dataIndex="actions"
        align="center"
        fixed="right"
        width={120}
        render={(_, record) => {
          return (
            <>
              {record?.status == ApprovalStatus.Draft && (
                <EditButton
                  disabled={!checkEdit(resourcePath)}
                  hideText
                  type="text"
                  style={{ color: "#8C8C8C" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`edit/${record.id}`);
                  }}
                />
              )}
            </>
          );
        }}
      />
    </Table>
  );
};
