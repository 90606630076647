import React from "react";
import {
  getDefaultSortOrder,
  Icons,
  Radio,
  Select,
  Space,
  Table,
  TextField,
  Typography,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useTranslate,
  useTable,
  useNavigation,
} from "@pankod/refine-core";
import {
  EmptyData,
  LinkText,
  SearchBox,
  ButtonTooltip,
  DataText,
} from "components";
import { useNavigate } from "react-router-dom";
import { IHeaderTab, IPayment } from "interfaces";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import { ApprovalStatus, PaymentRequestType } from "api/enums";
import { Currency } from "components/Currency";
import { ApprovalStatusLabel } from "components/ApprovalStatusLabel";
import { ListCustom } from "components/layout";
import ApprovalTable from "components/ApprovalTable";
import { API_PATH, PATH, SLUGS } from "configs/path";
import { useSearchParams } from "react-router-dom";
import usePermissions from "hooks/permission";
import { WORKID_TYPE_OPTIONS } from "configs/constants";

const { Text } = Typography;

export const PaymentList: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { create } = useNavigation();
  const { checkCreateHaveHelperText } = usePermissions();

  const isApproval = props?.name === PATH.paymentApprovalrequests;

  return (
    <ListCustom
      title={t(
        !isApproval ? "payments.titles.list" : "approvalrequests.titles.list"
      )}
      headerButtons={
        <ButtonTooltip
          hidden={isApproval}
          type="primary"
          icon={<Icons.PlusOutlined />}
          onClick={() => create(PATH.payments)}
          {...checkCreateHaveHelperText(PATH.payments)}
        >
          {t("payments.actions.create")}
        </ButtonTooltip>
      }
      breadcrumb={null}
    >
      {isApproval ? <PaymentRequestInformation /> : <PaymentInformation />}
    </ListCustom>
  );
};

export const PaymentInformation = () => {
  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get("code") || "";
  const [paymentCode, setPaymentCode] = React.useState<string>(codeParam);
  const { checkEditHaveHelperText } = usePermissions();

  const resource = PATH.payments;

  const t = useTranslate();
  const navigate = useNavigate();
  const [isSearch, setIsSearch] = React.useState<boolean>(false);

  const {
    tableQueryResult: { data: dataTable, isLoading, isRefetching },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    filters,
    setFilters,
    sorter,
    setSorter,
  } = useTable<IPayment>({
    initialCurrent: 1,
    resource: PATH.payments,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const setSearchFilter = (value: any) => {
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };

  // Read the active tab, payment code from url params
  React.useEffect(() => {
    if (codeParam) {
      setSearchFilter(codeParam);
    }
  }, [codeParam]);

  const onSearch = (value: string) => {
    setCurrent(1);
    setIsSearch(value ? true : false);
    setSearchFilter(value);
  };

  React.useEffect(() => {
    setCurrent(1);
    setIsSearch(filters && filters.length > 0);
  }, [filters]);

  return (
    <div className="list-content">
      <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
        <SearchBox
          placeholder={t("payments.fields.searchPlaceHolder")}
          onSearch={onSearch}
          style={{ width: 304 }}
          value={paymentCode}
          onChange={(value) => {
            setPaymentCode(value.target.value);
          }}
        />
        <Select
          allowClear
          className={"placeholderBlack"}
          placeholder={t("payments.fields.type")}
          dropdownStyle={{ color: "black" }}
          style={{ width: 150, marginBottom: 16 }}
          bordered={false}
          options={[
            {
              value: PaymentRequestType.Payment,
              label: t("payments.type.payment"),
            },
            {
              value: PaymentRequestType.Refund,
              label: t("payments.type.refund"),
            },
          ]}
          onChange={(value, _) => {
            setFilters([
              {
                field: "requestType",
                operator: "eq",
                value: value,
              },
            ]);
          }}
        />
        <Select
          options={WORKID_TYPE_OPTIONS.map((op) => ({
            ...op,
            label: t(op.label),
          }))}
          allowClear
          style={{ width: 200, marginBottom: 16 }}
          bordered={false}
          placeholder={t("prepays.fields.caseCode")}
          onChange={(e) => {
            setFilters([
              {
                field: "caseType",
                operator: "eq",
                value: e,
              },
            ]);
          }}
        />
      </div>
      <Radio.Group
        defaultValue={-1}
        buttonStyle="solid"
        onChange={(e) => {
          setFilters([
            {
              field: "status",
              operator: "eq",
              value: e?.target?.value === -1 ? "" : e?.target?.value,
            },
          ]);
        }}
        style={{ display: "block", marginBottom: 16 }}
      >
        <Radio.Button value={-1}>{t("common.status.all")}</Radio.Button>
        <Radio.Button value={ApprovalStatus.Waiting}>
          {t("common.status.waiting")}
        </Radio.Button>
        <Radio.Button value={ApprovalStatus.Approving}>
          {t("common.status.approving")}
        </Radio.Button>
        <Radio.Button value={ApprovalStatus.Approved}>
          {t("common.status.approved")}
        </Radio.Button>
        <Radio.Button value={ApprovalStatus.Returned}>
          {t("common.status.returned")}
        </Radio.Button>
        <Radio.Button value={ApprovalStatus.Draft}>
          {t("common.status.draft")}
        </Radio.Button>
        <Radio.Button value={ApprovalStatus.Rejected}>
          {t("common.status.reject")}
        </Radio.Button>
        <Radio.Button value={ApprovalStatus.Canceled}>
          {t("common.status.cancel")}
        </Radio.Button>
      </Radio.Group>
      <Table
        loading={isLoading || isRefetching}
        dataSource={dataTable?.data}
        rowKey="id"
        locale={{
          emptyText: (
            <EmptyData text={isSearch ? t("common.noDataSearch") : ""} />
          ),
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`show/${record.id}`);
            },
          };
        }}
        pagination={{
          pageSize: pageSize,
          current: current,
          total: dataTable?.total,
          showSizeChanger: true,
          onChange: (cur, size) => {
            setCurrent(cur);
            setPageSize(size);
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0;
          },
          showTotal: (total) => (
            <p>{t("common.totalPage", { total: total })}</p>
          ),
          locale: {
            items_per_page: t("common.pageNumber"),
          },
        }}
        onChange={(_, f, s) => {
          const newSort = s as any;
          let sorterField = "";
          switch (newSort?.field) {
            case "employee":
              sorterField = "employee.fullName";
              break;
            case "department":
              sorterField = "department.name";
              break;
            case "advancePayment":
              sorterField = "advancePayment.code";
              break;
            default:
              sorterField = newSort?.field;
              break;
          }
          setSorter([
            {
              field: sorterField,
              order: newSort?.order,
            },
          ]);
        }}
        showSorterTooltip={false}
        scroll={{ x: "2000px" }}
      >
        <Table.Column
          key="id"
          dataIndex="id"
          title={t("common.no")}
          render={(_, __, index) => (current - 1) * pageSize + index + 1}
          width={80}
        />
        <Table.Column
          dataIndex="code"
          title={t("payments.fields.code")}
          defaultSortOrder={getDefaultSortOrder("code", sorter)}
          width={160}
          sorter
          render={(value, record: any) => (
            <DataText className="primary" value={value} defaultValue={"-"} />
          )}
        />
        <Table.Column
          key="case"
          dataIndex="case"
          title={t("payments.fields.caseCode")}
          render={(value) => <DataText value={value?.code} defaultValue="-" />}
          width={150}
          sorter
        />

        <Table.Column
          dataIndex="name"
          title={t("payments.fields.name")}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          width={220}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="requestType"
          title={t("payments.fields.type")}
          defaultSortOrder={getDefaultSortOrder("requestType", sorter)}
          sorter
          width={220}
          render={(value) => {
            let typeName = "";
            switch (value) {
              case PaymentRequestType.Payment:
                typeName = t("payments.type.payment");
                break;
              case PaymentRequestType.Refund:
                typeName = t("payments.type.refund");
                break;
            }
            return <Text>{typeName}</Text>;
          }}
        />
        <Table.Column
          dataIndex="status"
          title={t("payments.fields.status")}
          defaultSortOrder={getDefaultSortOrder("status", sorter)}
          sorter
          width={120}
          render={(value) => <ApprovalStatusLabel status={value} />}
        />
        <Table.Column
          dataIndex="advancePayment"
          title={t("payments.fields.prepayCode")}
          defaultSortOrder={getDefaultSortOrder("advancePayment", sorter)}
          width={200}
          render={(value) => <DataText value={value?.code} defaultValue="-" />}
          sorter
        />
        <Table.Column
          dataIndex="realTotalAmount"
          title={t("payments.fields.requestMoney")}
          defaultSortOrder={getDefaultSortOrder("realTotalAmount", sorter)}
          width={220}
          sorter
          align={"right"}
          render={(value, record: any) => {
            const money = record?.realTotalAmount || 0;
            return (
              <Currency value={money} currencyType={record.currencyType} />
            );
          }}
        />
        <Table.Column<IPayment>
          dataIndex="receivedObject"
          title={t("payments.fields.receivedObject")}
          render={(value, record) => {
            const receivedObjectName =
              record?.employee?.name ?? record?.supplier?.name ?? "-";
            return <TextField value={receivedObjectName} />;
          }}
          width={220}
        />
        <Table.Column
          dataIndex="department"
          title={t("payments.fields.department")}
          defaultSortOrder={getDefaultSortOrder("department", sorter)}
          render={(value) => <DataText value={value?.name} defaultValue="-" />}
          sorter
          width={220}
        />
        <Table.Column
          dataIndex="createdName"
          width={220}
          title={t("common.createdUsername")}
          render={(value) => <TextField className="primary" value={value} />}
          sorter
          defaultSortOrder={getDefaultSortOrder("createdName", sorter)}
        />
        <Table.Column
          dataIndex="createdDate"
          width={220}
          title={t("common.createdDate")}
          defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
          sorter
          render={(value) => value && <DateTimeFieldCustom value={value} />}
        />

        <Table.Column
          dataIndex="submitRequestDate"
          title={t("payments.fields.submitRequestDate")}
          defaultSortOrder={getDefaultSortOrder("submitRequestDate", sorter)}
          sorter
          render={(value) =>
            value ? (
              <DateTimeFieldCustom value={value} />
            ) : (
              <DataText defaultValue="-" />
            )
          }
          width={220}
        />

        <Table.Column<IPayment>
          title={t("table.actions")}
          dataIndex="actions"
          align="center"
          fixed="right"
          width={120}
          render={(_, record) => {
            return (
              <>
                {record?.status == ApprovalStatus.Draft && (
                  <ButtonTooltip
                    type="text"
                    icon={<Icons.EditOutlined />}
                    className="gray"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`edit/${record.id}`);
                    }}
                    {...checkEditHaveHelperText(resource)}
                  />
                )}
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

const PaymentRequestInformation = () => {
  return <ApprovalTable resource={API_PATH.payments} filterByWorkIdType />;
};
