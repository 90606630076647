import React, { useContext } from "react";
import {
  Refine,
  Authenticated,
  ResourceProps,
  PromptProps,
} from "@pankod/refine-core";

import { useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import type { History } from "history";

import { ReadyPage, Icons } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import "styles/antd.less";
import "styles/style.less";
import { ChooseTenantPage } from "pages/chooseTenant";
import { CustomLoginPage } from "pages/noAuthViews/login";
import { CustomResetPasswordPage } from "pages/noAuthViews/resetPassword";
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";
import { authProvider } from "provider/authProvider";
import JsonServer from "provider/dataProvider";
import { NO_AUTH_PATH, PATH, SLUGS } from "configs/path";
import usePermissions from "./hooks/permission";
import { CustomUpdatePasswordSuccessPage } from "pages/noAuthViews/updatePassSuccess";

import { UserList, UserCreate, UserShow } from "pages/users";
import {
  DepartmentList,
  DepartmentsCreate,
  DepartmentEdit,
  DepartmentShow,
} from "pages/departments";
import {
  EmployeesList,
  EmployeeCreate,
  EmployeeEdit,
  EmployeeShow,
} from "pages/employees";
import { RoleList, RoleCreate, RoleEdit, RoleShow } from "pages/roles";
import { RolesEmployeeEdit } from "pages/userTenantMisRoles";
import { LocationsProvider } from "contexts/LocationsContext";
import {
  WorkflowCreate,
  WorkflowEdit,
  WorkflowList,
  WorkflowShow,
} from "pages/workflows";
import { WorkflowDiagramEdit } from "pages/workflowDiagrams";
import {
  PrepayCreate,
  PrepayList,
  PrepayShow,
  PrepayEdit,
} from "pages/prepays";
import { PaymentCreate, PaymentList, PaymentShow } from "pages/payments";
import { ErrorComponent } from "components/ErrorComponent";
import { CasesList, CaseShow } from "pages/workId/caseNonSales";
import { ExternalLoginCallback } from "components/ExternalLogin/ExternalLoginCallback";
import { CustomForgotPasswordPage } from "pages/noAuthViews/forgotPassword";
import { NoPermissionPage } from "pages/noAuthViews/noPermissionPage";
import Dashboard from "pages/dashboard";
import { UnitsList, UnitCreate, UnitShow } from "pages/config/units";
import { CostCreate, CostList, CostShow } from "pages/config/costGroup";
import { TitleCreate, TitleList, TitleShow } from "pages/config/title";
import {
  BusinessTripItemCreate,
  BusinessTripItemList,
  BusinessTripItemShow,
} from "pages/config/businessTripItem";
import {
  SupplierCreate,
  SupplierList,
  SupplierShow,
} from "pages/config/supplier";
import {
  ProductGroupCreate,
  ProductGroupList,
  ProductGroupShow,
} from "pages/config/productGroup";
import { ProductCreate, ProductList, ProductShow } from "pages/config/product";
import {
  MarketAreaCreate,
  MarketAreaList,
  MarketAreaShow,
} from "pages/config/marketArea";
import {
  OfficialDispatchCreate,
  OfficialDispatchList,
  OfficialDispatchShow,
} from "pages/config/officialDispatch";
import {
  BusinessIndustryCreate,
  BusinessIndustryList,
  BusinessIndustryShow,
} from "pages/config/businessIndustry";
import { RegionCreate, RegionList, RegionShow } from "pages/config/region";
import {
  CustomerExploitConfigCreate,
  CustomerExploitConfigList,
} from "pages/sale/customerExploitConfigs";
import CustomerEdit from "pages/sale/customers/edit";
import CustomerList from "pages/sale/customers/list";
import CustomerCreate from "pages/sale/customers/create";
import CustomerShow from "pages/sale/customers/show";
import BusinessOpportunitiesEdit from "pages/sale/businessOpportunities/edit";
import BusinessOpportunitiesList from "pages/sale/businessOpportunities/list";
import BusinessOpportunitiesCreate from "pages/sale/businessOpportunities/create";
import BusinessOpportunitiesShow from "pages/sale/businessOpportunities/show";
import CaseIdList from "pages/workId/caseId/list";
import CaseIdShow from "pages/workId/caseId/show";
import { Icon, IconName } from "components/Icon";
import AssignExploitingsList from "pages/sale/assignExploitings/list";
import AssignExploitingsCreate from "pages/sale/assignExploitings/create";
import AssignExploitingsShow from "pages/sale/assignExploitings/show";
import SalePipelineList from "pages/report/salePipeline/list";
import InternalEstimatesCreate from "pages/sale/estimates/internalEstimates/create";
import InternalEstimatesShow from "pages/sale/estimates/internalEstimates/show";
import InternalEstimatesList from "pages/sale/estimates/internalEstimates/list";
import {
  WorkingGroupList,
  WorkingGroupCreate,
  WorkingGroupShow,
} from "pages/config/workingGroup";
import BusinessPlanCreate from "pages/sale/businessPlans/create";
import BusinessPlanShow from "pages/sale/businessPlans/show";
import BusinessPlanList from "pages/sale/businessPlans/list";
import QuotationList from "pages/sale/quotations/list";
import QuotationCreate from "pages/sale/quotations/create";
import QuotationShow from "pages/sale/quotations/show";
import ContractCreate from "pages/sale/contracts/create";
import ContractList from "pages/sale/contracts/list";
import ContractShow from "pages/sale/contracts/show";
import ProjectCreate from "pages/projectManagement/projects/create";
import ProjectList from "pages/projectManagement/projects/list";
import ProjectShow from "pages/projectManagement/projects/show";
import ServiceRequestCreate from "pages/sale/serviceRequest/create";
import ServiceRequestList from "pages/sale/serviceRequest/list";
import ServiceRequestShow from "pages/sale/serviceRequest/show";
import ContractEstimationCreate from "pages/sale/contractEstimation/create";
import ContractEstimationList from "pages/sale/contractEstimation/list";
import ContractEstimationShow from "pages/sale/contractEstimation/show";
import { SettlementsContractList } from "pages/sale/settlementContracts/list";
import CreateSettlementsContract from "pages/sale/settlementContracts/create";
import { SettlementsContractShow } from "pages/sale/settlementContracts/show";
import SettlementCreate from "pages/projectManagement/settlements/create";
import SettlementList from "pages/projectManagement/settlements/list";
import SettlementShow from "pages/projectManagement/settlements/show";

function App() {
  const { t, i18n } = useTranslation();
  const { checkView, checkCreate, checkEdit } = usePermissions();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const approvalPage = [
    {
      element: (
        <Authenticated>
          <PaymentShow isApprover={true} />
        </Authenticated>
      ),
      path: `${SLUGS.paymentApproval}/approval/:id`,
      layout: true,
    },
    {
      element: (
        <Authenticated>
          <PrepayShow isApprover={true} />
        </Authenticated>
      ),
      path: `${SLUGS.prepayApproval}/approval/:id`,
      layout: true,
    },
  ];

  return (
    <LocationsProvider>
      <Refine
        ReadyPage={ReadyPage}
        reactQueryClientConfig={{
          defaultOptions: {
            queries: {
              retry: false,
            },
          },
        }}
        catchAll={<ErrorComponent />}
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <CustomResetPasswordPage />,
              path: NO_AUTH_PATH.resetPassword,
            },
            {
              element: <CustomUpdatePasswordSuccessPage />,
              path: NO_AUTH_PATH.changePasswordSuccess,
            },
            {
              element: <CustomForgotPasswordPage />,
              path: NO_AUTH_PATH.forgotPassword,
            },
            {
              element: <ExternalLoginCallback />,
              path: `${PATH.externalLoginCallback}`,
            },
            {
              element: <AuthenticatedCustomPage />,
              path: PATH.myTenants,
            },
            {
              element: <NoPermissionPage />,
              path: PATH.noPermissionPage,
            },
            ...approvalPage,
          ],
          Prompt: Prompt,
        }}
        dataProvider={{
          default: JsonServer(),
        }}
        accessControlProvider={{
          can: async ({ resource, action, params }) => {
            var can = false;
            // If user have create or update permission, grant the access
            const hybridCreateUpdatePermissionResources: string[] = [
              PATH.customerExploitConfigs,
            ];

            let rs = resource;

            switch (rs) {
              case PATH.paymentApprovalrequests:
                rs = PATH.payments;
                break;
              case PATH.prepayApprovalrequests:
                rs = PATH.prepays;
                break;

              case PATH.businessPlanApprovalrequests:
                rs = PATH.businessPlans;
                break;

              case PATH.internalEstimatesApprovalrequests:
                rs = PATH.internalEstimates;
                break;

              default:
                break;
            }

            switch (action) {
              case "list":
              case "show":
                can = checkView(rs);
                break;
              case "edit":
              case "create":
                if (
                  hybridCreateUpdatePermissionResources.findIndex(
                    (t) => t == rs
                  ) !== -1
                ) {
                  can = checkEdit(rs) || checkCreate(rs);
                } else {
                  can = action === "edit" ? checkEdit(rs) : checkCreate(rs);
                }
                break;
            }

            if (params?.resource?.options?.allAccess === true) {
              can = true;
            }

            return Promise.resolve({ can: !rs || can });
          },
        }}
        authProvider={authProvider}
        LoginPage={CustomLoginPage}
        resources={[
          {
            icon: <Icons.HomeOutlined />,
            name: PATH.dashboards,
            list: Dashboard,
            options: {
              allAccess: true,
            },
          },

          // Work id
          {
            name: PATH.workId,
            options: {
              allAccess: true,
            },
          },
          {
            icon: <Icons.DollarCircleOutlined />,
            list: CaseIdList,
            show: CaseIdShow,
            name: PATH.caseId,
            parentName: PATH.workId,
            options: {
              label: t("caseid.businessCaseId"),
              allAccess: true,
            },
          },
          {
            icon: <Icon iconName={IconName.store} />,
            name: PATH.caseNonSales,
            parentName: PATH.workId,
            list: CasesList,
            show: CaseShow,
            options: {
              label: t("workid.departmentCaseId"),
            },
          },

          // Report
          {
            name: PATH.report,
            options: {
              allAccess: true,
            },
          },

          {
            name: PATH.salePipelines,
            parentName: PATH.report,
            list: SalePipelineList,
            options: {
              label: t("Sale Pipelines"),
            },
          },

          // Project
          {
            name: PATH.projectManagement,
            options: {
              allAccess: true,
              label: "Quản trị dự án",
            },
          },

          {
            icon: <Icon iconName={IconName.complexBox} />,
            name: PATH.projects,
            parentName: PATH.projectManagement,
            options: {
              label: t("Dự án"),
            },

            edit: ProjectCreate,
            list: ProjectList,
            create: ProjectCreate,
            show: ProjectShow,
          },

          {
            icon: <Icon iconName={IconName.settlement} />,
            name: PATH.settlements,
            parentName: PATH.projectManagement,
            options: {
              label: t("Quyết toán triển khai"),
            },
            edit: SettlementCreate,
            list: SettlementList,
            create: SettlementCreate,
            show: SettlementShow,
          },

          // Sale
          {
            name: PATH.sales,
            options: {
              allAccess: true,
              label: t("sales.menu"),
            },
          },
          {
            icon: <Icon iconName={IconName.dimand} />,
            name: PATH.businessOpportunities,
            parentName: PATH.sales,
            options: {
              label: t("businessOpportunities.businessOpportunities"),
            },

            edit: BusinessOpportunitiesEdit,
            list: BusinessOpportunitiesList,
            create: BusinessOpportunitiesCreate,
            show: BusinessOpportunitiesShow,
          },

          {
            icon: <Icon iconName={IconName.box} />,
            name: PATH.serviceRequests,
            parentName: PATH.sales,
            options: {
              label: t("Yêu cầu dịch vụ"),
            },

            edit: ServiceRequestCreate,
            list: ServiceRequestList,
            create: ServiceRequestCreate,
            show: ServiceRequestShow,
          },

          {
            icon: <Icons.DollarCircleOutlined />,
            name: PATH.internalEstimates,
            parentName: PATH.sales,
            create: InternalEstimatesCreate,
            edit: InternalEstimatesCreate,
            show: InternalEstimatesShow,
            list: InternalEstimatesList,
            options: {
              label: t("Dự toán nội bộ"),
            },
          },

          {
            icon: <Icons.DollarCircleOutlined />,
            name: PATH.businessPlans,
            parentName: PATH.sales,
            create: BusinessPlanCreate,
            edit: BusinessPlanCreate,
            show: BusinessPlanShow,
            list: BusinessPlanList,
            options: {
              label: t("Phương án kinh doanh"),
            },
          },

          {
            icon: <Icon iconName={IconName.quotation} />,
            name: PATH.quotations,
            parentName: PATH.sales,
            options: {
              label: t("Báo giá"),
            },
            edit: QuotationCreate,
            list: QuotationList,
            create: QuotationCreate,
            show: QuotationShow,
          },

          {
            icon: <Icons.DollarCircleOutlined />,
            name: PATH.contractEstimateds,
            parentName: PATH.sales,
            options: {
              label: t("Dự toán hợp đồng"),
            },
            edit: ContractEstimationCreate,
            list: ContractEstimationList,
            create: ContractEstimationCreate,
            show: ContractEstimationShow,
          },

          {
            icon: <Icons.DollarCircleOutlined />,
            name: PATH.contracts,
            parentName: PATH.sales,
            options: {
              label: t("Hợp đồng"),
            },
            edit: ContractCreate,
            list: ContractList,
            show: ContractShow,
            create: ContractCreate,
          },
          {
            icon: <Icons.DollarCircleOutlined />,
            name: PATH.settlementContracts,
            
            parentName: PATH.sales,
            options: {
              label: t("settlements.contract.name"),
            },
            list: SettlementsContractList,
            create: CreateSettlementsContract,
            show: SettlementsContractShow,
            edit: CreateSettlementsContract,
          },
          {
            icon: <Icon iconName={IconName.box} />,
            name: PATH.customers,
            parentName: PATH.sales,
            options: {
              label: t("customer.customers"),
            },
            edit: CustomerEdit,
            list: CustomerList,
            create: CustomerCreate,
            show: CustomerShow,
          },

          {
            icon: <Icon iconName={IconName.box} />,
            name: PATH.assignExploitings,
            parentName: PATH.sales,
            options: {
              label: t("assignExploiting.assignExploit"),
            },
            edit: AssignExploitingsCreate,
            list: AssignExploitingsList,
            create: AssignExploitingsCreate,
            show: AssignExploitingsShow,
          },

          {
            icon: <Icon iconName={IconName.file} />,
            name: PATH.saleConfigs,
            parentName: PATH.sales,
            options: {
              label: t("common.masterData"),
              allAccess: true,
            },
          },
          {
            name: PATH.configMarketAreas,
            parentName: PATH.saleConfigs,
            edit: MarketAreaCreate,
            list: MarketAreaList,
            show: MarketAreaShow,
            create: MarketAreaCreate,
          },
          {
            name: PATH.configBusinessIndustries,
            parentName: PATH.saleConfigs,
            edit: BusinessIndustryCreate,
            list: BusinessIndustryList,
            show: BusinessIndustryShow,
            create: BusinessIndustryCreate,
          },

          {
            name: PATH.configRegions,
            parentName: PATH.saleConfigs,
            edit: RegionCreate,
            list: RegionList,
            show: RegionShow,
            create: RegionCreate,
          },

          // E-Office

          {
            name: PATH.eOffice,
            options: {
              allAccess: true,
              label: t("eoffices.menu"),
            },
          },
          {
            icon: <Icons.DollarCircleOutlined />,
            name: PATH.prepay,
            parentName: PATH.eOffice,
            options: {
              allAccess: true,
              label: t("prepays.prepays"),
            },
          },

          {
            name: PATH.prepays,
            parentName: PATH.prepay,
            options: {
              label: t("DS tạm ứng"),
            },
            list: PrepayList,
            show: PrepayShow,
            create: PrepayCreate,
            edit: PrepayEdit,
          },

          {
            name: PATH.prepayApprovalrequests,
            parentName: PATH.prepay,
            options: {
              label: t("Yêu cầu cần phê duyệt"),
              permission: PATH.prepays,
            },
            list: PrepayList,
            show: PrepayShow,
            create: PrepayCreate,
            edit: PrepayEdit,
          },

          {
            icon: <Icon iconName={IconName.wallet} />,
            name: PATH.payment,
            parentName: PATH.eOffice,
            options: {
              allAccess: true,
              label: t("payments.payments"),
            },
          },
          {
            name: PATH.payments,
            parentName: PATH.payment,
            edit: PaymentCreate,
            list: PaymentList,
            show: PaymentShow,
            create: PaymentCreate,
            options: {
              label: t("DS thanh toán/hoàn ứng"),
            },
          },
          {
            name: PATH.paymentApprovalrequests,
            parentName: PATH.payment,
            edit: PaymentCreate,
            list: PaymentList,
            show: PaymentShow,
            create: PaymentCreate,
            options: {
              label: t("Yêu cầu cần phê duyệt"),
              permission: PATH.payments,
            },
          },

          {
            icon: <Icon iconName={IconName.file} />,
            name: PATH.eOfficeConfigs,
            parentName: PATH.eOffice,
            options: {
              label: t("common.masterData"),
              allAccess: true,
            },
          },

          {
            name: PATH.configOfficialDispatches,
            parentName: PATH.eOfficeConfigs,
            edit: OfficialDispatchCreate,
            list: OfficialDispatchList,
            show: OfficialDispatchShow,
            create: OfficialDispatchCreate,
          },
          {
            name: PATH.configBusinessTripItems,
            parentName: PATH.eOfficeConfigs,
            edit: BusinessTripItemCreate,
            list: BusinessTripItemList,
            show: BusinessTripItemShow,
            create: BusinessTripItemCreate,
          },

          // System

          {
            name: PATH.system,
            options: {
              allAccess: true,
              label: t("system.menu"),
            },
          },

          {
            icon: <Icons.UserOutlined />,
            parentName: PATH.system,
            name: PATH.users,
            list: UserList,
            show: UserShow,
            create: UserCreate,
          },
          {
            icon: <Icons.UsergroupAddOutlined />,
            name: PATH.employees,
            parentName: PATH.system,
            list: EmployeesList,
            show: EmployeeShow,
            create: EmployeeCreate,
            edit: EmployeeEdit,
          },
          {
            name: PATH.userTenantMisRoles,
            edit: RolesEmployeeEdit,
            parentName: PATH.employees,
          },
          {
            icon: <Icon iconName={IconName.key} />,
            parentName: PATH.system,
            name: PATH.roles,
            edit: RoleEdit,
            list: RoleList,
            show: RoleShow,
            create: RoleCreate,
          },

          {
            icon: <Icon iconName={IconName.checkedShield} />,
            parentName: PATH.system,
            name: PATH.workflows,
            edit: WorkflowEdit,
            list: WorkflowList,
            show: WorkflowShow,
            create: WorkflowCreate,
          },
          {
            icon: <Icon iconName={IconName.file} />,
            name: PATH.systemConfig,
            parentName: PATH.system,
            options: {
              label: t("common.masterData"),
              allAccess: true,
            },
          },
          {
            name: PATH.configSuppliers,
            parentName: PATH.systemConfig,
            edit: SupplierCreate,
            list: SupplierList,
            show: SupplierShow,
            create: SupplierCreate,
          },
          {
            name: PATH.configProductGroups,
            parentName: PATH.systemConfig,
            edit: ProductGroupCreate,
            list: ProductGroupList,
            show: ProductGroupShow,
            create: ProductGroupCreate,
          },
          {
            name: PATH.configProducts,
            parentName: PATH.systemConfig,
            edit: ProductCreate,
            list: ProductList,
            show: ProductShow,
            create: ProductCreate,
          },
          {
            name: PATH.configUnits,
            parentName: PATH.systemConfig,
            edit: UnitCreate,
            list: UnitsList,
            show: UnitShow,
            create: UnitCreate,
          },
          {
            icon: <Icons.ApartmentOutlined />,
            name: PATH.departments,
            parentName: PATH.systemConfig,
            list: DepartmentList,
            show: DepartmentShow,
            create: DepartmentsCreate,
            edit: DepartmentEdit,
          },
          // {
          //   options: { label: t("workingGroup.title") },
          //   name: PATH.configWorkingGroup,
          //   parentName: PATH.systemConfig,
          //   list: WorkingGroupList,
          //   show: WorkingGroupShow,
          //   create: WorkingGroupCreate,
          //   edit: WorkingGroupCreate,
          // },
          {
            name: PATH.departments,
            parentName: PATH.systemConfig,
            list: DepartmentList,
            show: DepartmentShow,
            create: DepartmentsCreate,
            edit: DepartmentEdit,
          },
          {
            name: PATH.configTitles,
            parentName: PATH.systemConfig,
            options: {
              label: t("titles.titles.menu"),
            },
            edit: TitleCreate,
            list: TitleList,
            show: TitleShow,
            create: TitleCreate,
          },

          {
            name: PATH.configCosts,
            parentName: PATH.systemConfig,
            edit: CostCreate,
            list: CostList,
            show: CostShow,
            create: CostCreate,
          },
          {
            name: PATH.workflowsDiagrams,
            create: WorkflowDiagramEdit,
            edit: WorkflowDiagramEdit,
            parentName: PATH.workflows,
          },
          // {
          //   name: PATH.customerExploitConfigs,
          //   parentName: PATH.systemConfig,
          //   edit: CustomerExploitConfigCreate,
          //   list: CustomerExploitConfigList,
          //   create: CustomerExploitConfigCreate,
          // },
        ]}
        Title={Title}
        // Header={Header}
        Sider={Sider}
        Footer={Footer}
        Layout={Layout}
        OffLayoutArea={OffLayoutArea}
        i18nProvider={i18nProvider}
      />
    </LocationsProvider>
  );
}

export default App;

const AuthenticatedCustomPage = () => {
  return (
    <Authenticated>
      <ChooseTenantPage />
    </Authenticated>
  );
};

const Prompt: React.FC<PromptProps> = ({ message, when, setWarnWhen }) => {
  const navigator = useContext(NavigationContext).navigator as History;

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((transition: any) => {
      if (window.confirm(message)) {
        setWarnWhen?.(false);
        unblock();
        transition.retry();
      } else {
        navigator.location.pathname = window?.location?.pathname;
      }
    });

    return unblock;
  }, [when, window.location, message]);

  return null;
};
