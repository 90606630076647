import { Form, useForm, useSelect } from "@pankod/refine-antd";
import { useTable } from "@pankod/refine-core";
import { API_PATH } from "configs/path";
import { CustomerStatus } from "interfaces/Customer";
import { OptionViewAproval } from "interfaces/ServiceRequest";

interface FilterForm {
  region: string;
  marketArea: string;
  status: CustomerStatus;
}

const { useWatch } = Form;

export const useController = () => {
  const { form, formProps } = useForm<FilterForm>();
  const table = useTable<any>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const { setFilters } = table;

  const { selectProps: contractTypeProps } = useSelect({
    resource: API_PATH.contractTypeDropdownlist,
    optionValue: "id",
    optionLabel: "name",

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const { selectProps: statusProps } = useSelect({
    resource: API_PATH.contractStatusDropdownlist,
    optionValue: "id",
    optionLabel: "name",
  });

  const {selectProps: customerFilterProps} = useSelect({
    resource:  API_PATH.customerDropdownlistByName,
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const {selectProps: supplierFilterProps} = useSelect({
    resource:  API_PATH.supplierDropdownlistByName,
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const optionViewAproval = useWatch("optionViewAproval", form);

  const onChangeOptionView = (option: OptionViewAproval) => () => {
    const value = optionViewAproval === option ? undefined : option;

    form.setFieldsValue({
      optionViewAproval: value,
    });

    setFilters([
      {
        field: "optionViewAproval",
        operator: "eq",
        value,
      },
    ]);
  };

  return {
    table,
    formProps,
    form,
    contractTypeProps,
    statusProps,
    customerFilterProps,
    supplierFilterProps,
    optionViewAproval,
    onChangeOptionView,
  };
};
