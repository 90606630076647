import {
  Table,
  Button,
  Typography,
  Input,
  Icons,
  Row,
  Col,
  Space,
  Descriptions,
  Form,
  FormInstance,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { CurrencyType, PaymentBeneficiaryType } from "api/enums";
import { SelectCustom } from "components/SelectCustom";
import { API_PATH, PATH } from "configs/path";
import { IPrepay } from "interfaces";
import { useEffect, useState } from "react";
import { Currency } from "./Currency";
import { DateTimeFieldCustom } from "./DateTimeTextField";
import { MoneyInput } from "./MoneyInput";
import { CostTableTypeEnum } from "utils/enums";
import ButtonConfirm from "./ButtonConfirm";
import { v4 as uuid } from "uuid";
import TextValue from "./TextValue";
import { LinkText } from "./LinkText";
import { useNumToWord } from "hooks/useNumToWord";
import { getSlugToRedirectWorkId } from "utils/commons";

const { Text } = Typography;

export interface ICostTable {
  title?: string;
  mode?: CostTableTypeEnum;
  prepayDetail?: IPrepay;
  currencyType: CurrencyType;
  form: FormInstance;
  costKeys?: Array<string>;
}

const MAX_MONEY = 100 * 1000 * 1000 * 1000;

const CostTable = (props: ICostTable) => {
  const { mode, prepayDetail, form, currencyType, costKeys } = props;
  const t = useTranslate();
  const listCosts = Form.useWatch("listCosts", form) || {};

  const [list, setList] = useState<string[]>([uuid()]);

  useEffect(() => {
    if (costKeys) {
      setList(costKeys || []);
    }
  }, [costKeys]);

  const total = Object.keys(listCosts)
    .map((a) => listCosts[a])
    .reduce((a: any, b: any) => a + +(b.money || 0), 0);

  const realTotal = Object.keys(listCosts)
    .map((a) => listCosts[a])
    .reduce((a: any, b: any) => a + +(b.realMoney || 0), 0);
  let currency = currencyType;

  const { text } = useNumToWord({
    currency,
    num: mode === CostTableTypeEnum.FULL_PREPAY ? total : realTotal,
  });

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        background:
          mode !== CostTableTypeEnum.FULL &&
          mode !== CostTableTypeEnum.FULL_PREPAY
            ? "#f4f4f4"
            : "transparent",
        padding:
          mode !== CostTableTypeEnum.FULL &&
          mode !== CostTableTypeEnum.FULL_PREPAY
            ? 12
            : 0,
        marginBottom: 24,
      }}
    >
      {mode !== CostTableTypeEnum.FULL &&
        mode !== CostTableTypeEnum.FULL_PREPAY &&
        prepayDetail && (
          <>
            <Descriptions column={3} layout="vertical">
              {prepayDetail?.case && (
                <Descriptions.Item
                  span={3}
                  labelStyle={{ fontWeight: 500 }}
                  label={t("payments.fields.case")}
                >
                  <LinkText
                    value={{
                      ...prepayDetail?.case,
                      code: prepayDetail?.case?.name,
                    }}
                    resource={getSlugToRedirectWorkId(prepayDetail?.case?.type)}
                    showTooltip={false}
                  />
                </Descriptions.Item>
              )}

              <Descriptions.Item label={t("prepays.fields.code")}>
                {prepayDetail.code}
              </Descriptions.Item>
              <Descriptions.Item label={t("prepays.fields.name")}>
                {prepayDetail.name}
              </Descriptions.Item>
              <Descriptions.Item label={t("prepays.fields.time")}>
                {prepayDetail.submitRequestDate && (
                  <DateTimeFieldCustom value={prepayDetail.submitRequestDate} />
                )}
              </Descriptions.Item>
              <Descriptions.Item label={t("prepays.fields.receivedObject")}>
                {prepayDetail.beneficiaryType ===
                  PaymentBeneficiaryType.Supplier &&
                  t("common.beneficiaryType.supplier")}
                {prepayDetail.beneficiaryType ===
                  PaymentBeneficiaryType.Internal &&
                  t("common.beneficiaryType.internal")}
              </Descriptions.Item>
              {prepayDetail.beneficiaryType ===
                PaymentBeneficiaryType.Internal && (
                <>
                  <Descriptions.Item label={t("prepays.fields.employee")}>
                    {prepayDetail.employee?.code} -{" "}
                    {prepayDetail.employee?.name}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("prepays.fields.department")}>
                    {prepayDetail.department?.name}
                  </Descriptions.Item>
                </>
              )}
              {prepayDetail.beneficiaryType ===
                PaymentBeneficiaryType.Supplier && (
                <Descriptions.Item
                  span={2}
                  label={t("prepays.fields.supplier")}
                >
                  {prepayDetail.supplier?.name}
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t("prepays.fields.currency")}>
                {prepayDetail.currencyType === CurrencyType.EUR && "EUR"}
                {prepayDetail.currencyType === CurrencyType.VND && "VND"}
                {prepayDetail.currencyType === CurrencyType.USD && "USD"}
                {prepayDetail.currencyType === CurrencyType.SGD && "SGD"}
              </Descriptions.Item>
            </Descriptions>
            <label>{t("payments.fields.costList")}</label>
          </>
        )}
      {(mode === CostTableTypeEnum.FULL ||
        mode === CostTableTypeEnum.FULL_PREPAY) && (
        <Row align="middle">
          <Col flex={1}>
            <label style={{ fontWeight: 500 }}>
              <span className="required-mark">*</span>
              {t("prepays.fields.costList")}
            </label>
          </Col>
          <Button
            type="primary"
            onClick={() => {
              setList([...list, uuid()]);
            }}
            icon={<Icons.PlusOutlined />}
          >
            {t("prepays.actions.addCost")}
          </Button>
        </Row>
      )}

      <Table<any>
        size="small"
        className="vertical-align-top"
        dataSource={list.concat(["total"])}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 1000,
        }}
        locale={{
          emptyText: " ",
        }}
        scroll={{ x: 980 }}
      >
        <Table.Column
          title={t("payments.costs.name")}
          dataIndex="name"
          width="230px"
          render={(v, key: string) => {
            if (key === "total")
              return <Text strong>{t("payments.costs.total")}</Text>;
            var disabled =
              mode !== CostTableTypeEnum.FULL &&
              mode !== CostTableTypeEnum.FULL_PREPAY;
            if (disabled) {
              return (
                <>
                  <TextValue
                    value={`${listCosts?.[key]?.costCode} - ${listCosts?.[key]?.costName}`}
                  />
                  <Form.Item
                    required
                    className="m-0"
                    hidden
                    name={["listCosts", key, "costName"]}
                    rules={[
                      {
                        required: true,
                        message: t("errors.ER005"),
                      },
                    ]}
                  />

                  <Form.Item
                    name={["listCosts", key, "costCode"]}
                    hidden
                    className="m-0"
                  />
                  <Form.Item
                    name={["listCosts", key, "costId"]}
                    hidden
                    className="m-0"
                  />
                </>
              );
            }
            return (
              <Form.Item
                required
                style={{ margin: 0 }}
                name={["listCosts", key, "costId"]}
                rules={[
                  {
                    required: true,
                    message: t("errors.ER005"),
                  },
                ]}
              >
                <SelectCustom
                  resource={API_PATH.costDropdownList}
                  formatData={(r: any) => ({
                    ...r,
                    label: `${r.code} - ${r.label}`,
                  })}
                  optionLabel="label"
                  optionValue="value"
                  placeholder={t("payments.costs.namePlaceholder")}
                  style={{ width: "100%", color: "#000" }}
                />
              </Form.Item>
            );
          }}
        />
        {mode !== CostTableTypeEnum.FULL &&
          mode !== CostTableTypeEnum.FULL_PREPAY && (
            <Table.Column
              title={
                (mode !== CostTableTypeEnum.READ ? "(1) " : "") +
                t("payments.costs.prepays")
              }
              width="230px"
              align="right"
              dataIndex="money"
              render={(v, key: string) => {
                if (key === "total")
                  return (
                    <Text>
                      {total ? (
                        <Currency
                          strong
                          value={total}
                          currencyType={currency}
                        />
                      ) : (
                        "-"
                      )}
                    </Text>
                  );
                return (
                  <Form.Item
                    style={{ margin: 0 }}
                    required
                    name={["listCosts", key, "money"]}
                  >
                    <Currency value={1} currencyType={currency} />
                  </Form.Item>
                );
              }}
            />
          )}
        {mode === CostTableTypeEnum.FULL_PREPAY && (
          <Table.Column
            title={t("payments.costs.prepays")}
            width="230px"
            align="right"
            dataIndex="money"
            render={(v, key: string) => {
              if (key === "total")
                return (
                  <Text strong>
                    {total ? (
                      <Currency strong value={total} currencyType={currency} />
                    ) : (
                      "-"
                    )}
                  </Text>
                );
              return (
                <Form.Item
                  style={{ margin: 0 }}
                  required
                  name={["listCosts", key, "money"]}
                  rules={[
                    {
                      required: true,
                      message: t("errors.ER005"),
                    },
                    {
                      validator: async (_, value) => {
                        if (!value) return;
                        if (value > MAX_MONEY) {
                          return Promise.reject(
                            t("errors.ER091", { min: 0, max: "100 tỷ" })
                          );
                        }
                        if (value <= 0) {
                          return Promise.reject(
                            t("errors.ER091", { min: 0, max: "100 tỷ" })
                          );
                        }
                      },
                    },
                  ]}
                >
                  <MoneyInput
                    style={{ textAlign: "right" }}
                    placeholder={t("payments.costs.moneyPlaceholder")}
                    allowClear
                    currencyType={currency}
                    max={MAX_MONEY}
                    min={0}
                    allowDecimal
                    decimalLength={2}
                  />
                </Form.Item>
              );
            }}
          />
        )}
        {(mode === CostTableTypeEnum.FULL ||
          mode === CostTableTypeEnum.EDIT) && (
          <Table.Column
            title={
              (mode !== CostTableTypeEnum.FULL ? "(2) " : "") +
              t("payments.costs.moneyReal")
            }
            width="230px"
            align="right"
            dataIndex="realMoney"
            render={(v, key: string) => {
              if (key === "total")
                return (
                  <Text strong>
                    {realTotal ? (
                      <Currency
                        strong
                        value={realTotal}
                        currencyType={currency}
                      />
                    ) : (
                      "-"
                    )}
                  </Text>
                );

              return (
                <Form.Item
                  style={{ margin: 0 }}
                  required
                  name={["listCosts", key, "realMoney"]}
                  rules={[
                    {
                      required: true,
                      message: t("errors.ER005"),
                    },
                    {
                      validator: async (_, value) => {
                        if (value > MAX_MONEY) {
                          return Promise.reject(
                            t("errors.ER091", { min: 0, max: "100 tỷ" })
                          );
                        }
                        if (value <= 0 && mode === CostTableTypeEnum.FULL) {
                          return Promise.reject(
                            t("errors.ER091", { min: 0, max: "100 tỷ" })
                          );
                        }
                        if (value < 0 && mode === CostTableTypeEnum.EDIT) {
                          return Promise.reject(
                            t("errors.ER091", { min: 0, max: "100 tỷ" })
                          );
                        }
                      },
                    },
                  ]}
                >
                  {/* <Input /> */}
                  <MoneyInput
                    style={{ textAlign: "right" }}
                    placeholder={t("payments.costs.moneyPlaceholder")}
                    allowClear
                    currencyType={currency}
                    allowDecimal
                    decimalLength={2}
                  />
                </Form.Item>
              );
            }}
          />
        )}

        {(mode === CostTableTypeEnum.FULL ||
          mode === CostTableTypeEnum.EDIT ||
          mode === CostTableTypeEnum.FULL_PREPAY) && (
          <Table.Column
            dataIndex="desc"
            title={t("payments.costs.note")}
            width={200}
            render={(v, key: string) => {
              if (key === "total") return "";
              return (
                <Form.Item
                  style={{ margin: 0 }}
                  name={["listCosts", key, "note"]}
                  rules={[
                    {
                      max: 200,
                      message: t("errors.ER014", { max: "200" }),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("payments.costs.notePlaceholder")}
                    allowClear
                  />
                </Form.Item>
              );
            }}
          />
        )}
        {(mode === CostTableTypeEnum.FULL ||
          // mode === CostTableTypeEnum.EDIT ||
          mode === CostTableTypeEnum.FULL_PREPAY) && (
          <Table.Column
            align="center"
            width="120px"
            title={t("table.actions")}
            render={(v, key) => {
              if (list.length <= 1 || key === "total") {
                return null;
              }
              return (
                <ButtonConfirm
                  text={t("payments.costs.deleteConfirmText")}
                  description=""
                  onClick={() => {
                    setList(list.filter((a) => a !== key));
                  }}
                  type="text"
                >
                  <Icons.DeleteOutlined />
                </ButtonConfirm>
              );
            }}
          />
        )}
      </Table>

      {(mode === CostTableTypeEnum.FULL ||
        mode === CostTableTypeEnum.FULL_PREPAY) && (
        <Space
          style={{ padding: "0 12px", width: "100%" }}
          direction="vertical"
        >
          <Row align="middle">
            <Col style={{ width: 225 }}>
              <Text>
                <span className="required-mark">*</span>
                {t("payments.costs.totalWord")}
              </Text>
            </Col>
            <Col style={{ width: 400 }}>{text}</Col>
          </Row>
        </Space>
      )}
      {mode === CostTableTypeEnum.EDIT && (
        <>
          <Row align="middle">
            <Text>{t("payments.costs.totalRefund")}: </Text>
            <Text strong style={{ marginLeft: 8 }}>
              <Currency
                strong
                value={total > realTotal ? total - realTotal : 0}
                currencyType={currency}
              />
            </Text>
          </Row>
          <Row align="middle">
            <Text> {t("payments.costs.totalPayment")}: </Text>
            <Text strong style={{ marginLeft: 8 }}>
              <Currency
                strong
                value={realTotal > total ? realTotal - total : 0}
                currencyType={currency}
              />
            </Text>
          </Row>
        </>
      )}
    </Space>
  );
};

export default CostTable;
