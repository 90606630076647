import { Col, Descriptions, Form, Row, Typography } from "@pankod/refine-antd";
import { useOne, useTranslate } from "@pankod/refine-core";
import { DataText, SelectCustom } from "components";
import { useFormRule } from "hooks/useFormRule";
import { FC, memo, useContext } from "react";
import { IForm, SettlementFormContext } from "../../..";
import { API_PATH } from "configs/path";
import CCollapse, { CaseInfoPanel } from "components/CCollapse";
import { CaseInfo as ICaseInfo } from "interfaces/CaseId";
import { formatISODateTimeToView } from "utils/commons";
import { DATE_TIME_FORMAT } from "configs/constants";
import { Currency } from "components/Currency";

const { Title } = Typography;
const { useWatch, useFormInstance } = Form;

const CaseInfo: FC = memo(() => {
  const { onChangeCase } = useContext(SettlementFormContext);
  const form = useFormInstance<IForm>();
  const translate = useTranslate();
  const { required } = useFormRule();

  const caseId = useWatch("caseId", form);

  const caseData = useOne<ICaseInfo>({
    resource: API_PATH.caseInfo(caseId),
    id: "",
    queryOptions: { enabled: !!caseId },
  });

  const caseDetail = caseData?.data?.data;
  const loadingCase = caseData?.isFetching;

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Title level={5}>{translate("Thông tin vụ việc")}</Title>
      <Row>
        <Col xs={24} lg={12}>
          <Form.Item
            name="caseId"
            rules={required}
            label={translate("Vụ việc")}
            className={!!caseId ? "" : "m-0"}
          >
            <SelectCustom
              resource={API_PATH.settlementCaseDropdownlist}
              placeholder={translate("Chọn vụ việc")}
              optionLabel="label"
              optionValue="value"
              onChangeOption={onChangeCase}
            />
          </Form.Item>
        </Col>
      </Row>
      <CCollapse
        hidden={!caseId}
        loading={loadingCase}
        title={translate("Chi tiết vụ việc")}
      >
        <CaseInfoPanel caseInfo={caseDetail} />
      </CCollapse>
    </div>
  );
});

export default CaseInfo;
