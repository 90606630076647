import { FC, memo, useContext, useMemo } from "react";
import {
  CTitle,
  ControlContainer,
  ControlLeftContainer,
  EstimateInfoStyle,
} from "../../../create/components/EstimateInfo";
import { Checkbox, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { generateCurrency } from "components/Currency";
import { CurrencyType } from "api/enums";
import CostTable from "./CostTable";
import MandayTable from "./MandayTable";
import TotalEstimate from "./TotalEstimate";
import { InternalEstimateShowContext } from "../..";

const { Text } = Typography;

const EstimateInfo: FC = memo(() => {
  const { record } = useContext(InternalEstimateShowContext);

  const translate = useTranslate();
  const currency = useMemo(() => generateCurrency(CurrencyType.VND), []);

  return (
    <EstimateInfoStyle>
      <ControlContainer>
        <ControlLeftContainer>
          <CTitle level={4}>{translate("Chi tiết dự toán")}</CTitle>
          <Checkbox checked={record?.isAllProducts}>
            {translate("Dự toán cho tất cả sản phẩm")}
          </Checkbox>
        </ControlLeftContainer>
        <Text>
          Đơn vị tiền tệ:{" "}
          <Text style={{ color: currency.unitColor, fontWeight: "bold" }}>
            {currency.unit}
          </Text>
        </Text>
      </ControlContainer>
      <CostTable />
      <MandayTable />
      <TotalEstimate />
    </EstimateInfoStyle>
  );
});

export default EstimateInfo;
