import { Button, Col, Form, Icons, Radio, Row, Table, TextField, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation, useTable, useTranslate } from "@pankod/refine-core";
import { ApprovalStatus } from "api/enums";
import { ButtonTooltip, EmptyData, SearchBox } from "components";
import { ApprovalStatusLabel } from "components/ApprovalStatusLabel";
import CTableColumn from "components/CTableColumn";
import { ListCustom } from "components/layout";
import { DATE_TIME_FORMAT } from "configs/constants";
import { API_PATH, PATH } from "configs/path";
import { SubmitEmployee, WaitingProcessEmployees } from "interfaces";
import { ContractEstimateTableRow } from "interfaces/ContractEstimate";
import { OptionViewAproval } from "interfaces/ServiceRequest";
import { memo, useMemo } from "react";
import { formatISODateTimeToView } from "utils/commons";

const { useWatch } = Form;
interface RowData extends ContractEstimateTableRow {
  submitEmployee: SubmitEmployee;
  waitingProcessEmployees: WaitingProcessEmployees[];
}
const Column = CTableColumn<RowData>();

export const SettlementsContractList: React.FC<IResourceComponentsProps> = memo(() => {
  const translate = useTranslate();
  const {show, edit, create} = useNavigation();
  const { form, formProps } = useForm<any>();
  const optionViewAproval = useWatch<OptionViewAproval>("optionViewAproval", form);
  const table = useTable({
    initialCurrent: 1,
    initialPageSize: 10,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      }
    ]
  }) 
  const {pageSize, current, filters, setCurrent, setPageSize, setFilters, setSorter} = table;
  const dataTable = useMemo(
    () =>
      table?.tableQueryResult?.data?.data?.map((row) => ({
        ...row.objectData,
        submitEmployee: row?.submitEmployee,
        waitingProcessEmployees: row?.waitingProcessEmployees,
      })),
    [table?.tableQueryResult]
  );

  const { selectProps: statusProps } = useSelect({
    resource: API_PATH.settlementContractsStatusDropdownlist,
    optionValue: "code",
    optionLabel: "displayName",
  });
  
  const onChangeOptionView = (option: OptionViewAproval) => {
    const statusValue = form.getFieldValue("status");
    const value = option === optionViewAproval ? null : option;
    form.setFieldsValue({ optionViewAproval: value });

    setFilters([
      {
        field: "optionViewAproval",
        operator: "eq",
        value: value,
      },
      {
        field: "status",
        operator: "eq",
        value: statusValue > -1 ? statusValue : undefined,      
      }
    ]);
  }

  return (
    <ListCustom
      title={translate("settlements.contract.list")}
      breadcrumb={null}
      headerButtons={
        <ButtonTooltip
          type="primary"
          icon={<Icons.PlusOutlined />}
          onClick={() => {create(PATH.settlementContracts) }}// TODO: navigate to create page
        >{translate("actions.create")}
        </ButtonTooltip>
      }
    >
      <div className="list-content">
        <Form
        {...formProps}
          initialValues={{status: -1, oldStatus: -1}}
        >
          <Row justify="space-between">
            <Col>
              <SearchBox
                placeholder={translate("settlements.searchPlaceholder")}
                style={{ width: 300 }}
                onSearch={(e) => {
                  setFilters([
                    {
                      field: "q",
                      operator: "eq",
                      value: e,
                    }
                  ])
                }}
              />
              {filters &&
                  filters.length > 0 &&
                  !(
                    filters.length === 1 && filters[0]?.operator === "containss"
                  ) && (
                    <Button
                      type="text"
                      onClick={(e) => {
                        form.resetFields();
                        setFilters([], "replace");
                      }}
                      style={{ color: "#8C8C8C" }}
                    >
                      {translate("common.cleanFilter")}
                      <Icons.CloseOutlined />
                    </Button>
                  )}
            </Col>
            <Row gutter={8} style={{ paddingBottom: '8px' }}>
            <Col >
              <Button
                onClick={() => {
                  onChangeOptionView(OptionViewAproval.MINE)
                }}
                shape="round"
                type={
                  optionViewAproval === OptionViewAproval.MINE
                    ? "primary" : "ghost"
                }
              >
                {translate("common.my")}
              </Button>
            </Col>
            <Col>
                  <Button
                    onClick={() => { 
                      onChangeOptionView(OptionViewAproval.APPROVING)
                    }}
                    shape="round"
                    type={
                      optionViewAproval === OptionViewAproval.APPROVING
                        ? "primary":"ghost"
                    }
                  >
                    Cần xử lý
                  </Button>
                </Col>
            </Row>
            
          </Row>
          <Form.Item name={["optionViewAproval"]} hidden />
          <Form.Item name={["status"]} style={{ marginBottom: 16 }}>
            <Radio.Group
              defaultValue={-1}
              buttonStyle="solid"
              onChange={(e) => {
                setCurrent(1);
                form.setFieldsValue({ oldStatus: e.target.value });
                setFilters([
                  {
                    field: "status",
                    operator: "eq",
                    value:
                      e?.target?.value === -1 ? undefined : e?.target?.value,
                  },
                ]);
              }}
            >
              <Radio.Button value={-1}>
                {translate("common.all")}
              </Radio.Button>

              {statusProps?.options?.map(({ label, value }) => {
                return (
                  <Radio.Button value={value}>
                    {translate(label as string)}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </Form>
        <Table
          sticky
          loading={table.tableQueryResult.isLoading}
          dataSource={dataTable}
          rowKey="id"
          locale={{
            emptyText: <EmptyData text={translate("common.noDataSearch")} />,
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                show(PATH.settlementContracts,  record.id);
              },
            };
          }}
          
          pagination={{
            pageSize: pageSize,
            current: current,
            total: table.tableQueryResult.data?.total,
            showSizeChanger: true,
            onChange: (cur, size) => {
              setCurrent(cur);
              setPageSize(size);
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0;
            },
            locale: {
              items_per_page: translate("common.pageNumber"),
            },
            showTotal: (total) => (
              <p>{translate("common.totalPage", { total: total })}</p>
            ),
          }}
          onChange={(_, __, s) => {
            // setSorter(s);
            const newSort = s as any;
            setSorter([
              {
                field:
                  newSort?.field === "department"
                    ? "department.name"
                    : newSort?.field === "title"
                    ? "title.name"
                    : newSort?.field,
                order: newSort?.order,
              },
            ]);
          }}
          scroll={{ x: 2500 }}
          showSorterTooltip={false}
        >
          <Column
            key="code"
            dataIndex="code"
            title={translate("table.idSettlement")}
            render={(value, record) => (
              <TextField
                value={value || "-"}
                className={value && "primary"}
              />
            )}
            sorter
          />
          <Column
            key="case"
            dataIndex="case"
            width={150}
            title={translate("table.workId")}
            render={(value, record) => (
              <TextField value={record?.case?.code} />
            )}
            sorter
          />

          <Column
            key={"caseName"}
            dataIndex={"case"}
            title={translate("table.nameWorkId")}
            render={(value, record) => (
              <TextField value={value?.name} />
            )}
            sorter
          />

          <Column
            key={"status"}
            dataIndex={"status"}
            title={translate("table.process.status")}
            render={(status: ApprovalStatus, record) => (
              <ApprovalStatusLabel status={Number(status)} />
            )}
            sorter
          />

          <Column
            key={"waitingProcessEmployees"}
            dataIndex={"waitingProcessEmployees"}
            title={translate("table.process.people")}
            render={(value, record) => {
              const content = record?.waitingProcessEmployees
                ?.map((emp) => `${emp.code} - ${emp.fullName}`)
                ?.join(",");
              return <TextField value={content || "-"} />;
            }}
            sorter
          />
          <Column
            key={"department"}
            dataIndex={"department"}
            title={translate("table.department")}
            render={(value, record) => (
              <TextField value={record?.ownerByDepartment?.name || "-"} />
            )}
            sorter
          />
           <Column
            key={"createdName"}
            dataIndex={"createdName"}
            title={translate("table.create.people")}
            render={(value, { createdUserDetail }) => (
              <TextField
                value={`${createdUserDetail?.code} - ${createdUserDetail?.name}`}
              />
            )}
            sorter
          />
          <Column
            key={"createdDate"}
            dataIndex={"createdDate"}
            title={translate("table.create.time")}
            render={(value) => (
              <TextField
                value={formatISODateTimeToView(
                  value as string,
                  DATE_TIME_FORMAT
                )}
              />
            )}
            sorter
          />
          <Column
            key={"updatedName"}
            dataIndex={"updatedName"}
            title={translate("table.update.people")}
            render={(value, { updatedUserDetail }) => (
              <TextField
                value={`${updatedUserDetail?.code} - ${updatedUserDetail?.name}`}
              />
            )}
            sorter
          />
          <Column
            key={"updatedDate"}
            dataIndex={"updatedDate"}
            title={translate("table.update.time")}
            render={(value) => (
              <TextField
                value={formatISODateTimeToView(
                  value as string,
                  DATE_TIME_FORMAT
                )}
              />
            )}
            sorter
          />
          <Column
            key={""}
            dataIndex={"actions"}
            align="center"
            fixed="right"
            width={120}
            title={translate("table.actions")}
            render={(_, record) => {
              const hiddenStatus = record.status !== ApprovalStatus.Draft && record.status !== ApprovalStatus.Returned;
              return <ButtonTooltip
                type="text"
                className="gray"
                hidden={hiddenStatus}
                onClick={(e) => { 
                  e.stopPropagation();  
                  edit(PATH.settlementContracts, record.id);            
                }}
                icon={<Icons.EditOutlined />}
              />
            }}
          />
        </Table>

      </div>
    </ListCustom>
  )
})
