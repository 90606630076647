import { useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import { ApprovalStatus } from "api/enums";
import { PATH } from "configs/path";
import { IPayment } from "interfaces";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPayment from "./components/form";

export const PaymentCreate: React.FC<IResourceComponentsProps> = (props) => {
  const { id } = useParams();
  const { form, formProps, saveButtonProps, queryResult } = useForm<IPayment>({
    resource: PATH.payments,
    id: id,
  });
  const resourcePath = PATH.payments;

  const { push, list, show } = useNavigation();
  const record: IPayment | undefined = queryResult?.data?.data;

  const isDone = record?.status === ApprovalStatus.Approved;

  useEffect(() => {
    if (isDone) {
      push("/payments/show/" + record?.id);
    }
  }, [isDone]);

  return (
    <FormPayment
      form={form}
      formProps={formProps}
      record={record}
      onCancel={() => list(resourcePath)}
      onSubmitSuccess={(id) => show(resourcePath, id || "")}
      saveButtonProps={saveButtonProps}
    />
  );
};
