import {
  Button,
  Col,
  Collapse,
  Form,
  Icons,
  Row,
  Select,
  Space,
  Table,
  Typography,
  useSelect,
} from "@pankod/refine-antd";
import { FC, memo, useCallback, useContext, useMemo, useState } from "react";
import { useTranslate } from "@pankod/refine-core";
import {
  IForm,
  SettlementFormContext,
} from "pages/projectManagement/settlements/create";
import { cloneDeep } from "lodash";
import { v4 as uuid } from "uuid";
import { API_PATH } from "configs/path";
import { isEmpty, searchSelect } from "utils/commons";
import { useFormRule } from "hooks/useFormRule";
import { DataText } from "components";
import { Currency } from "components/Currency";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import ButtonConfirm from "components/ButtonConfirm";
import styled from "styled-components";

const { Panel } = Collapse;
const { Text } = Typography;
const { useFormInstance, useWatch } = Form;

type TRow = { id: string; parentId: string; sequence: number };

const StyledWrapper = styled.div`
  margin-top: 24px;
`;

const ConstByManday: FC = memo((props) => {
  const { required } = useFormRule();

  const {
    costByMandayKeyState,
    isAllProducts,
    productSelect,
    dataForCreate,
    totalSettlementQuantityManday,
    totalSettlementValueManday,
  } = useContext(SettlementFormContext);

  const form = useFormInstance<IForm>();

  const listCostByManday = useWatch("listCostByManday", form);

  const caseId = useWatch("caseId", form);

  const translate = useTranslate();

  const [list, setList] = costByMandayKeyState;

  const [visibleCollapse, setVisibleCollapse] = useState(true);

  const {
    selectProps: rateCardSelectProps,
    queryResult: { data: rateCardData },
  } = useSelect<any>({
    resource: API_PATH.settlementRateCardDropdownlist,
    optionLabel: "position",
    optionValue: "id",
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const employeeSelect = useSelect({
    resource: API_PATH.settlementEmployeeDropdownlist,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      formatData: (r: any) => ({
        ...r,
        name: `${r?.code} - ${r?.name}`,
      }),
    },
    filters: [
      {
        field: "caseId",
        operator: "containss",
        value: caseId,
      },
    ],
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
    queryOptions: {
      enabled: !!caseId,
    },
  });

  const toggleCollapse = () => setVisibleCollapse((prev) => !prev);

  const dataTable = useMemo(() => {
    const datas: TRow[] = [];
    list.forEach((d, index) => {
      datas.push({ id: d.id, sequence: index + 1, parentId: "" });
      if (d?.children?.length) {
        d.children.forEach((c, index) => {
          datas.push({ id: c.id, parentId: d.id, sequence: index + 1 });
        });
      }
    });
    datas.push({ id: "sum", parentId: "", sequence: 0 });
    return datas;
  }, [list]);

  const onRemoveCost = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);

    setList(newList);
  };

  const onRemoveEmployee = (row: TRow) => () => {
    const newList = cloneDeep(list);
    const parentIndex = newList.findIndex((d) => row.parentId === d.id);
    const indexRemove = newList[parentIndex]?.children.findIndex(
      (c: any) => c.id === row.id
    );
    newList[parentIndex]?.children.splice(indexRemove, 1);

    setList(newList);
  };

  const onAddEmployee = (row: TRow) => () => {
    const newList = cloneDeep(list);
    const index = newList.findIndex((item) => item.id === row.id);
    newList[index].children.push({ id: uuid(), children: [] });
    setList(newList);
  };

  const isReadonly = useCallback(
    (keyRow: string) => !listCostByManday?.[keyRow]?.editable,
    [listCostByManday]
  );

  const isPolicy = useCallback(
    (keyRow: string) =>
      !isEmpty(listCostByManday?.[keyRow]?.proportion) && !isAllProducts,
    [listCostByManday, isAllProducts]
  );

  const employeeSelecteds = useCallback(
    (row: TRow) => {
      return Object.values(
        listCostByManday?.[row.parentId!]?.settlementLaborEmployees || {}
      )
        ?.map((em: any) => em?.employeeId)
        ?.filter(Boolean);
    },
    [listCostByManday]
  );

  const employeeOptions = useCallback(
    (row: TRow, value?: string) => {
      return employeeSelect?.queryResult?.data?.data?.map((o) => ({
        ...o,
        label: `${o?.code} - ${o?.name}`,
        value: o.id,
        disabled: employeeSelecteds(row)
          .filter((id) => id !== value)
          .includes(o.id),
      }));
    },
    [employeeSelecteds, employeeSelect]
  );

  const onChangeRateCard = (keyRow: string) => (value: any) => {
    const rateCardSelected = rateCardData?.data?.find((u) => u.id === value);

    const newData = cloneDeep(listCostByManday);
    newData[keyRow] = {
      ...newData[keyRow],
      internalRateCardId: rateCardSelected?.id,
      unitPrice: rateCardSelected.rateCardByDay,
    };
    form.setFieldsValue({ listCostByManday: newData });
  };

  const onChangeSettlementQuantity = (row: TRow) => (value: number) => {
    if (
      value ==
      listCostByManday[row.parentId!].settlementLaborEmployees![row.id]
        .settlementQuantity
    )
      return;
    const newData = cloneDeep(listCostByManday);
    const unitPrice = newData[row.parentId!].unitPrice;
    newData[row.parentId!].settlementLaborEmployees![row.id].settlementValue =
      isEmpty(value) || isEmpty(unitPrice) ? null : value * (unitPrice || 0);
    newData[row.parentId!].settlementLaborEmployees![
      row.id
    ].settlementQuantity = value;
    form.setFieldsValue({ listCostByManday: newData });
  };

  return (
    <StyledWrapper style={{ marginTop: 24 }} className="w-full">
      <Collapse
        className="w-full"
        defaultActiveKey={["1"]}
        ghost
        onChange={toggleCollapse}
        collapsible="header"
      >
        <Panel
          header={translate("Chi phí nhân công")}
          key="1"
          extra={
            visibleCollapse && (
              <Row align="middle">
                <Col flex={1} />
                <div style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      setList([...list, { id: uuid(), children: [] }]);
                    }}
                    icon={<Icons.PlusOutlined />}
                    style={{ marginTop: 10 }}
                  >
                    {translate("actions.create")}
                  </Button>
                </div>
              </Row>
            )
          }
        >
          <Table
            style={{ marginTop: 8 }}
            size="small"
            className="vertical-align-top w-full"
            dataSource={dataTable}
            pagination={{
              hideOnSinglePage: true,
              pageSize: 1000,
            }}
            locale={{
              emptyText: " ",
            }}
            scroll={{ x: 1000 }}
            rowKey={"id"}
          >
            <Table.Column
              title={translate("STT")}
              dataIndex="name"
              width={50}
              align="center"
              render={(v, row: TRow, index) => {
                if (row.id === "sum") return null;
                const isChildren = !!row.parentId;
                if (isChildren) return null;

                return <Text>{row.sequence}</Text>;
              }}
            />
            <Table.Column
              title={translate("Sản phẩm")}
              dataIndex="productId"
              width={200}
              render={(v, row: TRow) => {
                if (row.id === "sum") return null;
                const isChildren = !!row.parentId;
                if (isChildren) return null;
                const keyRow = row.id;
                const readonly = isReadonly(keyRow);
                return (
                  <>
                    <Form.Item
                      hidden
                      name={["listCostByManday", keyRow, "productName"]}
                    />
                    <Form.Item
                      hidden
                      name={["listCostByManday", keyRow, "refId"]}
                    />
                    <Form.Item
                      hidden
                      name={["listCostByManday", keyRow, "id"]}
                    />
                    <Form.Item
                      hidden
                      name={["listCostByManday", keyRow, "editable"]}
                      initialValue={true}
                    />
                    <Form.Item
                      required
                      className="m-0"
                      name={["listCostByManday", keyRow, "productId"]}
                      rules={!readonly && !isAllProducts ? required : []}
                    >
                      {isAllProducts ? (
                        <Text>{translate("Tất cả sản phẩm")}</Text>
                      ) : readonly ? (
                        <Text>
                          {listCostByManday?.[keyRow]?.productName || "-"}
                        </Text>
                      ) : (
                        <Select
                          {...productSelect?.selectProps}
                          placeholder={translate("Chọn sản phẩm")}
                          dropdownMatchSelectWidth={false}
                          filterOption={(inputValue: string, option: any) => {
                            return (
                              option && searchSelect(inputValue, option?.label)
                            );
                          }}
                        />
                      )}
                    </Form.Item>
                  </>
                );
              }}
            />
            <Table.Column
              title={translate("Công việc")}
              dataIndex="internalRateCardId"
              width={200}
              render={(v, row: TRow, index) => {
                if (row.id === "sum") return null;
                const isChildren = !!row.parentId;
                if (isChildren) return null;
                const keyRow = row.id;
                const readonly = isReadonly(keyRow);
                const policy = isPolicy(row.id);

                if (policy) return "-";

                return (
                  <>
                    <Form.Item
                      name={[
                        "listCostByManday",
                        keyRow,
                        "internalRateCardName",
                      ]}
                      hidden={!readonly}
                      className="m-0"
                    >
                      {listCostByManday?.[keyRow]?.internalRateCardName}
                    </Form.Item>
                    <Form.Item
                      name={["listCostByManday", keyRow, "internalRateCardId"]}
                      className="m-0"
                      rules={readonly ? undefined : required}
                      hidden={readonly}
                    >
                      <Select
                        {...rateCardSelectProps}
                        onChange={onChangeRateCard(keyRow)}
                        placeholder={translate("Chọn công việc")}
                        dropdownMatchSelectWidth={false}
                        filterOption={(inputValue: string, option: any) => {
                          return (
                            option && searchSelect(inputValue, option?.label)
                          );
                        }}
                      />
                    </Form.Item>
                  </>
                );
              }}
            />

            <Table.Column
              title={translate("Tỷ lệ")}
              dataIndex="proportion"
              width={200}
              render={(v, row: TRow, index) => {
                if (row.id === "sum") return null;
                if (isAllProducts) return "-";
                const isChildren = !!row.parentId;
                if (isChildren) return null;
                const keyRow = row.id;
                const proportion = listCostByManday?.[keyRow]?.proportion;
                const policy = isPolicy(row.id);

                return (
                  <>
                    <Form.Item
                      name={["listCostByManday", keyRow, "proportion"]}
                      hidden
                    />

                    <DataText
                      value={
                        policy ? (
                          <Currency
                            value={proportion * 100}
                            showCurrency={false}
                            after={"%"}
                          />
                        ) : (
                          "-"
                        )
                      }
                    />
                  </>
                );
              }}
            />
            <Table.Column
              title={translate("Đơn giá")}
              dataIndex="unitPrice"
              width={200}
              render={(v, row: TRow) => {
                if (row.id === "sum")
                  return (
                    <Text className="font-bold">{translate("Tổng cộng")}</Text>
                  );
                const isChildren = !!row.parentId;
                if (isChildren) return null;
                const keyRow = row.id;
                const unitPrice = listCostByManday?.[keyRow]?.unitPrice;

                return (
                  <>
                    <Form.Item
                      hidden
                      name={["listCostByManday", keyRow, "unitPrice"]}
                    />

                    <Currency value={unitPrice!} showCurrency={false} />
                  </>
                );
              }}
            />
            <Table.Column
              width={200}
              title={translate("Số lượng dự toán (manday)")}
              dataIndex="estimateQuantity"
              render={(v, row: TRow) => {
                if (row.id === "sum")
                  return (
                    <Currency
                      showCurrency={false}
                      value={dataForCreate?.totalEstimatedQuantityMandayTable}
                    />
                  );
                const isChildren = !!row.parentId;
                if (isChildren) return null;
                const keyRow = row.id;
                const policy = isPolicy(keyRow);
                if (policy || !isReadonly(row.id)) return "-";
                return (
                  <InputMoney
                    className="m-0"
                    name={["listCostByManday", keyRow, "estimateQuantity"]}
                    style={{ margin: 0 }}
                    isLimit={false}
                    readonly
                    defaulValue={listCostByManday?.[keyRow]?.estimateQuantity}
                  />
                );
              }}
            />

            <Table.Column
              title={translate("Thành tiền dự toán")}
              dataIndex="total"
              width={200}
              render={(v, row: TRow) => {
                if (row.id === "sum")
                  return (
                    <Currency
                      showCurrency={false}
                      value={dataForCreate?.totalEstimatedValueMandayTable}
                    />
                  );
                const isChildren = !!row.parentId;
                const readonly = isReadonly(row.id);
                if (isChildren) return null;
                if (!readonly) return "-";

                const money = listCostByManday?.[row.id]?.estimatedValue;
                return (
                  <Form.Item
                    name={["listCostByManday", row.id, "estimatedValue"]}
                    className="m-0"
                  >
                    <Currency value={money} showCurrency={false} />
                  </Form.Item>
                );
              }}
            />

            <Table.Column
              width={200}
              title={translate("Nhân sự")}
              dataIndex="employeeId"
              render={(v, row: TRow) => {
                if (row.id === "sum") return null;
                const isChildren = !!row.parentId;
                const keyRow = row.id;
                const policy = isPolicy(keyRow);
                if (policy) return "-";
                if (!isChildren) return null;
                return (
                  <>
                    <Form.Item
                      name={[
                        "listCostByManday",
                        row.parentId!,
                        "settlementLaborEmployees",
                        row.id,
                        "id",
                      ]}
                      hidden
                    />
                    <Form.Item
                      className="m-0"
                      name={[
                        "listCostByManday",
                        row.parentId!,
                        "settlementLaborEmployees",
                        row.id,
                        "employeeId",
                      ]}
                      rules={required}
                    >
                      <Select
                        {...employeeSelect.selectProps}
                        placeholder={translate("Chọn nhân sự")}
                        options={employeeOptions(
                          row,
                          listCostByManday?.[row.parentId!]
                            ?.settlementLaborEmployees?.[row.id]?.employeeId
                        )}
                        dropdownMatchSelectWidth={false}
                        filterOption={(inputValue: string, option: any) =>
                          option && searchSelect(inputValue, option?.label)
                        }
                      />
                    </Form.Item>
                  </>
                );
              }}
            />
            <Table.Column
              width={200}
              title={translate("Số lượng quyết toán (manday)")}
              dataIndex="settlementQuantity"
              render={(v, row: TRow) => {
                if (row.id === "sum")
                  return (
                    <Currency
                      showCurrency={false}
                      value={totalSettlementQuantityManday}
                    />
                  );
                const keyRow = row.id;
                const policy = isPolicy(keyRow);
                const isChildren = !!row.parentId;
                let totalSettlementQuantityOnRow = 0;
                if (policy) return "-";
                if (!isChildren) {
                  totalSettlementQuantityOnRow = Object.values(
                    listCostByManday?.[row.id]?.settlementLaborEmployees || {}
                  ).reduce(
                    (cur, next) =>
                      cur + (Number(next?.settlementQuantity) || 0),
                    0
                  );
                }
                return isChildren ? (
                  <InputMoney
                    name={[
                      "listCostByManday",
                      row.parentId!,
                      "settlementLaborEmployees",
                      row.id,
                      "settlementQuantity",
                    ]}
                    isLimit
                    className="m-0"
                    placeholder={translate("Nhập số liệu")}
                    onChange={onChangeSettlementQuantity(row)}
                    allowDecimal
                    decimalLength={2}
                  />
                ) : (
                  <Currency
                    value={totalSettlementQuantityOnRow}
                    showCurrency={false}
                  />
                );
              }}
            />
            <Table.Column
              width={200}
              title={translate("Thành tiền quyết toán")}
              dataIndex="settlementValue"
              render={(v, row: TRow) => {
                if (row.id === "sum")
                  return (
                    <Currency
                      showCurrency={false}
                      value={totalSettlementValueManday}
                    />
                  );
                const keyRow = row.id;
                const policy = isPolicy(keyRow);
                const isChildren = !!row.parentId;
                let totalSettlementValueOnRow = 0;

                if (policy) return "-";

                if (!isChildren) {
                  totalSettlementValueOnRow = Object.values(
                    listCostByManday?.[row.id]?.settlementLaborEmployees || {}
                  ).reduce(
                    (cur, next) => cur + (Number(next?.settlementValue) || 0),
                    0
                  );
                }
                return isChildren ? (
                  <InputMoney
                    name={[
                      "listCostByManday",
                      row.parentId!,
                      "settlementLaborEmployees",
                      row.id,
                      "settlementValue",
                    ]}
                    isLimit
                    isRequired
                    className="m-0"
                    placeholder={translate("Nhập số liệu")}
                    allowDecimal
                    decimalLength={2}
                  />
                ) : (
                  <Currency
                    value={totalSettlementValueOnRow}
                    showCurrency={false}
                  />
                );
              }}
            />
            <Table.Column
              align="center"
              fixed="right"
              width={90}
              title={translate("table.actions")}
              render={(v, row: TRow, index) => {
                if (row.id === "sum") {
                  return null;
                }
                const readonly = isReadonly(row.id);
                const isChildren = !!row.parentId;
                const policy = isPolicy(row.id);
                return (
                  <Space>
                    {!readonly && (
                      <ButtonConfirm
                        text={translate(
                          isChildren
                            ? "Bạn muốn xóa nhân viên này này?"
                            : "Bạn muốn xóa sản phẩm dịch vụ này?"
                        )}
                        danger
                        description=""
                        onClick={
                          isChildren
                            ? onRemoveEmployee(row)
                            : onRemoveCost(row.sequence - 1)
                        }
                        type="text"
                        icon={<Icons.DeleteOutlined />}
                      />
                    )}
                    {!isChildren && !policy && (
                      <Button
                        type="text"
                        icon={<Icons.PlusSquareOutlined />}
                        onClick={onAddEmployee(row)}
                        hidden={isChildren || policy}
                      />
                    )}
                  </Space>
                );
              }}
            />
          </Table>
        </Panel>
      </Collapse>
    </StyledWrapper>
  );
});

export default ConstByManday;
