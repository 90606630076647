import { Form, useForm, notification } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { ApprovalStatus } from "api/enums";
import { CreateCustom } from "components/layout";
import { PATH } from "configs/path";
import { IPrepay } from "interfaces";
import React, { useState, useEffect, useMemo } from "react";
import {
  formatOnlyDateToUTC,
  mappingErrorFromApi,
  validateFieldsRequied,
} from "utils/commons";
import { useParams } from "react-router-dom";
import PrepayForm from "./components/form";
import { useNumToWord } from "hooks/useNumToWord";

export const PrepayEdit: React.FC<IResourceComponentsProps> = (props) => {
  const { id } = useParams();

  const resourcePath = PATH.prepays;
  const { form, formProps, saveButtonProps, queryResult } = useForm<IPrepay>({
    resource: PATH.prepays,
    id: id,
  });
  const t = useTranslate();
  const { show } = useNavigation();

  const [visibleShowModal, setVisibleShowModal] = useState<boolean>();
  const { mutate: mutateUpdate, isLoading } = useUpdate<any>();

  const record = queryResult?.data?.data;

  const costObj = Form.useWatch("listCosts", form) || {};
  const currency = Form.useWatch("currencyType", form);

  const listCosts = useMemo(
    () => Object.keys(costObj)?.map((k) => costObj[k]),
    [costObj]
  );

  const total = useMemo(
    () => listCosts?.reduce((a: any, b: any) => a + +(b.money || 0), 0),
    [listCosts]
  );

  const { text } = useNumToWord({
    currency,
    num: total,
  });

  const onSubmit = (isDraft = false) => {
    const listCosts = Object.keys(costObj).map((k) => costObj[k]);
    const valueForm: IPrepay = form.getFieldsValue() as unknown as IPrepay;

    const dataSend: any = {
      resource: PATH.prepays,
      metaData: isDraft
        ? {
            type: "draft",
          }
        : {},

      values: {
        ...valueForm,
        totalAmountWord: text,
        name: form.getFieldValue("name")?.trim(),
        reason: form.getFieldValue("reason")?.trim(),
        attachments: form.getFieldValue("attachments") || [],
        bankName: form.getFieldValue("bankName")?.trim(),
        bankAccountName: form.getFieldValue("bankAccountName")?.trim(),
        bankAccountNumber: form.getFieldValue("bankAccountNumber")?.trim(),
        listCosts: listCosts.map((a, idx) => ({ ...a, ordinal: idx })),
        estimatedPaymentDate: form.getFieldValue("estimatedPaymentDate")
          ? formatOnlyDateToUTC(form.getFieldValue("estimatedPaymentDate"))
          : null,
      },
    };

    dataSend.id = record?.id;
    mutateUpdate(dataSend, {
      onSuccess: () => {
        setVisibleShowModal(false);
        notification.success({
          message: isDraft
            ? t("common.saveDraftSuccess")
            : t("common.sentSuccess"),
        });
        show(resourcePath, record?.id!);
      },
      onError: (error: any) => {
        setVisibleShowModal(false);
        mappingErrorFromApi(error, form, {
          ER047: "name",
          ER046: "code",
        });
      },
    });
  };

  const onDraft = () => {
    onSubmit(true);
  };

  const isDone = record?.status === ApprovalStatus.Approved;

  React.useEffect(() => {
    if (isDone) {
      show(resourcePath, record?.id!);
    }
  }, [isDone]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        caseId: record?.case?.id,
        status: record?.status,
      });
    }
  }, [record]);

  return (
    <>
      <CreateCustom
        {...props}
        title={t("prepays.titles.edit")}
        saveButtonProps={{
          ...saveButtonProps,
          title: t("prepays.actions.sendRequest"),
          hidden: ![ApprovalStatus.Draft, ApprovalStatus.Returned].includes(
            form.getFieldValue("status")
          ),
        }}
        visibleShowModal={visibleShowModal}
        setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
        onSubmit={onSubmit}
        onDraft={onDraft}
        confirmModalProps={{
          title: t("prepays.message.confirmSend"),
        }}
        draftButtonProps={{
          title: t("common.saveEdit"),
          type: "primary",
          hidden: ![ApprovalStatus.Draft, ApprovalStatus.Returned].includes(
            form.getFieldValue("status")
          ),
        }}
        cancelModalProps={{
          title: t("prepays.message.confirmCancelEdit"),
          okButtonProps: { danger: true },
          cancelButtonProps: {},
        }}
        draftModalProps={{
          title: t("prepays.message.confirmSaveEdit"),
        }}
        validateDraft={() =>
          validateFieldsRequied(["name"], form, t("errors.ER005"))
        }
        saving={isLoading}
      >
        <PrepayForm
          form={form}
          formProps={formProps}
          onFinishForm={() => setVisibleShowModal(true)}
          record={record}
        />
      </CreateCustom>
    </>
  );
};
