import { Button, Card, Col, Descriptions, Icons, Modal, Row, notification } from "@pankod/refine-antd";
import { useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { CurrencyType } from "api/enums";
import { DataText, SelectCustom } from "components";
import { Currency } from "components/Currency";
import { IntegrationStatusTag } from "components/StatusTag/IntegrationStatusTag";
import { DATE_FORMAT } from "configs/constants";
import { API_PATH } from "configs/path";
import ActionBusinessPlan, { groupById } from "pages/workId/components/actionBusinessPlan";
import BusinessOpportunities from "pages/workId/components/businessOpportunities";
import { FC, memo, useCallback, useContext, useEffect, useState } from "react";
import { formatISODateTimeToView } from "utils/commons";
import { BusinessWorkIdContext } from "../../context";




const CaseIdInfo: FC = memo((props) => {
  const translate = useTranslate();
  const { caseId } = useContext(BusinessWorkIdContext);
  const { customer, marketArea, ownerEmployee, ownerDepartment } = caseId || {};
  const [employee, setEmployee] = useState(ownerEmployee);

  const { data } = useOne({
    resource: API_PATH.caseId,
    id: "",
    metaData: {
      "after": `/${caseId?.id}/objects-group-by-feature`,
    },
    queryOptions: {
      enabled: !!caseId?.id
    }
  })

  const { data: responseGroupGeneral } = useOne({
    resource: `${API_PATH.cases}/${caseId?.id}/objects-group-general-by-feature`,
    id: "",
    queryOptions: {
      enabled: !!caseId?.id
    }
  })

  const dataGroupGeneral = groupById(responseGroupGeneral?.data);

  const renderActionBusinessPlanGenneral = useCallback((key: string) => (
    <ActionBusinessPlan key={key} {...dataGroupGeneral[key]} />
  ), [dataGroupGeneral]);

  const [isEditUser, setIsEditUser] = useState(false);
  const { mutate } = useUpdate()
  const [accountManager, setAccountManager] = useState<string>("")

  const updateManager = (value: string) => {
    const [id, code, name] = value.split("_");

    mutate({
      resource: `${API_PATH.caseId}/${caseId?.id}/change-account-manager`,
      id: "",
      values: {
        employeeId: id
      },

    }, {
      onSuccess: () => {
        notification.success({
          message: translate("employees.changeBusiness.success"),
        })
        setEmployee({
          id,
          code,
          name
        })
      },
      onError: (err) => {
        notification.error({
          message: translate("common.error"),
          description: err?.message
        })
      }
    },)
  }

  const groupData = groupById(data?.data);
  const resourceDroplist = `${API_PATH.caseId}/${caseId?.id}/account-manager/dropdownlist?oldEmployeeId=${employee?.id}`;
  const handleSelectChange = (value: string) => setAccountManager(value);
  const formatData = useCallback((r: any) => ({
    id: `${r.id}_${r.code}_${r.name}`,
    label: `${r.code} - ${r.name}`,
  }), []);

  useEffect(() => {
    setEmployee(ownerEmployee)
  }, [ownerEmployee]);

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
      <Col xs={24} lg={16} >
        <Card>
          <Descriptions
            title={translate("caseId.businessCaseDetail")}
            column={2}
            layout="vertical"
          >
            <Descriptions.Item
              label={translate("caseId.caseId")}
              labelStyle={{ fontWeight: 500 }}
              span={2}
            >
              <DataText value={caseId?.code} />
            </Descriptions.Item>
            <Descriptions.Item
              label={translate("customer.status")}
              labelStyle={{ fontWeight: 500 }}
            >
              <DataText value={caseId?.status?.name} />
            </Descriptions.Item>
            <Descriptions.Item
              label={translate("customer.caseValue")}
              labelStyle={{ fontWeight: 500 }}
            >
              <Currency
                value={caseId?.caseValue}
                currencyType={CurrencyType.VND}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={translate("customer.customer")}
              labelStyle={{ fontWeight: 500 }}
            >
              <DataText value={`${customer?.code} - ${customer?.name}`} />
            </Descriptions.Item>
            <Descriptions.Item
              label={translate("businessOpportunities.market")}
              labelStyle={{ fontWeight: 500 }}
            >
              <DataText value={marketArea?.name} />
            </Descriptions.Item>
            <Descriptions.Item
              label={translate("caseId.businessStaff")}
              labelStyle={{ fontWeight: 500 }}
            >
              <div>
                <DataText
                  value={`${employee?.code} - ${employee?.name}`}
                />
                <Button type="text"
                  icon={isEditUser ? <Icons.CloseCircleOutlined /> : <Icons.EditOutlined />} onClick={() => {
                    setIsEditUser(!isEditUser)
                  }} />
              </div>
            </Descriptions.Item>
            <Descriptions.Item
              label={translate("caseId.department")}
              labelStyle={{ fontWeight: 500 }}
            >
              <DataText value={ownerDepartment?.name} />
            </Descriptions.Item>
            <Descriptions.Item
              label={translate("common.createdDate")}
              labelStyle={{ fontWeight: 500 }}
            >
              <DataText
                value={formatISODateTimeToView(caseId?.createdDate, DATE_FORMAT)}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={translate("common.createdName")}
              labelStyle={{ fontWeight: 500 }}
            >
              <DataText value={caseId?.createdName} />
            </Descriptions.Item>
            <Descriptions.Item
              label={translate("integration.syncTime")}
              labelStyle={{ fontWeight: 500 }}
            >
              <DataText
                value={formatISODateTimeToView(caseId?.syncTime, DATE_FORMAT)}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={translate("integration.syncStatus")}
              labelStyle={{ fontWeight: 500 }}
            >
              <IntegrationStatusTag status={caseId?.syncStatus} />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={translate("cases.fields.name")}
              span={3}
            >
              <DataText value={caseId?.description} />
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>

      <Col xs={24} lg={8} >
        <Card>
          <Descriptions title={translate("common.business.action")} column={1}  >
            <Descriptions.Item labelStyle={{ display: "block" }} contentStyle={{ display: "block" }}>
              <>
                <BusinessOpportunities key={caseId?.id} {...caseId} />
                {Array.isArray(data?.data) && Object.keys(groupData).map((key: string, index: number) => {
                  return (
                    <ActionBusinessPlan key={key} {...groupData[key]} />
                  )
                })}
              </>
            </Descriptions.Item>
          </Descriptions>
          <div style={{ backgroundColor: "#f0f0f0", height: "2px", width: "100%", marginBottom: "15px"  }} />
          <Descriptions
            title={translate("common.activities")}
            column={1}
          >
            {
              <Descriptions.Item labelStyle={{ display: "block" }} contentStyle={{ display: "block" }}>
                {(responseGroupGeneral?.data?.length ?? 0) > 0
                  ? Object.keys(dataGroupGeneral).map(renderActionBusinessPlanGenneral)
                  : <p>{translate("common.noActivities")}</p>
                }
              </Descriptions.Item>
            }
          </Descriptions>
        </Card>
      </Col>
      <Modal
        title={translate("employees.changeMVV")}
        visible={isEditUser}
        okText={translate("buttons.confirm")}
        cancelText={translate("buttons.cancel")}
        onOk={() => {
          updateManager(accountManager)
          setIsEditUser(false)
        }}
        onCancel={() => {
          setIsEditUser(false)
        }}>
        <b>{translate("employees.business")}</b>
        <SelectCustom
          resource={resourceDroplist}
          formatData={formatData}
          placeholder={translate("employees.selectBusiness")}
          onChange={handleSelectChange}
          style={{ width: "100%" }}
          optionLabel="label" />
      </Modal>
    </Row>
  );
});

export default CaseIdInfo;
