import { Icons, Select, useSelect } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { Button, Col, Form, Row, Table, Typography } from "antd";
import { DataText } from "components";
import ButtonConfirm from "components/ButtonConfirm";
import { API_PATH } from "configs/path";
import { cloneDeep } from "lodash";
import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import { IForm, ProjectContext } from "../..";
import { useFormRule } from "hooks/useFormRule";
import { ProjectMember } from "interfaces/Project";
import { searchSelect } from "utils/commons";
import Employee from "interfaces/Employee";
const { Title } = Typography;

interface Props {
  formName: string;
  keyDataResult: string;
  label: string;
  endpointGetRole: string;
}

const { useWatch, useFormInstance } = Form;
const TableDepartment: FC<Props> = memo((props) => {
  const { initialValues, isEdit } = useContext(ProjectContext);
  const { required } = useFormRule();
  const form = useFormInstance<IForm>();
  const { formName, label, endpointGetRole, keyDataResult } = props;
  const translate = useTranslate();
  const [list, setList] = useState<string[]>([]);

  const { selectProps: employeeSelectProps, queryResult: employeeResult } =
    useSelect<Employee>({
      resource: API_PATH.projectEmployeeDropdownlist,
      optionLabel: "fullName",
      optionValue: "id",
      onSearch: () => [
        {
          field: "q",
          operator: "eq",
          value: undefined,
        },
      ],
    });

  const { selectProps: roleSelectProps, queryResult: roleResult } = useSelect({
    resource: endpointGetRole,
    optionLabel: "name",
    optionValue: "id",
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const dataTableForm = useWatch(formName, form);

  const employeeSelecteds = useMemo(
    () =>
      dataTableForm
        ? Object.values(dataTableForm).map((product: any) => product.employeeId)
        : [],
    [dataTableForm]
  );

  const employeeOptions = useCallback(
    (value?: string) =>
      employeeResult?.data?.data?.map((o) => ({
        ...o,
        label: o.fullName,
        value: o.id,
        disabled: employeeSelecteds.filter((id) => id !== value).includes(o.id),
      })),
    [employeeSelecteds, employeeResult]
  );

  const employees = employeeResult?.data?.data || [];

  const loadingEmlopyee = employeeResult.isLoading;
  const loadingRole = roleResult?.isLoading;

  const onRemoveItem = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };
  const onChageEmployee = (keyRow: string) => (value: any, __: any) => {
    const selectedItem = employees.find((e) => e.id === value);
    const newData = cloneDeep(dataTableForm);
    newData[keyRow] = {
      ...newData?.[keyRow],
      employeeId: value,
      title: selectedItem?.title?.name,
      departmentName: selectedItem?.department?.name,
    };
    form.setFieldsValue({ [formName]: newData });
  };

  useEffect(() => {
    if (isEdit && !!initialValues) {
      const ids: string[] = [];
      const data: any = {};
      initialValues?.[keyDataResult]?.forEach((d: ProjectMember) => {
        const id = uuid();
        ids.push(id);
        data[id] = {
          employeeId: d?.employeeId,
          title: d?.title?.name,
          departmentName: d?.department?.name,
          projectRoleMasterDataId: d?.projectRole?.id,
        };
      });

      setList(ids);
      setTimeout(() => form.setFieldsValue({ [formName]: data }), 0);
    }
  }, [isEdit, initialValues, formName]);

  return (
    <>
      <Row style={{ marginBottom: 16 }} align="middle">
        <Col flex={1}>
          <Title level={4} style={{ fontSize: 18 }}>
            {label}
          </Title>
        </Col>
        <Button
          type="primary"
          onClick={() => {
            setList([...list, uuid()]);
          }}
          icon={<Icons.PlusOutlined />}
        >
          {translate("actions.create")}
        </Button>
      </Row>

      <Table<String>
        size="small"
        className="vertical-align-top"
        dataSource={list}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 1000,
        }}
        locale={{
          emptyText: " ",
        }}
      >
        <Table.Column
          width={50}
          align="center"
          title={translate("STT")}
          dataIndex="id"
          render={(_, __, index) => index + 1}
        />
        <Table.Column
          title={translate("Nhân viên")}
          dataIndex="employee"
          width={200}
          render={(v, keyRow: string) => {
            return (
              <Form.Item
                name={[formName, keyRow, "employeeId"]}
                className="m-0"
              >
                <Select
                  {...employeeSelectProps}
                  onChange={onChageEmployee(keyRow)}
                  placeholder={translate("Chọn nhân viên")}
                  options={employeeOptions(dataTableForm?.[keyRow]?.employeeId)}
                  filterOption={(inputValue: string, option: any) =>
                    option && searchSelect(inputValue, option?.label)
                  }
                  loading={loadingEmlopyee}
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("Phòng ban")}
          dataIndex="department"
          width={200}
          render={(v, keyRow: string) => (
            <>
              <Form.Item
                name={[formName, keyRow, "departmentName"]}
                className="m-0"
              >
                <DataText value={dataTableForm?.[keyRow]?.departmentName} />
              </Form.Item>
            </>
          )}
        />
        <Table.Column
          title={translate("Chức vụ")}
          dataIndex="title"
          width={200}
          render={(v, keyRow: string) => (
            <Form.Item name={[formName, keyRow, "title"]} className="m-0">
              <DataText value={dataTableForm?.[keyRow]?.title} />
            </Form.Item>
          )}
        />
        <Table.Column
          title={translate("Vai trò")}
          dataIndex="role"
          width={200}
          render={(v, keyRow: string) => {
            return (
              <>
                <Form.Item
                  style={{ margin: 0 }}
                  name={[formName, keyRow, "projectRoleMasterDataId"]}
                  className="m-0"
                  rules={required}
                >
                  <Select
                    {...roleSelectProps}
                    placeholder={translate("Chọn vai trò")}
                    filterOption={(inputValue: string, option: any) =>
                      option && searchSelect(inputValue, option?.label)
                    }
                    loading={loadingRole}
                  />
                </Form.Item>
              </>
            );
          }}
        />
        <Table.Column
          align="center"
          width={120}
          title={translate("table.actions")}
          fixed="right"
          render={(v, record, index) => {
            return (
              <ButtonConfirm
                text={translate("Bạn muốn xóa nhân viên này?")}
                description=""
                onClick={onRemoveItem(index)}
                type="text"
                danger
              >
                <Icons.DeleteOutlined />
              </ButtonConfirm>
            );
          }}
        />
      </Table>
    </>
  );
});

export const ConsultDepartment = () => {
  const translate = useTranslate();
  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <TableDepartment
        formName="listProjectMemberTypeConsulting"
        label={translate("Ban dự án tư vấn")}
        endpointGetRole={API_PATH.projectRoleConsultingDropdownlist}
        keyDataResult="projectMemberTypeConsulting"
      />
    </div>
  );
};

export const DeployingDepartment = () => {
  const translate = useTranslate();
  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <TableDepartment
        formName="listProjectMemberTypeImplementation"
        label={translate("Ban dự án triển khai")}
        endpointGetRole={API_PATH.projectRoleDeployingDropdownlist}
        keyDataResult="projectMemberTypeImplementation"
      />
    </div>
  );
};
