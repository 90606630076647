import React from "react";
import { Radio, Select, Space } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useTranslate,
  useTable,
} from "@pankod/refine-core";
import { SearchBox } from "components";
import { IPrepay } from "interfaces";
import { ApprovalStatus, RefundAdvancePaymentStatus } from "api/enums";
import { PrepayTable } from "../table";
import { useSearchParams } from "react-router-dom";
import { PATH } from "configs/path";
import { WORKID_TYPE_OPTIONS } from "configs/constants";

export const PrepayRequest: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get("code") || "";

  const [prepayCode, setPrePayCode] = React.useState<string>(codeParam);
  const [isSearch, setIsSearch] = React.useState<boolean>(false);

  const {
    tableQueryResult: { data: dataTable, isLoading, isRefetching },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    filters,
    setFilters,
    sorter,
    setSorter,
  } = useTable<IPrepay>({
    initialCurrent: 1,
    resource: PATH.prepays,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  // Read the active tab, payment code from url params
  React.useEffect(() => {
    if (codeParam) {
      setSearchFilter(codeParam);
    }
  }, [codeParam]);

  React.useEffect(() => {
    setCurrent(1);
    setIsSearch(filters && filters.length > 0);
  }, [filters]);

  const onSearch = (value: string) => {
    setCurrent(1);
    setIsSearch(value ? true : false);
    setSearchFilter(value);
  };

  const setSearchFilter = (value: any) => {
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };

  return (
    <div className="list-content">
      <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
        <SearchBox
          placeholder={t("prepays.fields.searchPlaceHolder")}
          onSearch={onSearch}
          style={{ width: 304 }}
          value={prepayCode}
          onChange={(value) => {
            setPrePayCode(value.target.value);
          }}
        />
        <Select
          allowClear
          className={"placeholderBlack"}
          placeholder={t("prepays.refundStatus")}
          style={{ width: 200, marginBottom: 16 }}
          bordered={false}
          options={[
            {
              value: RefundAdvancePaymentStatus.Paid,
              label: t("prepays.refundStatuses.paid"),
            },
            {
              value: RefundAdvancePaymentStatus.Unpaid,
              label: t("prepays.refundStatuses.unpaid"),
            },
          ]}
          onChange={(value, _) => {
            setFilters([
              {
                field: "refundAdvancePaymentStatus",
                operator: "eq",
                value: value,
              },
            ]);
          }}
        />
        <Select
          options={WORKID_TYPE_OPTIONS.map((op) => ({
            ...op,
            label: t(op.label),
          }))}
          allowClear
          style={{ width: 200, marginBottom: 16 }}
          bordered={false}
          placeholder={t("prepays.fields.caseCode")}
          onChange={(e) => {
            setFilters([
              {
                field: "caseType",
                operator: "eq",
                value: e,
              },
            ]);
          }}
        />
      </div>
      <Radio.Group
        defaultValue={-1}
        buttonStyle="solid"
        onChange={(e) => {
          setFilters([
            {
              field: "status",
              operator: "eq",
              value: e?.target?.value === -1 ? "" : e?.target?.value,
            },
          ]);
        }}
        style={{ display: "block", marginBottom: 16 }}
      >
        <Radio.Button value={-1}>{t("common.all")}</Radio.Button>
        <Radio.Button value={ApprovalStatus.Waiting}>
          {t("common.status.waiting")}
        </Radio.Button>
        <Radio.Button value={ApprovalStatus.Approving}>
          {t("common.status.approving")}
        </Radio.Button>
        <Radio.Button value={ApprovalStatus.Approved}>
          {t("common.status.approved")}
        </Radio.Button>
        <Radio.Button value={ApprovalStatus.Returned}>
          {t("common.status.returned")}
        </Radio.Button>
        <Radio.Button value={ApprovalStatus.Draft}>
          {t("common.status.draft")}
        </Radio.Button>
        <Radio.Button value={ApprovalStatus.Rejected}>
          {t("common.status.reject")}
        </Radio.Button>
        <Radio.Button value={ApprovalStatus.Canceled}>
          {t("common.status.cancel")}
        </Radio.Button>
      </Radio.Group>
      <PrepayTable
        isLoading={isLoading}
        isRefetching={isRefetching}
        dataTable={dataTable}
        isSearch={isSearch}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setCurrent={setCurrent}
        current={current}
        sorter={sorter}
        setSorter={setSorter}
        viewTab={"show"}
      />
    </div>
  );
};
