import {
  Button,
  Col,
  DatePicker,
  Form,
  Icons,
  List,
  Popover,
  Row,
  Select,
  Table,
  Tag,
  TextField,
  Tooltip,
  Typography,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { CurrencyType } from "api/enums";
import { EmptyData } from "components";
import ButtonConfirm from "components/ButtonConfirm";
import CTableColumn from "components/CTableColumn";
import { Currency, generateCurrency } from "components/Currency";
import { DATE_FORMAT, DEFAULT_KEY } from "configs/constants";
import { uniqBy } from "lodash";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import { memo } from "react";
import {
  formatISODateTimeToView,
  formatNumberTo,
  formatOnlyDateToUTC,
  getSequent,
  searchSelect,
  subString,
} from "utils/commons";
import { useController } from "./controller";

const { Text } = Typography;

const { RangePicker } = DatePicker;

const Column = CTableColumn<any>();

const SumCard = memo(
  ({
    data = 0,
    label = "",
    minWidth = 180,
  }: {
    data?: number;
    label: string;
    minWidth?: number;
  }) => {
    const { locateCode } = generateCurrency(CurrencyType.VND);
    const formatedMoneyStr = `${new Intl.NumberFormat(locateCode).format(
      data || 0
    )}`;
    return (
      <div
        style={{
          flex: 1,
          padding: 16,
          borderRadius: 8,
          border: "1px solid #d9d9d9",
          whiteSpace: "nowrap",
          minWidth,
        }}
      >
        <div>{label}</div>
        <Tooltip arrowPointAtCenter title={formatedMoneyStr}>
          <strong style={{ fontSize: 28 }}>{formatNumberTo(data)}</strong>
        </Tooltip>
      </div>
    );
  }
);

const SalePipelineList = () => {
  const {
    visibleModalExport,
    formProps,
    businessOpportunityStatusProps,
    marketSelectProps,
    SBAGroupSelectProps,
    employeeSelectProps,
    dataForm: { revenueEstFrom, date },
    saveButtonProps,
    openSearch,
    currency,
    dataSalePipeline,
    table: {
      pageSize,
      current,
      sorter,
      tableQueryResult: { fetchStatus, data },
      setCurrent,
      setPageSize,
      setFilters,
      setSorter,
    },
    hiddenButtonClearFilter,
    renderStatus,
    translate,
    toggleModalExport,
    onExport,
    onFilter,
    onClearFilter,
    setOpenSearch,
  } = useController();

  return (
    <List
      title={translate("Báo cáo Sale Pipeline")}
      headerButtons={
        <ButtonConfirm
          type="primary"
          icon={<Icons.DownloadOutlined />}
          danger={false}
          noCancel={false}
          visible={visibleModalExport}
          toggle={toggleModalExport}
          onClick={onExport}
          customContent={<div>Bạn muốn xuất dữ liệu?</div>}
        >
          {translate("Xuất File")}
        </ButtonConfirm>
      }
      breadcrumb={null}
    >
      <div className="list-content">
        <div className="flex items-cemter justify-between">
          <Form
            {...formProps}
            initialValues={{
              employeeId: DEFAULT_KEY,
              status: DEFAULT_KEY,
              SBAGroupId: DEFAULT_KEY,
              marketArea: DEFAULT_KEY,
            }}
            onFinish={onFilter}
            layout="inline"
          >
            <Form.Item name="date">
              <RangePicker
                style={{ width: 300 }}
                placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                onChange={(date) => {
                  setFilters([
                    {
                      field: "startDate",
                      operator: "eq",
                      value: date
                        ? formatOnlyDateToUTC(date?.[0]?.toDate())
                        : undefined,
                    },
                    {
                      field: "endDate",
                      operator: "eq",
                      value: date
                        ? formatOnlyDateToUTC(date?.[1]?.toDate())
                        : undefined,
                    },
                  ]);
                }}
                allowClear={false}
              />
            </Form.Item>

            <Popover
              content={
                <div style={{ padding: 16 }}>
                  <Row style={{ gap: 16 }}>
                    <Form.Item
                      name="status"
                      label="Trạng thái CHKD"
                      labelAlign="left"
                      style={{
                        marginBottom: 16,
                        flexDirection: "column",
                      }}
                    >
                      <Select
                        {...businessOpportunityStatusProps}
                        options={[
                          {
                            label: "Tất cả",
                            value: DEFAULT_KEY,
                          },
                          ...(businessOpportunityStatusProps.options || []),
                        ]}
                        allowClear={false}
                        style={{ width: 300 }}
                        className={"placeholderBlack"}
                        dropdownStyle={{ color: "black" }}
                        placeholder="Trạng thái CHKD"
                      />
                    </Form.Item>
                    <Form.Item
                      style={{
                        marginBottom: 16,
                        flexDirection: "column",
                      }}
                      label="Thị trường"
                      name="marketArea"
                      labelAlign="left"
                    >
                      <Select
                        {...marketSelectProps}
                        options={[
                          {
                            label: "Tất cả",
                            value: DEFAULT_KEY,
                          },
                          ...(marketSelectProps.options || []),
                        ]}
                        filterOption={(inputValue: string, option: any) =>
                          option && searchSelect(inputValue, option?.label)
                        }
                        allowClear={false}
                        style={{ width: 300 }}
                        className={"placeholderBlack"}
                        dropdownStyle={{ color: "black" }}
                      />
                    </Form.Item>
                  </Row>

                  <Row style={{ gap: 16 }}>
                    <Form.Item
                      style={{
                        marginBottom: 16,
                        flexDirection: "column",
                      }}
                      labelAlign="left"
                      label="Nhóm SBA"
                      name="SBAGroupId"
                    >
                      <Select
                        {...SBAGroupSelectProps}
                        options={[
                          {
                            label: "Tất cả",
                            value: DEFAULT_KEY,
                          },
                          ...(SBAGroupSelectProps.options || []),
                        ]}
                        filterOption={(inputValue: string, option: any) =>
                          option && searchSelect(inputValue, option?.label)
                        }
                        allowClear={false}
                        style={{ width: 300 }}
                        className={"placeholderBlack"}
                        dropdownStyle={{ color: "black" }}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{
                        marginBottom: 16,
                        flexDirection: "column",
                      }}
                      labelAlign="left"
                      label="Nhân viên"
                      name="employeeId"
                    >
                      <Select
                        {...employeeSelectProps}
                        options={[
                          {
                            label: "Tất cả",
                            value: DEFAULT_KEY,
                          },
                          ...(employeeSelectProps.options || []),
                        ]}
                        allowClear={false}
                        style={{ width: 300 }}
                        className={"placeholderBlack"}
                        dropdownStyle={{ color: "black" }}
                        filterOption={(inputValue: string, option: any) =>
                          option && searchSelect(inputValue, option?.label)
                        }
                      />
                    </Form.Item>
                  </Row>

                  <div style={{ marginBottom: 8 }}>
                    Giá trị hợp đồng ước tính:
                  </div>
                  <Row style={{ gap: 16 }} className="flex">
                    <InputMoney
                      style={{ width: 300, flexDirection: "column" }}
                      label={
                        <span>
                          {translate("Từ")}{" "}
                          <span style={{ color: generateCurrency().unitColor }}>
                            (VND)
                          </span>
                        </span>
                      }
                      isLimit={false}
                      name="revenueEstFrom"
                      labelAlign="left"
                    />
                    <InputMoney
                      style={{ width: 300, flexDirection: "column" }}
                      label={
                        <span>
                          {translate("Đến")}{" "}
                          <span style={{ color: generateCurrency().unitColor }}>
                            (VND)
                          </span>
                        </span>
                      }
                      isLimit={false}
                      name="revenueEstTo"
                      labelAlign="left"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              Number(value) < Number(revenueEstFrom)
                            ) {
                              return Promise.reject(
                                new Error(
                                  translate(
                                    "Giá trị Đến phải lớn hơn hoặc bằng giá trị Từ"
                                  )
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    />
                  </Row>
                  <Row justify="end" gutter={[16, 16]}>
                    <Col>
                      <Button
                        style={{ marginBottom: 16 }}
                        onClick={onClearFilter}
                        hidden={hiddenButtonClearFilter}
                      >
                        Xóa bộ lọc
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        style={{ marginBottom: 16 }}
                        type="primary"
                        {...saveButtonProps}
                      >
                        Tìm kiếm
                      </Button>
                    </Col>
                  </Row>
                </div>
              }
              title="Tìm kiếm nâng cao"
              trigger="click"
              visible={openSearch}
              onVisibleChange={() => setOpenSearch((prev) => !prev)}
              placement="rightBottom"
            >
              <Button
                style={{ marginBottom: 16 }}
                icon={<Icons.FilterOutlined />}
              >
                Bộ lọc
              </Button>
            </Popover>
          </Form>

          <Text>
            Đơn vị tiền tệ:{" "}
            <Text style={{ color: currency.unitColor, fontWeight: "bold" }}>
              {currency.unit}
            </Text>
          </Text>
        </div>

        <div
          style={{ marginBottom: 16, gap: 16, overflow: "auto" }}
          className="flex items-center"
        >
          <SumCard
            data={dataSalePipeline?.totalCase}
            label="Tổng MVV"
            minWidth={150}
          />
          <SumCard
            data={dataSalePipeline?.totalCustomer}
            label="Tổng khách hàng"
            minWidth={150}
          />
          <SumCard
            data={dataSalePipeline?.totalRevenueEstimation}
            label="Doanh số dự kiến"
          />
          <SumCard
            data={dataSalePipeline?.totalProfitEstimation}
            label="Lợi nhuận gộp dự kiến"
          />{" "}
          <SumCard
            data={dataSalePipeline?.totalBudgetEstimation}
            label="Ngân sách dự kiến"
          />
        </div>
        <Table
          sticky
          loading={fetchStatus === "fetching" && !!date}
          dataSource={!date ? [] : dataSalePipeline.list}
          rowKey="id"
          locale={{
            emptyText: (
              <EmptyData
                text={translate(
                  date
                    ? "common.noDataSearch"
                    : "Vui lòng chọn Ngày bắt đầu và Ngày kết thúc để xem báo cáo"
                )}
              />
            ),
          }}
          pagination={{
            pageSize: pageSize,
            current: current,
            total: data?.total,
            showSizeChanger: true,
            onChange: (cur, size) => {
              setCurrent(cur);
              setPageSize(size);
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0;
            },
            locale: {
              items_per_page: translate("common.pageNumber"),
            },
            showTotal: (total) => (
              <p>{translate("common.totalPage", { total: total })}</p>
            ),
          }}
          onChange={(_, __, s) => {
            const newSort = s as any;
            setSorter([
              {
                field: newSort?.field,
                order: newSort?.order,
              },
            ]);
          }}
          scroll={{ x: "2200px" }}
          showSorterTooltip={false}
        >
          <Column
            onCell={(data) => {
              return {
                className: data.isSum ? "ant-table-cell-row-hover" : "",
              };
            }}
            fixed="left"
            width={70}
            key="stt"
            dataIndex="stt"
            title={translate("STT")}
            render={(value, record, index) => (
              <TextField
                value={
                  record?.isSum
                    ? "Tổng"
                    : getSequent({ index, pageIndex: current, pageSize })
                }
              />
            )}
          />
          <Column
            fixed="left"
            key="case"
            dataIndex="case"
            title={translate("MVV")}
            width={200}
            render={(value, record) => (
              <TextField value={value?.code || value} />
            )}
            defaultSortOrder={getDefaultSortOrder("case", sorter)}
            sorter
            onCell={(data) => {
              return {
                className: data.isSum ? "ant-table-cell-row-hover" : "",
              };
            }}
          />
          <Column
            key="customer"
            dataIndex="customer"
            title={translate("Tên khách hàng")}
            width={200}
            render={(value, record) => (
              <TextField
                value={record.isSum ? value : value?.name && `${value?.name}`}
              />
            )}
            defaultSortOrder={getDefaultSortOrder("customer", sorter)}
            sorter
            onCell={(data) => {
              return {
                className: data.isSum ? "ant-table-cell-row-hover" : "",
              };
            }}
          />
          <Column
            width={200}
            key="sbaGroup"
            dataIndex="sbaGroup"
            title={translate("Nhóm SBA")}
            render={(value, record) => (
              <div
                style={{ display: "flex", flexDirection: "column", rowGap: 8 }}
              >
                {uniqBy(record?.sbaGroup, "id")?.map((sba: any) => (
                  <div>
                    <Tag color="default">
                      {subString({ str: sba?.name, to: 25 })}
                    </Tag>
                  </div>
                ))}
              </div>
            )}
            onCell={(data) => {
              return {
                className: data.isSum ? "ant-table-cell-row-hover" : "",
              };
            }}
          />

          <Column
            key="revenueEstimation"
            dataIndex="revenueEstimation"
            width={200}
            title={translate("Giá trị hợp đồng ước tính")}
            render={(value, record) => (
              <Currency value={value} showCurrency={false} />
            )}
            defaultSortOrder={getDefaultSortOrder("revenueEstimation", sorter)}
            sorter
            align="right"
            onCell={(data) => {
              return {
                className: data.isSum ? "ant-table-cell-row-hover" : "",
              };
            }}
          />
          <Column
            key="profitEstimation"
            dataIndex="profitEstimation"
            width={200}
            title={translate("Lợi nhuận gộp")}
            render={(value, record) => (
              <Currency value={value} showCurrency={false} />
            )}
            defaultSortOrder={getDefaultSortOrder("profitEstimation", sorter)}
            sorter
            align="right"
            onCell={(data) => {
              return {
                className: data.isSum ? "ant-table-cell-row-hover" : "",
              };
            }}
          />

          <Column
            width={200}
            key="status"
            dataIndex="businessOppsStatus"
            title={translate("Trạng thái CHKD")}
            render={(_, record) => {
              if (record?.isSum) return null;
              return (
                <Tag {...renderStatus(record?.businessOppsStatus?.code)}>
                  {`${record?.businessOppsStatus?.name} - ${record?.businessOppsStatus?.percentSuccess}%`}
                </Tag>
              );
            }}
            onCell={(data) => {
              return {
                className: data.isSum ? "ant-table-cell-row-hover" : "",
              };
            }}
          />

          <Column
            width={200}
            key="closedDate"
            dataIndex="closedDate"
            title={translate("Ngày đóng dự kiến")}
            render={(value) => (
              <TextField
                value={formatISODateTimeToView(value as string, DATE_FORMAT)}
              />
            )}
            defaultSortOrder={getDefaultSortOrder("closedDate", sorter)}
            sorter
            onCell={(data) => {
              return {
                className: data.isSum ? "ant-table-cell-row-hover" : "",
              };
            }}
            align="center"
          />
          <Column
            key="employee"
            dataIndex="employee"
            width={200}
            title={translate("NVKD")}
            render={(value, record) => (
              <TextField value={value && `${value?.code} - ${value?.name}`} />
            )}
            defaultSortOrder={getDefaultSortOrder("employee", sorter)}
            sorter
            onCell={(data) => {
              return {
                className: data.isSum ? "ant-table-cell-row-hover" : "",
              };
            }}
          />
          <Column
            key="market"
            dataIndex="market"
            width={200}
            title={translate("Thị trường")}
            render={(value, record) => (
              <TextField value={value && `${value?.code} - ${value?.name}`} />
            )}
            onCell={(data) => {
              return {
                className: data.isSum ? "ant-table-cell-row-hover" : "",
              };
            }}
          />
          <Column
            key="budgetEstimation"
            dataIndex="budgetEstimation"
            title={translate("Ngân sách dự kiến")}
            width={200}
            render={(value, record) => (
              <Currency value={value} showCurrency={false} />
            )}
            defaultSortOrder={getDefaultSortOrder("budgetEstimation", sorter)}
            sorter
            align="right"
            onCell={(data) => {
              return {
                className: data.isSum ? "ant-table-cell-row-hover" : "",
              };
            }}
          />
        </Table>
      </div>
    </List>
  );
};

export default SalePipelineList;
