import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { PATH, SLUGS } from "configs/path";
import { IPrepay } from "interfaces";
import React from "react";
import { Information } from "./components/Information";
import { ApprovalPage } from "components/Approval";

interface PrepayShowProps {
  isApprover?: boolean;
}

export const PrepayShow: React.FC<
  IResourceComponentsProps & PrepayShowProps
> = (props) => {
  const { isApprover } = props;
  const t = useTranslate();

  return (
    <ApprovalPage
      pageTitle={t("prepays.titles.show")}
      resource={PATH.prepays}
      backUrl={isApprover ? PATH.prepayApprovalrequests : PATH.prepays}
      isApprover={props.isApprover}
      redirectPath={isApprover ? SLUGS.prepayApproval : SLUGS.prepays}
      renderInformation={(record: IPrepay) => <Information record={record} />}
    />
  );
};
