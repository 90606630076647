import { Tag } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { ApprovalActionStatus, ApprovalStatus } from "api/enums";

interface ApprovalStatusLabelProps {
  status?: any;
}

// For prepay/payment
export const ApprovalStatusLabel = ({ status }: ApprovalStatusLabelProps) => {
  const t = useTranslate();

  switch (status) {
    case ApprovalStatus.Draft:
      return <Tag color="warning">{t("common.status.draft")}</Tag>;
    case ApprovalStatus.Waiting:
      return <Tag color="cyan">{t("common.status.waiting")}</Tag>;
    case ApprovalStatus.Approved:
      return <Tag color="success">{t("common.status.approved")}</Tag>;
    case ApprovalStatus.Approving:
      return <Tag color="processing">{t("common.status.approving")}</Tag>;
    case ApprovalStatus.Rejected:
      return <Tag color="red">{t("common.status.reject")}</Tag>;
    case ApprovalStatus.Returned:
      return (
        <Tag color="magenta" style={{ background: "#F0F0F0" }}>
          {t("common.status.returned")}
        </Tag>
      );
    case ApprovalStatus.Canceled:
      return <Tag color="error">{t("common.status.cancel")}</Tag>;
    case ApprovalStatus.Expired:
      return <Tag color="default">{t("common.status.expired")}</Tag>;
    default:
      return <>{status}</>;
  }
};

// For process status of prepay/payment
export const ProcessApprovalStatusLabel = ({
  status,
}: ApprovalStatusLabelProps) => {
  const t = useTranslate();

  switch (status) {
    case ApprovalActionStatus.Approved:
      return <Tag color="success">{t("common.status.approved")}</Tag>;
    case ApprovalActionStatus.Rejected:
      return <Tag color="error">{t("common.status.reject")}</Tag>;
    case ApprovalActionStatus.Returned:
      return (
        <Tag color="magenta" style={{ background: "#F0F0F0" }}>
          {t("common.status.returned")}
        </Tag>
      );
    case ApprovalActionStatus.WaitingProcess:
      return <Tag color="warning">{t("common.status.processing")}</Tag>;
    case ApprovalActionStatus.Submit:
      return <Tag color="red">{t("common.status.submit")}</Tag>; // Not use FE
    case ApprovalActionStatus.Cancel:
      return <Tag color="red">{t("common.status.cancel")}</Tag>;
    case ApprovalActionStatus.Retrieve:
      return <Tag color="red">{t("common.status.retrieve")}</Tag>;
    default:
      return <>{status}</>;
  }
};
