import { FC } from "react";
import {
  Button,
  Col,
  Form,
  Icons,
  Radio,
  Row,
  Space,
  Table,
  TextField,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";
import { ButtonTooltip, EmptyData, SearchBox } from "components";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { useController } from "./controller";
import { ListCustom } from "components/layout";
import CTableColumn from "components/CTableColumn";
import styled from "styled-components";
import { OptionViewAproval } from "interfaces/ServiceRequest";
import { ApprovalStatus } from "api/enums";
import { formatISODateTimeToView } from "utils/commons";
import { ApprovalStatusLabel } from "components/ApprovalStatusLabel";
import { DATE_TIME_FORMAT } from "configs/constants";
import ModalCloneBusinessPlan from "./components/ModalClone";
import { BusinessPlanObjectData } from "interfaces/BusinessPlan";
import { SubmitEmployee, WaitingProcessEmployees } from "interfaces";

interface RowData extends BusinessPlanObjectData {
  submitEmployee: SubmitEmployee;
  waitingProcessEmployees: WaitingProcessEmployees[];
}

const Column = CTableColumn<RowData>();

const RowStyle = styled(Row)`
  justify-content: flex-start;

  @media only screen and (min-width: 1200px) {
    justify-content: flex-end;
  }
`;

const BusinessPlanList: FC<IResourceComponentsProps> = (props) => {
  const {
    formProps,
    form,
    table: {
      tableQueryResult: { data, isLoading },
      sorter,
      pageSize,
      current,
      filters,
      setSorter,
      setPageSize,
      setCurrent,
      setFilters,
    },
    cloneItem,
    visibleModalClone,
    statusOptions,
    optionViewAproval,
    dataTable,
    onSelectItemClone,
    toggleModalClone,
    onChangeOptionView,
  } = useController();
  const { edit, show, create } = useNavigation();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } = usePermissions();
  const translate = useTranslate();

  return (
    <ListCustom
      title={translate("businessPlans.titles.list")}
      headerButtons={
        <ButtonTooltip
          type="primary"
          icon={<Icons.PlusOutlined />}
          onClick={() => create(PATH.businessPlans)}
          {...checkCreateHaveHelperText(PATH.businessPlans)}
        >
          {translate("actions.create")}
        </ButtonTooltip>
      }
      breadcrumb={null}
    >
      <div className="list-content">
        <Form
          {...formProps}
          initialValues={{ status: -1, oldStatus: -1 }}
          style={{ marginBottom: 16 }}
        >
          <Row gutter={[16, 16]}>
            <Col lg={24} xl={18}>
              <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
                <SearchBox
                  placeholder={translate("Tìm kiếm mã dự toán, mã vụ việc")}
                  style={{ width: 300, marginBottom: 0 }}
                  onSearch={(e) => {
                    setFilters([
                      {
                        field: "q",
                        operator: "eq",
                        value: e,
                      },
                    ]);
                  }}
                />

                {filters &&
                  filters.length > 0 &&
                  !(
                    filters.length === 1 && filters[0]?.operator === "containss"
                  ) && (
                    <Button
                      type="text"
                      onClick={(e) => {
                        form.resetFields();
                        setFilters([], "replace");
                      }}
                      style={{ color: "#8C8C8C" }}
                    >
                      {translate("common.cleanFilter")}
                      <Icons.CloseOutlined />
                    </Button>
                  )}
              </div>
            </Col>
            <Form.Item name={["optionViewAproval"]} hidden />
            <Form.Item name={["oldStatus"]} hidden />

            <Col lg={24} xl={6}>
              <RowStyle gutter={8}>
                <Col>
                  <Button
                    onClick={onChangeOptionView(OptionViewAproval.MINE)}
                    shape="round"
                    type={
                      optionViewAproval === OptionViewAproval.MINE
                        ? "primary"
                        : "ghost"
                    }
                  >
                    Của tôi
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={onChangeOptionView(OptionViewAproval.APPROVING)}
                    shape="round"
                    type={
                      optionViewAproval === OptionViewAproval.APPROVING
                        ? "primary"
                        : "ghost"
                    }
                  >
                    Cần xử lý
                  </Button>
                </Col>
              </RowStyle>
            </Col>
          </Row>
          <Form.Item
            name={["status"]}
            style={{ marginTop: 16 }}
            className="m-0"
          >
            <Radio.Group
              defaultValue={-1}
              buttonStyle="solid"
              onChange={(e) => {
                setCurrent(1);
                form.setFieldsValue({ oldStatus: e.target.value });
                setFilters([
                  {
                    field: "status",
                    operator: "eq",
                    value:
                      e?.target?.value === -1 ? undefined : e?.target?.value,
                  },
                ]);
              }}
            >
              <Radio.Button disabled={optionViewAproval === 1} value={-1}>
                {translate("common.all")}
              </Radio.Button>
              {statusOptions?.map(({ label, value }) => {
                return (
                  <Radio.Button
                    disabled={
                      optionViewAproval === 1 &&
                      value !== ApprovalStatus.Approving
                    }
                    value={value}
                  >
                    {translate(label as string)}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </Form>
        <Table
          sticky
          loading={isLoading}
          dataSource={dataTable}
          rowKey="id"
          locale={{
            emptyText: <EmptyData text={translate("common.noDataSearch")} />,
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                show(PATH.businessPlans, record.businessPlan?.id);
              },
            };
          }}
          pagination={{
            pageSize: pageSize,
            current: current,
            total: data?.total,
            showSizeChanger: true,
            onChange: (cur, size) => {
              setCurrent(cur);
              setPageSize(size);
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0;
            },
            locale: {
              items_per_page: translate("common.pageNumber"),
            },
            showTotal: (total) => (
              <p>{translate("common.totalPage", { total: total })}</p>
            ),
          }}
          onChange={(_, __, s) => {
            const newSort = s as any;
            setSorter([
              {
                field:
                  newSort?.field === "department"
                    ? "department.name"
                    : newSort?.field === "title"
                    ? "title.name"
                    : newSort?.field,
                order: newSort?.order,
              },
            ]);
          }}
          scroll={{ x: 2350 }}
          showSorterTooltip={false}
        >
          <Column
            key="businessPlan"
            dataIndex="businessPlan"
            title={translate("Mã PAKD")}
            render={(value, record) => (
              <TextField className="primary" value={value?.code} />
            )}
            defaultSortOrder={getDefaultSortOrder("businessPlan", sorter)}
            sorter
          />

          <Column
            key="case"
            dataIndex="case"
            title={translate("Vụ việc")}
            render={(value, record) => <TextField value={value?.code} />}
            defaultSortOrder={getDefaultSortOrder("case", sorter)}
            sorter
          />

          <Column
            width={250}
            key="case"
            dataIndex="case"
            title={translate("Tên vụ việc")}
            render={(value, record) => <TextField value={value?.name} />}
            defaultSortOrder={getDefaultSortOrder("case", sorter)}
            sorter
          />

          <Column
            key="status"
            dataIndex="status"
            title={translate("Trạng thái")}
            render={(status, record) => (
              <ApprovalStatusLabel status={Number(status)} />
            )}
            defaultSortOrder={getDefaultSortOrder("status", sorter)}
            sorter
          />

          <Column
            width={250}
            key="waitingProcessEmployees"
            dataIndex="waitingProcessEmployees"
            title={translate("Người xử lý")}
            render={(value, record) => {
              const content = record?.waitingProcessEmployees
                ?.map((emp) => `${emp.code} - ${emp.fullName}`)
                ?.join(",");
              return <TextField value={content || "-"} />;
            }}
            defaultSortOrder={getDefaultSortOrder(
              "waitingProcessEmployees",
              sorter
            )}
            sorter
          />

          <Column
            key="submitEmployee"
            dataIndex="submitEmployee"
            width={250}
            title={translate("Người yêu cầu")}
            render={(value, record) => (
              <TextField
                value={
                  record?.submitEmployee
                    ? `${record?.submitEmployee?.code} - ${record?.submitEmployee?.fullName}`
                    : "-"
                }
              />
            )}
            defaultSortOrder={getDefaultSortOrder("submitEmployee", sorter)}
            sorter
          />

          <Column
            key="originalBusinessPlan"
            dataIndex="originalBusinessPlan"
            title={translate("Mã PAKD gốc")}
            ellipsis
            render={(value, record) => <TextField value={value?.code} />}
            sorter
          />

          <Column
            key="updatedName"
            dataIndex="updatedName"
            title={translate("common.updatedName")}
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("updatedName", sorter)}
            sorter
          />

          <Column
            key="updatedDate"
            dataIndex="updatedDate"
            title={translate("common.updatedDate")}
            render={(value) => (
              <TextField
                value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
              />
            )}
            defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
            sorter
            width={200}
          />

          <Column
            key="createdName"
            dataIndex="createdName"
            title={translate("common.createdName")}
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("createdName", sorter)}
            sorter
          />

          <Column
            key="createdDate"
            dataIndex="createdDate"
            title={translate("common.createdDate")}
            render={(value) => (
              <TextField
                value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
              />
            )}
            defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
            sorter
            width={200}
          />

          <Column
            title={translate("table.actions")}
            dataIndex="actions"
            align="center"
            fixed="right"
            width={120}
            render={(_, record) => {
              const status = Number(record?.status);
              return (
                <Space>
                  <ButtonTooltip
                    type="text"
                    icon={<Icons.EditOutlined />}
                    hidden={
                      ![ApprovalStatus.Draft, ApprovalStatus.Returned].includes(
                        status
                      )
                    }
                    className="gray"
                    onClick={(e) => {
                      e.stopPropagation();
                      edit(PATH.businessPlans, record?.businessPlan?.id!);
                    }}
                    {...checkEditHaveHelperText(PATH.businessPlans)}
                  />

                  {status === ApprovalStatus.Approved && (
                    <>
                      <ButtonTooltip
                        type="text"
                        icon={<Icons.CopyOutlined />}
                        className="gray"
                        onClick={(e) => {
                          e.stopPropagation();
                          onSelectItemClone(record);
                        }}
                        {...checkEditHaveHelperText(PATH.businessPlans)}
                      />
                    </>
                  )}
                </Space>
              );
            }}
          />
        </Table>
        <ModalCloneBusinessPlan
          toggleModal={toggleModalClone}
          visibleModal={visibleModalClone}
          record={cloneItem}
        />
      </div>
    </ListCustom>
  );
};

export default BusinessPlanList;
