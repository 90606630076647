import {
  DatePicker,
  Form,
  FormInstance,
  FormProps,
  Input,
  Radio,
  Select,
  Typography,
  useSelect,
} from "@pankod/refine-antd";
import { useOne, useTranslate } from "@pankod/refine-core";
import { useSearchParams } from "@pankod/refine-react-router-v6";
import {
  ApprovalStatus,
  CurrencyType,
  PaymentBeneficiaryType,
  PaymentType,
} from "api/enums";
import { SelectCustom } from "components";
import CostTable from "components/CostTable";
import UploadMulti from "components/UploadMulti";
import { DATE_FORMAT } from "configs/constants";
import { API_PATH } from "configs/path";
import dayjs from "dayjs";
import { ICase, IPrepay } from "interfaces";
import { FC, memo, useEffect, useState } from "react";
import { CostTableTypeEnum } from "utils/enums";

interface Props {
  form: FormInstance<{}>;
  formProps: FormProps;
  onFinishForm: () => void;
  record?: IPrepay;
}
const { Text, Title } = Typography;
const { TextArea } = Input;

const PrepayForm: FC<Props> = memo((props) => {
  const { formProps, form, onFinishForm, record } = props;
  const t = useTranslate();
  const { selectProps: selectPropsCase } = useSelect({
    resource: API_PATH.prepaysCaseDropdownlist,
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const { data: employeeInforData } = useOne<ICase>({
    resource: API_PATH.employeeInformation,
    id: "",
  });
  const [costKeys, setCostKeys] = useState<string[]>();
  const employeeInformation = employeeInforData?.data;

  const caseId = Form.useWatch("caseId", form);
  const advanceType = Form.useWatch("advanceType", form);
  const beneficiaryType = Form.useWatch("beneficiaryType", form);
  const currencyType = Form.useWatch("currencyType", form);
  const employeeId = Form.useWatch("employeeId", form);
  const status = Form.useWatch("status", form);

  const disabledDate = (current: any): boolean => {
    // Can not select days before today
    return current && current < dayjs().startOf("day");
  };

  const onClearAttachFile = () =>
    form.setFields([{ name: "attachments", value: [] }]);
  const params = useSearchParams();
  useEffect(() => {
    if (record) setCostKeys(Object.keys(record.listCosts || {}));
  }, [record]);

  return (
    <>
      <Title level={5}>{t("prepays.titles.show")}</Title>
      <Form
        {...formProps}
        onFinish={onFinishForm}
        layout="vertical"
        labelCol={{ span: 10 }}
        initialValues={{
          status: ApprovalStatus.Draft,
          ...formProps.initialValues,
        }}
      >
        <Form.Item name="status" hidden />
        <Form.Item
          label={t("prepays.fields.case")}
          required
          style={{ maxWidth: 500 }}
          name="caseId"
          initialValue={params[0].get("caseId")}
          rules={[
            {
              required: true,
              message: t("errors.ER005"),
            },
          ]}
        >
          <Select
            {...selectPropsCase}
            onChange={onClearAttachFile}
            placeholder="Chọn mã vụ việc"
            disabled={status !== ApprovalStatus.Draft}
          />
        </Form.Item>
        <Form.Item
          label={t("prepays.fields.name")}
          required
          name="name"
          style={{ maxWidth: 500 }}
          rules={[
            {
              whitespace: true,
              required: true,
              message: t("errors.ER005"),
            },
            {
              max: 300,
              message: t("errors.ER014", { max: "300" }),
            },
          ]}
        >
          <Input placeholder={t("prepays.placeholder.name")} />
        </Form.Item>

        <Form.Item
          label={t("prepays.fields.receivedEmployee")}
          name="beneficiaryType"
          required
          initialValue={PaymentBeneficiaryType.Internal}
          style={{ maxWidth: 500 }}
        >
          <Radio.Group
            onChange={() => {
              form.setFieldsValue({ employeeId: null, supplierId: null });
            }}
          >
            <Radio value={PaymentBeneficiaryType.Internal}>
              {t("common.beneficiaryType.internal")}
            </Radio>
            <Radio value={PaymentBeneficiaryType.Supplier}>
              {t("common.beneficiaryType.supplier")}
            </Radio>
          </Radio.Group>
        </Form.Item>

        {beneficiaryType === PaymentBeneficiaryType.Supplier && (
          <>
            <Form.Item
              label={t("prepays.fields.supplier")}
              name="supplierId"
              style={{ maxWidth: 500 }}
              required
              rules={[
                {
                  required: true,
                  message: t("errors.ER005"),
                },
              ]}
            >
              <SelectCustom
                resource={API_PATH.suppliers}
                optionLabel="label"
                optionValue="value"
                placeholder={t("prepays.placeholder.supplier")}
              />
            </Form.Item>
          </>
        )}

        {beneficiaryType === PaymentBeneficiaryType.Internal && (
          <>
            <Form.Item
              label={t("prepays.fields.employeeName")}
              name="employeeId"
              style={{ maxWidth: 500, marginBottom: 0 }}
              required
              rules={[
                {
                  required: true,
                  message: t("errors.ER005"),
                },
              ]}
            >
              <SelectCustom
                resource={API_PATH.employeeDropdownList}
                optionLabel="label"
                optionValue="id"
                formatData={(r: any) => {
                  return {
                    ...r,
                    label: `${r.code} - ${r.fullName}`,
                  };
                }}
                placeholder={t("prepays.placeholder.employeeName")}
                valueInObject={true}
                onChangeItem={(_: any, __: any, item: any) => {
                  form.setFieldsValue({ departmentId: item?.departmentId });
                }}
                onLoaded={(options: any[]) => {
                  const currentEmployee = options?.find(
                    (t) => t.id === employeeInformation?.employee.id
                  );
                  if (options && options.length === 1 && currentEmployee) {
                    form.setFieldsValue({
                      employeeId: currentEmployee?.id,
                    });
                  }
                }}
              />
            </Form.Item>
            <div style={{ marginBottom: 16 }}>
              <Text type="secondary">
                {t("Vui lòng chọn nhân viên thụ hưởng là người tạo yêu cầu")}
              </Text>
            </div>
            {employeeId && (
              <Form.Item
                label={t("prepays.fields.department")}
                name="departmentId"
                style={{ maxWidth: 500 }}
                required
                rules={[
                  {
                    required: true,
                    message: t("errors.ER005"),
                  },
                ]}
              >
                <SelectCustom
                  resource={API_PATH.departmentSelectForPrepay}
                  optionLabel="name"
                  optionValue="id"
                  valueInObject={true}
                  placeholder={t("prepays.placeholder.department")}
                  disabled
                />
              </Form.Item>
            )}
          </>
        )}
        <Form.Item
          label={t("prepays.fields.reason")}
          name="reason"
          style={{ maxWidth: 500 }}
          required
          rules={[
            {
              max: 500,
              message: t("errors.ER014", { max: "500" }),
            },
            {
              whitespace: true,
              required: true,
              message: t("errors.ER005"),
            },
          ]}
        >
          <TextArea
            showCount={{
              formatter: (args: { count: number }) => `${args?.count}/500`,
            }}
            placeholder={t("prepays.placeholder.reason")}
          />
        </Form.Item>
        {caseId && (
          <Form.Item name="attachments" validateStatus="success" label="">
            <UploadMulti
              title={t("prepays.fields.attachment")}
              url={`${process.env.REACT_APP_API_URL}${API_PATH.prepayUploadFile}?caseId=${caseId}`}
            />
          </Form.Item>
        )}

        <Form.Item
          label={t("prepays.fields.estimatedPaymentDate")}
          name="estimatedPaymentDate"
          getValueProps={(value) => ({
            value: value ? dayjs(value) : "",
          })}
          required
          rules={[
            {
              required: true,
              message: t("errors.ER005"),
            },
          ]}
          style={{ maxWidth: 500 }}
        >
          <DatePicker
            placeholder={"dd/mm/yyyy"}
            format={DATE_FORMAT}
            disabledDate={disabledDate}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label={t("payments.fields.currency")}
          name="currencyType"
          style={{ maxWidth: 500 }}
          initialValue={CurrencyType.VND}
          rules={[
            {
              required: true,
              message: t("errors.ER005"),
            },
          ]}
          required
        >
          <Select placeholder={t("payments.placeholder.currency")}>
            <Select.Option value={CurrencyType.VND}>VND</Select.Option>
            <Select.Option value={CurrencyType.USD}>USD</Select.Option>
            <Select.Option value={CurrencyType.EUR}>EUR</Select.Option>
            <Select.Option value={CurrencyType.SGD}>SGD</Select.Option>
          </Select>
        </Form.Item>

        <CostTable
          mode={CostTableTypeEnum.FULL_PREPAY}
          form={form}
          currencyType={currencyType}
          costKeys={costKeys}
        />

        <Form.Item
          label={t("prepays.fields.advanceType")}
          name="advanceType"
          required
          style={{ maxWidth: 500 }}
          initialValue={PaymentType.Banking}
        >
          <Radio.Group>
            <Radio value={PaymentType.Banking}>
              {t("common.paymentType.banking")}
            </Radio>
            <Radio value={PaymentType.Cash}>
              {t("common.paymentType.cash")}
            </Radio>
          </Radio.Group>
        </Form.Item>
        {advanceType === PaymentType.Banking && (
          <>
            <Form.Item
              label={t("prepays.fields.bankAccountName")}
              name="bankAccountName"
              style={{ maxWidth: 500 }}
              required
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t("errors.ER005"),
                },
                {
                  max: 300,
                  message: t("errors.ER014", { max: "300" }),
                },
              ]}
            >
              <Input placeholder={t("prepays.placeholder.bankAccountName")} />
            </Form.Item>

            <Form.Item
              label={t("prepays.fields.bankAccountNumber")}
              name="bankAccountNumber"
              style={{ maxWidth: 500 }}
              required
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t("errors.ER005"),
                },
                {
                  max: 20,
                  message: t("errors.ER014", { max: "20" }),
                },
                {
                  pattern: /^[0-9]+$/,
                  message: t("errors.ER037"),
                },
              ]}
            >
              <Input placeholder={t("prepays.placeholder.bankAccountNumber")} />
            </Form.Item>

            <Form.Item
              label={t("prepays.fields.bank")}
              name="bankId"
              rules={[
                {
                  required: true,
                  message: t("errors.ER005"),
                },
              ]}
              style={{ maxWidth: 500 }}
              required
            >
              <SelectCustom
                optionLabel="label"
                optionValue="value"
                resource={API_PATH.banks}
                placeholder={t("prepays.placeholder.bank")}
                onChangeOption={(v: any, option: any) => {
                  form.setFieldsValue({ bankName: option?.label });
                }}
                formatData={(r: any) => {
                  return {
                    ...r,
                    label: `${r.code} - ${r.label}`,
                  };
                }}
              />
            </Form.Item>

            <Form.Item name="bankName" hidden />

            <Form.Item
              label={t("prepays.fields.branchBank")}
              name="bankBranch"
              style={{ maxWidth: 500 }}
              rules={[
                {
                  max: 300,
                  message: t("errors.ER014", { max: "300" }),
                },
              ]}
            >
              <Input placeholder={t("prepays.placeholder.branchBank")} />
            </Form.Item>
          </>
        )}
      </Form>
    </>
  );
});

export default PrepayForm;
