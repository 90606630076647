import { Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import {
  ApprovalServiceRequest,
  TabKey,
} from "components/ApprovalServiceRequest";
import { PATH } from "configs/path";
import ISettlement, { SettlemenData } from "interfaces/Settlement";
import { FC, createContext, memo } from "react";
import styled from "styled-components";
import CaseInfo from "./components/CaseInfo";
import SettlementInfo from "./components/SettlementInfo";
import Cost from "./components/Cost";

const { Text } = Typography;

interface InformationProps {
  record?: Partial<SettlemenData>;
  hidden?: boolean;
}

const InfomationStyle = styled.div<{ hidden?: boolean }>`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  gap: 8px;
  min-height: 400px;
`;

export const SettlementShowContext = createContext<Partial<SettlemenData>>({});

const Infomation: FC<InformationProps> = memo((props) => {
  const { hidden, record } = props;

  return (
    <SettlementShowContext.Provider value={record || {}}>
      <InfomationStyle hidden={hidden}>
        <CaseInfo />
        <SettlementInfo />
        <Cost />
      </InfomationStyle>
    </SettlementShowContext.Provider>
  );
});

const SettlementShow: FC<IResourceComponentsProps> = memo((props) => {
  const translate = useTranslate();
  return (
    <ApprovalServiceRequest
      {...props}
      onApprovalSuccess={() => {}}
      resource={PATH.settlements}
      requestName={translate("Phê duyệt QTTK")}
      renderInformation={(record: ISettlement, tab: TabKey) => (
        <Infomation
          record={record?.objectData}
          hidden={tab !== TabKey.INFO_TAB}
        />
      )}
      title={(record: ISettlement) => (
        <>
          {translate("Quyết toán triển khai")}:{" "}
          <Text className="primary">{record?.objectData?.case?.name}</Text>
        </>
      )}
    />
  );
});

export default SettlementShow;
