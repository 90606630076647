import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { PATH, SLUGS } from "configs/path";
import { IPayment } from "interfaces";
import React from "react";
import { Information } from "./components/Information";
import { ApprovalPage } from "components/Approval";

interface PaymentShowProps {
  isApprover?: boolean;
}

export const PaymentShow: React.FC<
  IResourceComponentsProps & PaymentShowProps
> = (props) => {
  const t = useTranslate();
  const { isApprover } = props;
  return (
    <ApprovalPage
      pageTitle={t("payments.titles.show")}
      backUrl={isApprover ? PATH.paymentApprovalrequests : PATH.payments}
      resource={PATH.payments}
      isApprover={props.isApprover}
      redirectPath={isApprover ? SLUGS.paymentApproval : SLUGS.payments}
      renderInformation={(record: IPayment) => <Information record={record} />}
    />
  );
};
